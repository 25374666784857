import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import clsx from "clsx";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import { useTableStyles } from "../../../../styles/table.style";
import { formatDate } from "../../../../helpers/utils";
import { Button } from "@material-ui/core";
import { useReactToPrint } from "react-to-print";
import { ComponentToPrint } from "../../../../components/componentToPrint";
import { useTranslation } from "react-i18next";
import { Ticket } from "../../../payement/components/ticket";
import { useSelector } from "react-redux";
import { symbol, statusFormat  } from "../../../../helpers/utils";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 24,
    height: 24,
  },
  print: {
    display: "none",
  },
  avatarCell: {
    width: 24,
  },
  button: {
    fontSize: 14,
    backgroundColor: theme.palette.productsTable.button,
    color: theme.palette.productsTable.buttonColor,
    "&:hover": {
      backgroundColor: theme.palette.productsTable.button,
    },
  },
  selectInput: {
    "& .MuiInputBase-root": {
      background: "#F4F7F9",
      borderRadius: 5,
      border: 0,
    },
    "& .MuiOutlinedInput-input": {
      padding: "2.9px 14px",
      fontSize: 14,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
  ticket: {
    "&:after": {
      content: '""',
      display: "block",
      height: "70px",
      backgroundImage: "url(/img/border.png)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom",
      marginTop: "-1.8625rem",
      textAlign: "center",
    },
  },
  ticketBlock: {
    boxShadow: "0px 5px 15px 4px #0000000F",
    [theme.breakpoints.up("md")]: {
      minWidth: 420,
    },
    fontFamily: "Courier Prime",
    color: "#707070",
    fontSize: 14,
    padding: "4rem 0.5rem",
    lineHeight: 2,
  },
  ticketHeader: {
    textAlign: "center",
  },
  ticketProducts: {
    display: "flex",
    padding: "20px 88px 10px 78px",
  },
  productsborder: {
    borderBottom: "1px solid #707070",
    paddingBottom: "1.375rem",
  },
  ProductsNumber: {
    paddingLeft: "1.75rem",
  },
  productRef: {
    float: "left",
    width: "4rem",
    overflowWrap: "anywhere",
  },
  productTotal: {
    marginLeft: "1rem",
    float: "right",
  },
  productDetails: {
    marginLeft: "1rem",
  },
  ticketFooter: {
    marginTop: "2.375rem",
  },
  logo: {
    width: 150,
    height: 40,
  },
}));

export const InvoicesTable = (props) => {
  const classes = useStyles();
  const componentRef = useRef();
  const componentRefTicket = useRef();
  const tableClasses = useTableStyles();
  const contactCompany = useSelector((state) => state.Login.selectedData);
  const currencies = useSelector((state) => state.Client.currencies);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const [selectedRow, setSelectedRow] = useState(null);
  const { t } = useTranslation(["common","order"]);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setSelectedRow(null),
  });

  const handleDuplicataPrint = useReactToPrint({
    content: () => componentRefTicket.current,
    onAfterPrint: () => setSelectedRow(null),
  });

  const currencySymbol = (row) => {
    return row
      ? symbol(row.currency, currencies)?.symbol
      : symbol(globalDefaults?.default_currency, currencies)?.symbol;
  };
  return (
    <Box className={tableClasses.paper}>
      <Table
        className={clsx(tableClasses.table, classes.selectInput)}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              Date
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:refIntn")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:refextrn")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:client")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:statut")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:totalPrice")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:facture")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:ticket")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.orders.map((row, index) => (
            <>
              <TableRow className={tableClasses.tableRow} key={index}>
                <TableCell className={tableClasses.tableCell} align="left">
                  {formatDate(row.posting_date, "YYYY-MM-DD")}
                </TableCell>
                <TableCell className={tableClasses.tableCell} align="left">
                  {row.name.substr(15, 5)}
                </TableCell>
                <TableCell className={tableClasses.tableCell} align="left">
                  {row.custom_ref ?? "-"}
                </TableCell>
                <TableCell className={tableClasses.tableCell} align="left">
                  {row.customer_name}
                </TableCell>
                <TableCell className={clsx(tableClasses.tableCell, tableClasses[statusFormat(row.status).color])} align="left">
                  {statusFormat(row.status).value}
                </TableCell>
                <TableCell className={tableClasses.tableCell} align="left">
                  {(row.grand_total + 0.1).toFixed(2)} {currencySymbol(row)}{" "}
                </TableCell>
                <TableCell className={tableClasses.tableCell} align="left">
                  <Button
                    className={classes.button}
                    onClickCapture={() => setSelectedRow(row)}
                    onClick={handlePrint}
                  >
                    {t("common:imprimer")}
                  </Button>
                </TableCell>
                <TableCell className={tableClasses.tableCell} align="left">
                  <Button
                    className={classes.button}
                    onClickCapture={() => setSelectedRow(row)}
                    onClick={handleDuplicataPrint}
                  >
                    Imprimer Duplicata
                  </Button>
                </TableCell>
              </TableRow>
              {selectedRow && (
                <>
                  <div className={classes.print}>
                    <ComponentToPrint
                      isFactureClient={true}
                      isDeliveryDocument={false}
                      document={selectedRow}
                      order={selectedRow}
                      contactCompany={contactCompany.contact}
                      title={t("common:facture")}
                      ref={componentRef}
                    />
                  </div>
                  <Box className={classes.print}>
                    <Ticket
                      ref={componentRefTicket}
                      isDuplicata={true}
                      row={selectedRow}
                    />
                  </Box>
                </>
              )}
            </>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
