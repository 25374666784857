{
  /* eslint-disable  */
}
import React, { useEffect, useRef, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import {
  Modal,
  Typography,
  OutlinedInput,
  ClickAwayListener,
} from "@material-ui/core";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import { Button } from "@material-ui/core";

import { useStyles } from "../../style";
import { useTableStyles } from "../../../../styles/table.style";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  CreatePaymentEntryRequest,
  createPaymentEntryeDoc,
  SetPaymentEntryMappedDoc,
} from "../../../../store/buying/buying.action";
import clsx from "clsx";
import { Loader } from "../../../../components/loader";
import { statusFormat } from "../../../../helpers/utils";
import { useReactToPrint } from "react-to-print";
import { PurchaseComponentToPrint } from "../../../../components/componentToPrint/purchaseInvoice/purchaseToPrint";
import { SetNotification } from "../../../../store/notification/notification.action";
import FormSubmitDebitNote from "../purchaseInvoiceTable/formSubmitDebitNote";
import DuplicatePurchaseInvoice from "../purchaseInvoiceTable/DuplicatePurchaseInvoice";
import { RecapPurchaseToPrint } from "../../../../components/componentToPrint/purchaseInvoice/recapPurchaseToPrint";
import axios from "axios";

export const PaymentEntryTable = (props) => {
  const classes = useStyles();
  const tableclasses = useTableStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(["payement", "common", "buying"]);
  const history = useHistory();
  const componentRef = useRef();
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedInvoice1, setSelectedInvoice1] = useState(null);
  const [prinInProgress, setPrinInProgress] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedModePay, setSelectedModePay] = useState("");

  const [openPurchaseToPrintModal, setOpenPurchaseToPrintModal] =
    useState(false);

  const handleOpenPurchaseToPrintModal = (row) => {
    setSelectedRow(row);
    setOpenPurchaseToPrintModal(true);
  };
  const handleClosePurchaseToPrintModal = () => {
    setOpenPurchaseToPrintModal(false);
  };

  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user"))?.token,
  };
  const paymentEntryDoc = useSelector((state) => state.Buying.paymentEntryDoc);
  const handleCreatePaymentEntry = (id) => {
    dispatch(createPaymentEntryeDoc(id));
    setOpenDetailsModal(true);
  };

  const handleUpdateStatus = (id) => {
    fetch(`${process.env.REACT_APP_API_URI}/api/update-status`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        doctype: "Purchase Invoice",
        freeze: true,
        docnames: [id],
        action: "cancel",
        data: { status: "Cancelled" },
      }),
    })
      .then((res) => {
        if (!res.ok) {
          dispatch(
            SetNotification({
              code: "error",
              message: t("common:AnErrorHasOccurred"),
            })
          );
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === false) {
          dispatch(SetNotification({ code: "warning", message: "warning" }));
        } else {
          dispatch(
            SetNotification({
              code: "success",
              message: "La Facture d'achat a été annulée avec succès",
            })
          );
          window.location.href = "/buying?tab=4";
        }
      });
  };
  const handleDelete = (id) => {
    fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({ doctype: "Purchase Invoice", name: id }),
    })
      .then((res) => {
        if (!res.ok) {
          dispatch(
            SetNotification({
              code: "error",
              message: t("common:AnErrorHasOccurred"),
            })
          );
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === false) {
          dispatch(
            SetNotification({
              code: "warning",
              message: "warning you need to cancel first",
            })
          );
        } else {
          dispatch(
            SetNotification({
              code: "success",
              message: "La facture d'Achat a été supprimée avec succès",
            })
          );
          window.location.href = "/buying?tab=4";
        }
      });
  };
  const handleEdit = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };
  const handleDuplicate = (row) => {
    setSelectedRow(row);
    setOpenDuplicateModal(true);
  };
  const handleCloseModalDebitNote = () => {
    setOpen(false);
  };
  const handleCloseModalDuplicate = () => {
    setOpenDuplicateModal(false);
  };
  const handleCloseModal = () => {
    dispatch(SetPaymentEntryMappedDoc(null));
    setOpenDetailsModal(false);
  };

  const [state, setState] = React.useState({
    reference_no: "",
    reference_date: "",
    mode_of_paiement: "",
    paid_amount: ""
  });

  const [error, setError] = React.useState({
    reference_no: false,
    reference_date: false,
    mode_of_paiement: false,
  });

  const handleChange = (event) => {
    setError({ ...error, [event.target.name]: false });
    setState({ ...state, [event.target.name]: event.target.value });
    setSelectedModePay(event.target.value);
  };

  const handleCreate = (row) => {
    handleCreatePaymentEntry(row?.name);
    setSelectedRow(row);
  };
  const handlePayCash = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/document/payment-entry`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        action: "Submit",
        doc: {
          "naming_series": "ACC-PAY-.YYYY.-",
          "payment_type": "Pay",
          "company": globalDefaults?.default_company,
          "mode_of_payment": "Cash",
          "party_type": "Supplier",
          "party": selectedRow.supplier,
          "paid_from": process.env.REACT_APP_PAID_FROM,
          "paid_from_account_type": "Cash",
          "paid_from_account_currency": "TND",
          "paid_to": process.env.REACT_APP_PAID_TO,
          "paid_to_account_type": "Payable",
          "paid_to_account_currency": "TND",
          "paid_amount": parseFloat(state.paid_amount),
          "source_exchange_rate": 1,
          "received_amount": parseFloat(state.paid_amount),
          "target_exchange_rate": 1,
          "doctype": "Payment Entry",
          "references": [
            {
              "parentfield": "references",
              "parenttype": "Payment Entry",
              "reference_doctype": "Purchase Invoice",
              "reference_name": selectedRow.name,
              "total_amount": selectedRow.total_amount,
              "outstanding_amount": selectedRow.outstanding_amount,
              "allocated_amount": parseFloat(state.paid_amount),
              "doctype": "Payment Entry Reference"
            }
          ]
        },
      }),
    })
      .then((res) => {
        if (!res.ok) {
          dispatch(
            SetNotification({
              code: "error",
              message: t("common:AnErrorHasOccurred"),
            })
          );
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === false) {
          dispatch(
            SetNotification({
              code: "warning",
              message: "warning you need to check paiement",
            })
          );
        } else {
          dispatch(
            SetNotification({
              code: "success",
              message: "La facture a été payer avec succès",
            })
          );
          window.location.reload();
        }
      });
  };
  const handleSubmit = (event) => {
    var isValid = true;
    if (state.mode_of_paiement === "Cash") {
      handlePayCash();
      handleCloseModal();
    } else {
      if (state.reference_no === "" || state.reference_no.length !== 20) {
        setError({ ...error, ["reference_no"]: true });
        isValid = false;
      }
      if (state.reference_date === "") {
        isValid = false;
        setError({ ...error, ["reference_date"]: true });
      }
      if (isValid && paymentEntryDoc) {
        const data = {
          ...paymentEntryDoc,
          reference_no: state.reference_no,
          reference_date: state.reference_date,
        };
        dispatch(CreatePaymentEntryRequest(data));
        handleCloseModal();
      }
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,

    onAfterPrint: () => {
      setSelectedInvoice(null);
      setPrinInProgress(false);
    },
  });

  const getPurchaseOrder = async (id) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Purchase+Invoice&name=${id}`,
        { headers: header }
      )
      .then(({ data }) => {
        setSelectedInvoice1(data?.docs[0]);
        setSelectedInvoice(data?.docs[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPurchaseOrder1 = async (id) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Purchase+Invoice&name=${id}`,
        { headers: header }
      )
      .then(({ data }) => {
        setSelectedInvoice1(data?.docs[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (selectedInvoice) {
      handlePrint();
    } else {
      if (prinInProgress) setPrinInProgress(false);
    }
  }, [selectedInvoice]);

  const addPayment = (
    <div className={classes.paper}>
      <Typography align={"center"} color={"primary"}>
        {t("payement:createPayment")}
      </Typography>
      <Box mt={3} display="flex" flexDirection="column">
        <TextField
          className="CuFormInput"
          disabled={true}
          label={t("common:totalPrice")}
          style={{ width: "30%" }}
          value={selectedRow?.grand_total}
        />
        {selectedRow?.status === ("Partly Paid" || "Overdue") && <TextField
          className="CuFormInput"
          disabled={true}
          label={t("common:mr")}
          style={{ width: "30%", marginTop: "15px" }}
          value={selectedRow?.outstanding_amount}
        />}
        <TextField
          className="CuFormInput"
          label={t("common:mt")}
          style={{ width: "30%", marginTop: "15px" }}
          value={state.paid_amount}
          name="paid_amount"
          onChange={handleChange}
        />
      </Box>
      <Box mt={3} justifyContent="center" display="flex" flexDirection="column">
        <InputLabel
          required
          align={"center"}
          color={"primary"}
          id="demo-simple-select-outlined-label"
          className={classes.label}
        >
          {t("common:Mode_de_paiement")}
        </InputLabel>
        <FormControl className={classes.formControl} variant="outlined">
          <Select
            labelId="select-label-item"
            id="simple-select-item"
            error={error.mode_of_paiement}
            value={state.mode_of_paiement}
            name="mode_of_paiement"
            label="Item"
            onChange={handleChange}
          >
            {["Cheque", "Cash"].map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {state.mode_of_paiement === "Cheque" && (
        <Box
          mt={3}
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          <InputLabel
            error={error.reference_date}
            required
            align={"center"}
            color={"primary"}
            id="demo-simple-select-outlined-label"
            className={classes.label}
          >
            {t("payement:refDate")}
          </InputLabel>
          <FormControl className={classes.formControl} variant="outlined">
            <OutlinedInput
              className={classes.chequeInput}
              id="outlined-adornment-weight"
              type="date"
              aria-describedby="outlined-weight-helper-text"
              placeholder="Reference Date"
              value={state.reference_date}
              error={error.reference_date}
              name="reference_date"
              onChange={handleChange}
            />
          </FormControl>
          <InputLabel
            error={error.reference_no}
            required
            align={"center"}
            color={"primary"}
            id="demo-simple-select-outlined-label"
            className={classes.label}
          >
            {t("payement:refNo")}
          </InputLabel>
          <FormControl className={classes.formControl} variant="outlined">
            <OutlinedInput
              className={classes.chequeInput}
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              placeholder={t("payement:refNo")}
              type={"number"}
              value={state.reference_no}
              error={error.reference_no}
              name="reference_no"
              onChange={handleChange}
            />
          </FormControl>
          {error.reference_no && state.reference_no.length !== 20 && (
            <InputLabel error>
              {t("Reference number must be exactly 20 characters long.")}
            </InputLabel>
          )}
        </Box>
      )}
      <Box
        mt={3}
        justifyContent="space-around"
        display="flex"
        flexDirection="row"
      >
        <Button color="primary" variant="outlined" onClick={handleCloseModal}>
          {t("common:Cancel")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={state.paid_amount === "" || state.mode_of_paiement === ""}
          onClick={handleSubmit}
        >
          {t("common:confirmer")}
        </Button>
      </Box>
    </div>
  );
  return (
    <Box className={tableclasses.paper}>
      <Table className={tableclasses.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:ref")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:createDate")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:supplier")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:total")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:Taxation")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:totalPrice")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:statut")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.purchaseInvoiceList &&
            Array.isArray(props.purchaseInvoiceList) &&
            props.purchaseInvoiceList.map((row, index) => (
              <TableRow className={tableclasses.tableRow} key={index}>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.name}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.posting_date}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.supplier}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.total.toFixed(3)} {row?.currency}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.total_taxes_and_charges.toFixed(3)} {row?.currency}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.grand_total.toFixed(3)} {row?.currency}
                </TableCell>
                <TableCell
                  className={clsx(
                    tableclasses.tableCell,
                    tableclasses[statusFormat(row?.status).color]
                  )}
                  align="left"
                >
                  {statusFormat(row?.status).value}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="right">
                  <FormControl
                    variant="outlined"
                    className={classes.formGroup}
                    sx={{ m: 1, minWidth: 70 }}
                    size="small"
                  >
                    <InputLabel
                      id="demo-simple-select-helper-label"
                      sx={{ fontSize: "0.8125rem", color: "#2F4B7C" }}
                    >
                      {t("common:Action")}
                    </InputLabel>
                    <Select
                      className="CuFormInput"
                      sx={{ borderRadius: '25px' }}
                      labelId="demo-simple-select-helper-label"
                      label="Action"
                      id="demo-simple-select-helper-label"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "center",
                          horizontal: "center",
                          minWidth: 60
                        },
                        getContentAnchorEl: null,
                        "aria-label": "Without label",
                      }}
                    >
                      <MenuItem
                        disabled={row?.status === "Return" || row?.status === "Cancelled"}
                        onClick={() => handleEdit(row?.name)}
                        style={{ color: "#2F4B7C", backgroundColor: "white" }}
                      >
                        {t("Retour Financier")}
                      </MenuItem>
                      <MenuItem
                        style={{ color: "#2F4B7C", backgroundColor: "white" }}
                        onClick={() => {
                          // setPrinInProgress(row?.name);
                          getPurchaseOrder(row?.name);
                        }}
                      >
                        {t("common:Print")}
                      </MenuItem>
                      {/* <MenuItem
                        disabled={!(row?.status === "Draft")}
                        style={{ color: "#2F4B7C", backgroundColor: "white" }}
                      >
                        {t("common:Submit")}
                      </MenuItem> */}
                      <MenuItem

                        onClick={() => handleUpdateStatus(row?.name)}
                        style={{ color: "#2F4B7C", backgroundColor: "white" }}
                      >
                        {t("common:Cancel")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleDuplicate(row?.name)}
                        style={{ color: "#2F4B7C", backgroundColor: "white" }}
                        disabled={row?.status === "Return" || row?.status === "Cancelled"}
                      >
                        {t("common:Duplicate")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleDelete(row?.name)}
                        style={{ color: "#2F4B7C", backgroundColor: "white" }}
                      >
                        {t("common:Delete")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell className={tableclasses.tableCell}>
                  <Button
                    disabled={row?.status == "Paid"}
                    size={"small"}
                    variant="outlined"
                    color="primary"
                    onClick={() => handleCreate(row)}
                  >
                    {t("payement:MakePayment")}
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      handleOpenPurchaseToPrintModal(row),
                        getPurchaseOrder1(row?.name);
                    }}
                    style={{ marginRight: 5, marginLeft: 5 }}
                  >
                    <ChromeReaderModeIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Modal
        open={openDetailsModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {paymentEntryDoc ? (
          addPayment
        ) : (
          <div className={classes.paper}>
            <Loader />
          </div>
        )}
      </Modal>
      <Modal
        open={openDuplicateModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className={classes.paper}
          style={{ overflowY: "auto", maxHeight: "90vh", width: "70%" }}
        >
          <DuplicatePurchaseInvoice
            setCloseModal={handleCloseModalDuplicate}
            id={selectedRow}
          />
        </div>
      </Modal>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className={classes.paper}
          style={{ overflowY: "auto", maxHeight: "90vh", width: "70%" }}
        >
          <FormSubmitDebitNote
            setCloseModal={handleCloseModalDebitNote}
            id={selectedRow}
          />
        </div>
      </Modal>
      {selectedInvoice && (
        <Box>
          <PurchaseComponentToPrint
            ref={componentRef}
            document={selectedInvoice}
          />
        </Box>
      )}
      <Modal
        open={openPurchaseToPrintModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ClickAwayListener
          onClickAway={() =>
            setOpenPurchaseToPrintModal
              ? setOpenPurchaseToPrintModal(false)
              : null
          }
        >
          <div
            className={classes.paper}
            style={{
              overflowY: "auto",
              maxHeight: "90vh",
              width: "auto",
              height: "100%",
              aspectRatio: "1.414",
            }}
          >
            {selectedInvoice1 && (
              <Box>
                <RecapPurchaseToPrint
                  ref={componentRef}
                  document={selectedInvoice1}
                />
              </Box>
            )}
          </div>
        </ClickAwayListener>
      </Modal>
    </Box>
  );
};
