import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Clock from "react-live-clock";
import Box from "@material-ui/core/Box";
import Moment from "react-moment";
import { formatDate } from "../../../helpers/utils";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { useTranslation } from "react-i18next";
import { Notifications } from "../notifications";

const useStyles = makeStyles((theme) => ({
  margin: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
  },
  box: {
    padding: 5,
    display: "flex",
    flexDirection: "row",
    background: theme.palette.secondary.main,
    borderRadius: 5,
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.main,
    gap: 10,
  },
  icon: {},
}));

export const DateTime = () => {
  const { t } = useTranslation(["common", "checkout", "address"]);
  const classes = useStyles();
  const posOpenedDate = useSelector((state) => state.Login.posOpeningDate);
  const selectedData = useSelector((state) => state.Login.selectedData);

  const user = useSelector((state) => state.Login.user);
  return (
    <Box className={classes.box}>
      {user && user?.role && user?.role == "Admin" && (
        <Notifications size={"small"} />
      )}
      <Box className={classes.margin}>
        <span className={clsx("icon-shop", classes.icon)} />
        <span>
          {t("address:magasin")}: {selectedData?.store}
        </span>
      </Box>
      {user && user?.role && user?.role == "Cashier" && (
        <Box className={classes.margin}>
          <span className={clsx("icon-calendar", classes.icon)} />
          <span>
            {t("checkout:daytime")}: {formatDate(posOpenedDate, "LL")}
          </span>
        </Box>
      )}
      <Box className={classes.margin}>
        <span className={clsx("icon-calendar", classes.icon)} />
        <span>
          {t("checkout:current")}: {formatDate(Date(), "LL")}
        </span>
      </Box>
      <Box className={classes.margin}>
        <span className={clsx("icon-clock", classes.icon)} />
        <span>
          <Clock format="HH:mm" interval={1000} ticking={true} />
        </span>
      </Box>
    </Box>
  );
};
