import {
  CREATE_BULK_HOLD_SUCCESS,
  CREATE_HOLD_SUCCESS,
  HOLDS_LOADED,
  HOLD_LOADED,
  RELOAD_HOLD,
  SAVE_HOLD,
  SET_HOLD_SELECTED,
} from "../hold/hold.type";
import { CLEAR_STATE } from "../login/login.type";
import {
  PAYMENTS_METHODS_LOADED,
  UPDATE_PAYMENT,
  UPDATE_PRODUCTS,
  UPDATE_ITEM_FINANCIER,
  UPDATE_SELECTED_CLIENT,
  CREATE_ORDER_SUCCESS,
  ORDERS_LOADED,
  Order_LODED,
  SET_DOCUMENT_PAYMENT,
  SET_DOCUMENT_DELIVERY,
  SET_DOCUMENT_INVOICE,
  DOCUMENT_NUMBER_LODED,
  CREATE_DOCUMENT_SUCCESS,
  SET_DISCOUNT_LODED,
  CANCEL_CHECKOUT,
  SET_HOLD_DATA,
  SET_COUPON_CODE,
  SET_PRICING_RULES,
  SET_DEFAULT_TAX,
  UPDATE_CURRENCY_RATE,
  SALES_PERSON_LOADED,
  UPDATE_SELECTED_SALESPERSON,
  SET_DEFAULT_CLIENT,
  CREATE_EMAIL_SUCCESS,
  SET_DETAIL_CLIENT,
  ITEM_DETAILS_LOADED,
  CALCULATOR_TOGGLED,
  RECENT_ORDERS_LOADED,
  CUSTOM_REF_LOADED,
  RETURN_LOADED,
  UPDATE_RETURNS,
  SET_RATE,
  SELECT_CURRENCY,
  SELECT_CLIENT_INFO,
  CREATE_CREDIT_NOTE_SUCCESS,
  CANCEL_CREDIT_NOTE,
  CREDIT_NOTES_LOADED,
  CREATE_PRICING_RULE_SUCCESS,
  CANCEL_PRICING_RULE,
  SET_GIFT_CARD_COUPON_CODE,
  SET_RETURN,
  SET_POS_2_PRINT,
  SET_AVOIR_2_PRINT,
  AVOIR_LIST_LOADED,
  SET_TAX_SALES,
  SET_TPE_TICKET_NUMBER,
  IGNORE_PRICING_RULES,
  FAILED_PRICING_RULES_DATA,
  UPDATE_ITEM_DETAILS,
  UPDATE_STOCK_QUANTITIES,
  CREATE_RETURN_SUCCESS,
  REINITIALIZE_DATA,
} from "./checkout.type";

const initialState = {
  payementsMethods: [],
  products: [],
  itemsFinancier: [],
  orders: [],
  defaultClient: null,
  client: null,
  order: null,
  docCreated: null,
  selectedorder: null,
  paymentDocument: null,
  deliveryDocument: null,
  documentNumber: null,
  discount: 0,
  amount: {
    taxAmount: 0,
    totalAmount: null,
    depositAmount: 0.0,
    restAmount: null,
    payments: [],
    isOrder: false,
    cheques: [],
  },
  selectedCoupon: {},
  coupons: [],
  pricingRules: null,
  holds: [],
  tax: null,
  currencyExchangeRate: 1,
  salesPersonList: [],
  selectedSalesPerson: [],
  detailClient: null,
  itemDetails: [],
  showCalculator: false,
  recentOrders: [],
  customRef: "",
  returnedInvoice: null,
  exchangeRate: { TND_TND: 1 },
  selectCurrency: null,
  selectClientInfo: [],
  creditNoteList: null,
  creditNotes: [],
  pricingRule: null,
  selectedGiftCardCoupon: {},
  creditNoteCoupon: null,
  posInv2Print: null,
  avoir2Print: null,
  avoirList: [],
  taxsales: null,
  ignorePricingRules: true,
  recentReturns: [],
  stockUpdates: {}, 
  persistedStockUpdates: {},
  initialQuantities: {},
  stockUpdateHistory: [],
  pricingRulesMsg: null,
  TPEticketnumber: null,
  temporaryHolds: [],
  holdselected: null,
  holdDetails: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENTS_METHODS_LOADED:
      return {
        ...state,
        payementsMethods: action.payements,
      };
    case UPDATE_PAYMENT:
      return {
        ...state,
        amount: action.amount,
      };
      case CREATE_RETURN_SUCCESS:
      const returnDoc = action.payload?.docs?.[0];
      const returnedItems = returnDoc?.items || [];
      const newStockUpdates = { ...state.persistedStockUpdates };
      const updateTime = new Date().getTime();
      
      returnedItems.forEach(item => {
        const qty = Math.abs(item.qty);
        newStockUpdates[item.item_code] = (newStockUpdates[item.item_code] || 0) + qty;
      });

      try {
        localStorage.setItem('persistedStockUpdates', JSON.stringify(newStockUpdates));
      } catch (err) {
        console.error('Error saving stock updates:', err);
      }

      return {
        ...state,
        persistedStockUpdates: newStockUpdates,
        stockUpdateHistory: [
          ...state.stockUpdateHistory,
          {
            timestamp: updateTime,
            updates: returnedItems.map(item => ({
              item_code: item.item_code,
              qty: Math.abs(item.qty)
            }))
          }
        ]
      };
      case UPDATE_PRODUCTS:
        const updatedProducts = action.products.map(product => {
          const stockAdjustment = (state.stockUpdates[product.item_code] || 0) + 
                                 (state.persistedStockUpdates[product.item_code] || 0);
          if (stockAdjustment > 0) {
            return {
              ...product,
              actual_qty: product.actual_qty + stockAdjustment
            };
          }
          return product;
        });
        
        return {
          ...state,
          products: updatedProducts
        };
        case REINITIALIZE_DATA:
      let persistedUpdates = {};
      try {
        const savedUpdates = localStorage.getItem('persistedStockUpdates');
        if (savedUpdates) {
          persistedUpdates = JSON.parse(savedUpdates);
        }
      } catch (err) {
        console.error('Error loading persisted stock updates:', err);
      }

      return {
        ...state,
        stockUpdates: {},
        persistedStockUpdates: persistedUpdates
      };
    case UPDATE_SELECTED_CLIENT:
      return {
        ...state,
        client: action.client,
      };
    case ORDERS_LOADED:
      return {
        ...state,
        orders: action.orders,
      };
    case Order_LODED:
      return {
        ...state,
        selectedorder: action.order.docs[0],
      };
    case DOCUMENT_NUMBER_LODED:
      return {
        ...state,
        documentNumber: action.documentNumber.message.count,
      };
    case SET_DOCUMENT_PAYMENT:
      return {
        ...state,
        paymentDocument: action.document.message,
      };
    case SET_DOCUMENT_DELIVERY:
      return {
        ...state,
        deliveryDocument: action.document.message,
      };
    case SET_DOCUMENT_INVOICE:
      return {
        ...state,
        invoiceDocument: action.document.message,
      };
    case SET_DISCOUNT_LODED:
      return {
        ...state,
        discount: action.discount,
      };
    case CREATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        docCreated: action.payload,
      };
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        order: JSON.parse(action.payload),
      };
    case CREATE_EMAIL_SUCCESS:
      const email = state.orders;
      return {
        ...state,
        discount: 0,
        amount: {
          totalAmount: null,
          depositAmount: 0.0,
          restAmount: null,
          payments: [],
          isOrder: false,
          cheques: [],
        },
        products: [],
        itemFinancier: [],
        client: state.defaultClient,
        order: JSON.parse(action.payload),
        orders: email,
      };
    case CREATE_HOLD_SUCCESS:
      return {
        ...state,
        holds: [...state.holds, action.payload],
        discount: 0,
        amount: {
          totalAmount: null,
          depositAmount: 0.0,
          restAmount: null,
          payments: [],
          cheques: [],
        },
        products: [],
        itemFinancier: [],
        client: state.defaultClient,
      };
    case CREATE_BULK_HOLD_SUCCESS:
      return {
        ...state,
        holds: [...state.holds, action.payload],
        discount: 0,
        amount: {
          totalAmount: null,
          depositAmount: 0.0,
          restAmount: null,
          payments: [],
          cheques: [],
        },
        products: [],
        itemFinancier: [],
        client: state.defaultClient,
        selectedCoupon: {},
        currencyExchangeRate: 1,
        temporaryHolds: [],
        holdselected: null,
      };
    case CANCEL_CHECKOUT:
      return {
        ...state,
        discount: 0,
        amount: {
          totalAmount: null,
          depositAmount: 0.0,
          restAmount: null,
          payments: [],
          isOrder: false,
          cheques: [],
        },
        products: [],
        itemFinancier: [],
        client: state.defaultClient,
        order: null,
        currencyExchangeRate: 1,
        selectedSalesPerson: [],
        returnedInvoice: null,
        creditNoteList: null,
        selectedCoupon: {},
        pricingRules: null,
        TPEticketnumber: null,
        holdselected: null,
        selectedItems: [],
        itemDetails: [],
        pricingRulesMsg: null,
        ignorePricingRules: true,
        holdDetails: null,
        recentReturns: [],
        stockUpdates: {},
        initialQuantities: {}
      };
    case SET_HOLD_DATA:
      return {
        ...state,
        discount: action.holdData.discount,
        amount: action.holdData.amount,
        products: action.holdData.products,
        itemFinancier: action.holdData.itemFinancier,
        client: {
          customer_name: action.holdData.client,
        },
      };
    case SET_COUPON_CODE:
      return {
        ...state,
        selectedCoupon: action.selectedCoupon,
      };
    case SET_PRICING_RULES:
      return {
        ...state,
        pricingRules: action.pricingRules,
      };
    case SET_DEFAULT_TAX:
      return {
        ...state,
        tax: action.tax,
      };
    case SALES_PERSON_LOADED:
      return {
        ...state,
        salesPersonList: action.salesPersonList,
      };
    case UPDATE_SELECTED_SALESPERSON:
      return {
        ...state,
        selectedSalesPerson: action.selectedSalesPerson,
      };
    case HOLDS_LOADED:
      return {
        ...state,
        holds: action.payload,
      };
    case SAVE_HOLD:
      return {
        ...state,
        temporaryHolds: [...state.temporaryHolds, action.payload],
        discount: 0,
        amount: {
          totalAmount: null,
          depositAmount: 0.0,
          restAmount: null,
          payments: [],
          cheques: [],
        },
        products: [],
        itemFinancier: [],
        client: state.defaultClient,
        selectedCoupon: {},
        currencyExchangeRate: 1,
        holdselected: null,
      };
    case SET_HOLD_SELECTED:
      return {
        ...state,
        holdselected: action.holdselected,
      };
    case RELOAD_HOLD:
      return {
        ...state,
        temporaryHolds: action.payload,
      };
    case UPDATE_CURRENCY_RATE:
      return {
        ...state,
        currencyExchangeRate: Array.isArray(action.payload)
          ? action.payload[0].exchange_rate
          : 1,
      };
    case SET_DEFAULT_CLIENT:
      return {
        ...state,
        defaultClient: action.client,
      };
    case SET_DETAIL_CLIENT:
      return {
        ...state,
        detailClient: action.detailClient,
      };
    case ITEM_DETAILS_LOADED:
      const newItem = JSON.parse(action.payload).message;
      const matchingIndex = state.itemDetails.findIndex(
        (item) =>
          item.item_code === newItem.item_code &&
          item.quantity === newItem.quantity
      );
      if (matchingIndex >= 0) {
        // Remove the existing item at the matching index
        const updatedItemDetails = [...state.itemDetails];
        updatedItemDetails.splice(matchingIndex, 1);

        // Add the new item in place of the existing item
        updatedItemDetails.splice(matchingIndex, 0, newItem);

        return { ...state, itemDetails: updatedItemDetails };
      } else {
        // Add the new item to the itemDetails array
        const updatedItemDetails = [...state.itemDetails, newItem];
        return { ...state, itemDetails: updatedItemDetails };
      }
    case UPDATE_ITEM_DETAILS: // Add this case to handle updating itemDetails
      return { ...state, itemDetails: action.itemDetails };
    case CALCULATOR_TOGGLED:
      return {
        ...state,
        showCalculator: action.showCalculator,
      };
    case RECENT_ORDERS_LOADED:
      return {
        ...state,
        recentOrders: JSON.parse(action.payload).message,
      };
    case CUSTOM_REF_LOADED:
      return {
        ...state,
        customRef: action.customRef,
      };
    case RETURN_LOADED:
      return {
        ...state,
        returnedInvoice: JSON.parse(action.payload).message,
      };
    case UPDATE_RETURNS:
      return {
        ...state,
        returnedInvoice: action.returnedInvoice,
      };
    case SET_RATE:
      return {
        ...state,
        exchangeRate: action.exchangeRate,
      };
    case SELECT_CURRENCY:
      return {
        ...state,
        selectCurrency: action.selectCurrency,
      };
    case SELECT_CLIENT_INFO:
      return {
        ...state,
        selectClientInfo: action.selectClientInfo,
      };
    case CREATE_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        creditNoteList: JSON.parse(action.payload).docs[0],
      };
    case CANCEL_CREDIT_NOTE:
      return {
        ...state,
        creditNoteList: null,
      };
    case CREDIT_NOTES_LOADED:
      return {
        ...state,
        creditNotes: action.payload,
      };
    case CREATE_PRICING_RULE_SUCCESS:
      return {
        ...state,
        pricingRule: JSON.parse(action.payload).docs[0],
      };
    case CANCEL_PRICING_RULE:
      return {
        ...state,
        pricingRule: null,
      };
    case SET_GIFT_CARD_COUPON_CODE:
      return {
        ...state,
        selectedGiftCardCoupon: action.selectedGiftCardCoupon,
      };
    case SET_RETURN:
      return {
        ...state,
        creditNoteCoupon: action.creditNoteCoupon,
      };
    case SET_POS_2_PRINT:
      return {
        ...state,
        posInv2Print: action.posInv2Print,
      };
    case SET_AVOIR_2_PRINT:
      return {
        ...state,
        avoir2Print: action.avoir2Print,
      };
    case AVOIR_LIST_LOADED:
      return {
        ...state,
        avoirList: action.payload,
      };
    case SET_TAX_SALES:
      return {
        ...state,
        taxsales: action.payload,
      };
    case SET_TPE_TICKET_NUMBER:
      return {
        ...state,
        TPEticketnumber: action.payload,
      };
    case IGNORE_PRICING_RULES:
      return {
        ...state,
        ignorePricingRules: action.ignorePricingRules,
      };
    case HOLD_LOADED:
      return {
        ...state,
        holdDetails: action.payload,
      };
    case CLEAR_STATE:
      return (state = initialState);
    default:
      return state;
  }
};

export default reducer;
