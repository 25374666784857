// import React, { useEffect, useState } from "react";
// import { useStyles } from "./style";
// import {
//   Table,
//   Grid,
//   Typography,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
//   Box,
// } from "@material-ui/core";
// import { useSelector } from "react-redux";
// import { formatDate } from "../../../../helpers/utils";
// import clsx from "clsx";
// import { makeStyles } from "@material-ui/core/styles";
// import { useTranslation } from "react-i18next";
// import localforage from "localforage";

// const useTableStyles = makeStyles((theme) => ({
//   table: {
//     marginBottom: "30px",
//     marginTop: "0px",
//   },
//   tableRow: {
//     boxShadow: "0px 2px 8px #091F461D",
//     borderRadius: 6,
//     "& .MuiTableCell-root:first-child": {
//       borderTopLeftRadius: 6,
//       borderBottomLeftRadius: 6,
//     },
//     "& .MuiTableCell-root:last-child": {
//       borderTopRightRadius: 6,
//       borderBottomRightRadius: 6,
//     },
//   },
//   tableCell: {
//     background: "#fff",
//     fontSize: "0.675rem",
//     padding: "6px 10px",
//     color: theme.palette.primary.main,
//   },
//   paper: {
//     overflow: "auto",
//     padding: "0px 4px",
//   },
//   tablecellHeader: {
//     paddingBottom: 0,
//     color: "#657288",
//     fontSize: 12,
//     borderBottom: "none",
//     paddingLeft: 13,
//   },
//   active: {
//     boxShadow: "0px 2px 8px #091F4643",
//     fontWeight: 600,
//     "& .MuiTableCell-root": {
//       fontWeight: 600,
//     },
//   },
//   filterBlock: {
//     borderTop: "1px solid #eef0f2",
//     borderBottom: "1px solid #eef0f2",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//     flexWrap: "wrap",
//   },
//   filter: {
//     display: "flex",
//     flexWrap: "wrap",
//   },
//   sort: {
//     fontSize: "0.8125rem",
//     color: "#042765",
//   },
//   chip: {
//     borderRadius: 6,
//     color: "#042765",
//     marginTop: 10,
//     marginRight: 12,
//   },
//   remove: {
//     color: "#042765",
//     fontSize: "0.8125rem",
//     textDecoration: "underline",
//     marginTop: 10,
//     cursor: "pointer",
//   },
// }));

// export const ZReport = React.forwardRef(
//   ({ details,salesPersonClosed, signature, caisseBalance }, ref) => {
    
//     const classes = useStyles();
//     const tableClasses = useTableStyles();
//     const { t } = useTranslation(["common"]);
//     const posOpenedDate = useSelector((state) => state.Login.posOpeningDate);
//     const selectedData = useSelector((state) => state.Login.selectedData);
//     const cushFund = useSelector((state) => state.Login.cushFund);
//     const activeImageTheme = useSelector((state) => state.Theme.image);
//     const coffreOperations = useSelector((state) => state.Pos.coffreOperations);
//     const globalDefaults = useSelector((state) => state.Login.globalDefaults);

//     const [taxList, setTaxList] = useState([]);

//     const header = {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
//       Authorization: JSON.parse(localStorage.getItem("user")).token,
//     };
//     const [salesPersonOpenPos, setSalesPersonOpenPos] = useState(null);
//     const [salesPersonClosePos, setSalesPersonClosePos] = useState(null);
  
//     useEffect(() => {
//       const loadCachedSalesPerson = async () => {
//         try {
//           const cachedSalesPersonOpenPos = await localforage.getItem("reduxPersist:SalesPerson");
//           setSalesPersonOpenPos(cachedSalesPersonOpenPos);
         
//         } catch (error) {
//           console.error("Error retrieving cached sales person data:", error);
//         }
//       };
  
//       loadCachedSalesPerson();
//     }, []);
     
//     const getTaxList = () => {
//       fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify({
//           doctype: "Account",
//           fields: ["*"],
//         }),
//       })
//         .then((res) => {
//           return res.json();
//         })
//         .then((data) => {
//           if (Array.isArray(data)) {
//             setTaxList(data);
//           }
//         });
//     };
//     const totalOpeningAmount = details?.payment_reconciliation?.reduce(
//       (sum, row) => sum + (row.opening_amount || 0),
//       0
//     );
    
//     const totalExpectedAmount = details?.payment_reconciliation?.reduce(
//       (sum, row) => sum + (row.expected_amount || 0),
//       0
//     );
   
//     const totalCaisseBalance = details?.payment_reconciliation?.reduce(
//       (sum, row) =>   (parseFloat(caisseBalance) || 0),
//       0
//     );
//     console.log('details.payment_mode',details)
//     const totalDifference = details?.payment_reconciliation?.reduce(
//       (sum, row) =>
//         sum + Math.abs(row.expected_amount - parseFloat(caisseBalance || 0)),
//       0
//     );
    
//     const expectedAmountSum =
//     details?.payment_reconciliation?.reduce((sum, row) => {
//       return sum + (row.expected_amount || 0); // Ensure undefined or null values are handled
//     }, 0) || 0;
//     useEffect(() => {
//       getTaxList();
//     }, []);
//     //console.log(props.details);
//     return (
//       <Box ref={ref}>
//         <Box className={classes.ticketHeader}>
//           <div className={classes.logo}>
//             <img
//               alt=""
//               src={activeImageTheme}
//               style={{
//                 display: "block",
//                 marginLeft: "40%",
//                 marginBottom: "30px",
//                 width: "20%",
//               }}
//             />
//           </div>
//           {selectedData &&
//             Array.isArray(selectedData.contact) &&
//             selectedData.contact.map((row) => (
//               <>
//                 <Box fontWeight={"normal"} fontSize={"12px"}>
//                   {row.address_line_1 === null ? "-" : row.address_line_1}
//                 </Box>
//                 <Box fontWeight={"normal"} fontSize={"12px"}>
//                   {t("common:city")}: {row.city === null ? "-" : row.city}
//                 </Box>
//                 <Box fontWeight={"normal"} fontSize={"12px"}>
//                   {t("common:tels")}:{" "}
//                   {row.phone_no === null ? "-" : row.phone_no} /{" "}
//                   {row.mobile_no === null ? "-" : row.mobile_no}
//                 </Box>
//               </>
//             ))}
//           <Box fontWeight={"normal"} fontSize={"12px"}>
//             {formatDate(posOpenedDate, "LL")} {formatDate(Date(), "HH:mm:ss")}
//           </Box>
//         </Box>
//         <Box display={"flex"} paddingTop={"10px"} marginBottom={"20px"}>
//           <Grid item xs={12} sm={6}>
//             <Box paddingLeft={10}>
//               <Typography color={"primary"} fontSize={"11px"}>
//                 <strong>{t("common:dateDébutPeriode")}:</strong>
//               </Typography>
//               <Typography color={"primary"} fontSize={"11px"}>
//                 {details
//                   ? formatDate(details.period_start_date, "DD/MM/YYYY HH:mm")
//                   : ""}
//               </Typography>
//               <Typography color={"primary"} fontSize={"11px"}>
//                 <strong>{t("common:dateFinPeriode")}: </strong>
//               </Typography>
//               <Typography color={"primary"} fontSize={"11px"}>
//                 {details
//                   ? formatDate(details.period_end_date, "DD/MM/YYYY HH:mm")
//                   : ""}
//               </Typography>
//               <Typography color={"primary"} fontSize={"11px"}>
//                 <strong>{t("common:cashier")}: </strong>
//               </Typography>
//               <Typography color={"primary"} fontSize={"11px"}>
//                 {details ? details.user : ""}
//               </Typography>

//               <Typography color={"primary"} fontSize={"11px"}>
//                 <strong>{t("common:vendeurOpenPos")}: </strong>
//               </Typography>
//               <Typography color={"primary"} fontSize={"11px"}>
//                 {salesPersonOpenPos}
//               </Typography>


//             </Box>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <Box paddingRight={10}>
//               <Typography color={"primary"} fontSize={"11px"}>
//                 <strong>{t("common:dateComptablisation")}: </strong>
//               </Typography>
//               <Typography color={"primary"} fontSize={"11px"}>
//                 {details ? details.posting_date : ""}
//               </Typography>
//               <Typography color={"primary"} fontSize={"11px"}>
//                 <strong>{t("common:ouvCaisse")}: </strong>
//               </Typography>
//               <Typography color={"primary"} fontSize={"11px"}>
//                 {details ? details.pos_opening_entry : ""} ({cushFund}{" "}
//                 {globalDefaults?.default_currency})
//               </Typography>
//               <Typography color={"primary"} fontSize={"11px"}>
//                 <strong>{t("common:nomCaissier")}: </strong>
//               </Typography>
//               <Typography color={"primary"} fontSize={"11px"}>
//                 {details ? details.pos_profile : ""}
//               </Typography>

//               <Typography color={"primary"} fontSize={"11px"}>
//                 <strong>{t("common:vendeurClosePos")}: </strong>
//               </Typography>
//               <Typography color={"primary"} fontSize={"11px"}>
//                 {salesPersonClosed}
//               </Typography> 

//             </Box>
//           </Grid>
//         </Box>
//         <Box className={clsx(classes.tabHead, classes.paper)}>
//           <Typography color={"primary"} fontSize={"14px"}>
//             {t("common:opJournee")}
//           </Typography>
//           <Table className={tableClasses.table} aria-label="simple table">
//             <TableHead>
//               <TableRow>
//                 <TableCell
//                   className={tableClasses.tablecellHeader}
//                   align="left"
//                 >
//                   {t("common:facture")}
//                 </TableCell>
//                 <TableCell
//                   className={tableClasses.tablecellHeader}
//                   align="left"
//                 >
//                   {t("common:date")}
//                 </TableCell>
//                 <TableCell
//                   className={tableClasses.tablecellHeader}
//                   align="left"
//                 >
//                   {t("common:vendeurs")}
//                 </TableCell>
//                 <TableCell
//                   className={tableClasses.tablecellHeader}
//                   align="left"
//                 >
//                   {t("common:client")}
//                 </TableCell>
//                 <TableCell
//                   className={tableClasses.tablecellHeader}
//                   align="left"
//                 >
//                   {t("common:amnt")}
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {details &&
//                 details.pos_transactions &&
//                 Array.isArray(details?.pos_transactions) &&
//                 details?.pos_transactions.map((row, index) => (
//                   <TableRow className={tableClasses.tableRow} key={index}>
//                     <TableCell className={tableClasses.tableCell} align="left">
//                       {row?.pos_invoice}
//                     </TableCell>
//                     <TableCell className={tableClasses.tableCell} align="left">
//                       {row?.posting_date}
//                     </TableCell>
//                     <TableCell className={tableClasses.tableCell} align="left">
//                     {salesPersonOpenPos}
//                     </TableCell>
//                     <TableCell className={tableClasses.tableCell} align="left">
//                       {row?.customer}
//                     </TableCell>
//                     <TableCell className={tableClasses.tableCell} align="left">
//                       {row?.grand_total.toFixed(3)}{" "}
//                       {globalDefaults?.default_currency}
//                     </TableCell>
//                   </TableRow>
//                 ))}
//             </TableBody>
//           </Table>
//         </Box>
//         <Box className={clsx(classes.tabHead, classes.paper)}>
//           <Typography color={"primary"} fontSize={"14px"}>
//             {t("common:regJournee")}
//           </Typography>
//           <Table className={tableClasses.table} aria-label="simple table">
//             <TableHead>
//               <TableRow>
//                 <TableCell
//                   className={tableClasses.tablecellHeader}
//                   align="left"
//                 >
//                   {t("common:modePayment")}
//                 </TableCell>
//                 <TableCell
//                   className={tableClasses.tablecellHeader}
//                   align="left"
//                 >
//                   {t("common:balanceOuverture")}
//                 </TableCell>
//                 <TableCell
//                   className={tableClasses.tablecellHeader}
//                   align="left"
//                 >
//                   {t("common:mntAttendu")}
//                 </TableCell>
//                 {/* <TableCell
//                   className={tableClasses.tablecellHeader}
//                   align="left"
//                 >
//                   {t("common:mntFermeture")}
//                 </TableCell> */}
//                 {/* <TableCell
//                   className={tableClasses.tablecellHeader}
//                   align="left"
//                 >
//                   {t("common:diff")}
//                 </TableCell> */}
//               </TableRow>
//             </TableHead>
//             <TableBody> 


    
//     <>
//   {details?.payment_reconciliation?.map((row, index) => (
//     <TableRow key={index} className={tableClasses.tableRow}>
//       <TableCell className={tableClasses.tableCell} align="left">
//         {row.mode_of_payment || "N/A"} {/* Access payment_mode directly */}
//       </TableCell>
//       <TableCell className={tableClasses.tableCell} align="left">
//         {row.mode_of_payment === "Cash"
//           ? `${row.opening_amount || "0"} ${globalDefaults?.default_currency || ""}`
//           : "-"}
//       </TableCell>
//       <TableCell className={tableClasses.tableCell} align="left">
//         {row.expected_amount} {globalDefaults?.default_currency || ""}
//       </TableCell>
//     </TableRow>
//   ))}

//   {/* Always include Timbre Fiscal row */}
//   <TableRow className={tableClasses.tableRow}>
//     <TableCell className={tableClasses.tableCell} align="left">
//       Timbre Fiscal
//     </TableCell>
//     <TableCell className={tableClasses.tableCell} align="left">
//       -
//     </TableCell>
//     <TableCell className={tableClasses.tableCell} align="left">
//    { details?.pos_transactions.length * 0.1} {globalDefaults?.default_currency || ""}
//     </TableCell>
//   </TableRow>
// </>


// </TableBody>

//           </Table>
//         </Box>
//         <Box className={clsx(classes.tabHead, classes.paper)}>
//           <Typography color={"primary"} fontSize={"14px"}>
//             {t("common:tax")}
//           </Typography>
//           <Table className={tableClasses.table} aria-label="simple table">
//             <TableHead>
//               <TableRow>
//                 <TableCell
//                   className={tableClasses.tablecellHeader}
//                   align="left"
//                 >
//                   {t("common:account")}
//                 </TableCell>
//                 <TableCell
//                   className={tableClasses.tablecellHeader}
//                   align="left"
//                 >
//                   {t("common:taux")}
//                 </TableCell>
//                 <TableCell
//                   className={tableClasses.tablecellHeader}
//                   align="left"
//                 >
//                   {t("common:amnt")}
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {details &&
//                 details.taxes &&
//                 Array.isArray(details.taxes) &&
//                 details.taxes.map((row, index) => {
//                   const matchingTax = taxList.find(
//                     (tax) => tax.name === row.account_head
//                   );
//                   return (
//                     <TableRow className={tableClasses.tableRow} key={index}>
//                       <TableCell
//                         className={tableClasses.tableCell}
//                         align="left"
//                       >
//                         {row.account_head}
//                       </TableCell>
//                       <TableCell
//                         className={tableClasses.tableCell}
//                         align="left"
//                       >
//                         {matchingTax ? `${matchingTax.tax_rate} %` : "N/A"}
//                       </TableCell>
//                       <TableCell
//                         className={tableClasses.tableCell}
//                         align="left"
//                       >
//                         {row.amount.toFixed(3)}{" "}
//                         {globalDefaults?.default_currency}
//                       </TableCell>
//                     </TableRow>
//                   );
//                 })}
//             </TableBody>
//           </Table>
//         </Box>
//         <Box className={clsx(classes.tabHead, classes.paper)}>
//           <Typography color={"primary"} fontSize={"14px"}>
//             {t("common:OpCoffre")}
//           </Typography>
//           <Table className={tableClasses.table} aria-label="simple table">
//             <TableHead>
//               <TableRow>
//                 <TableCell
//                   className={tableClasses.tablecellHeader}
//                   align="left"
//                 >
//                   {t("common:date")}
//                 </TableCell>
//                 <TableCell
//                   className={tableClasses.tablecellHeader}
//                   align="left"
//                 >
//                   {t("common:type")}
//                 </TableCell>
//                 <TableCell
//                   className={tableClasses.tablecellHeader}
//                   align="left"
//                 >
//                   {t("common:amnt")}
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {coffreOperations.map((o, i) => (
//                 <TableRow className={tableClasses.tableRow} key={i}>
//                   <TableCell className={tableClasses.tableCell} align="left">
//                     {formatDate(o.updated_at, "DD/MM/YYYY HH:mm")}
//                   </TableCell>
//                   <TableCell className={tableClasses.tableCell} align="left">
//                     {o.type}
//                   </TableCell>
//                   <TableCell className={tableClasses.tableCell} align="left">
//                     {o.type === "Deposit" ? "+" : "-"}
//                     {Math.abs(o.amount)} {globalDefaults?.default_currency}
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </Box>
//         <Box display={"flex"} paddingTop={"30px"}>
//           <Grid item xs={12} sm={6}>
//             <Box paddingLeft={10}>
//               <Typography color={"primary"} fontSize={"11px"}>
//                 <strong>{t("common:totalCaisse")}: </strong>{" "}
//                 {details && cushFund
//                   ? (details.grand_total + parseFloat(cushFund)).toFixed(3)
//                   : "0.000"}{" "}
//                 {globalDefaults?.default_currency}
//               </Typography>
//               <Typography color={"primary"} fontSize={"11px"}>
//                 <strong>{t("common:NbTickets")}: </strong>{" "}
//                 {details && details.pos_transactions.length}{" "}
//               </Typography>
//               <Typography color={"primary"} fontSize={"11px"}>
//                 <strong>{t("common:NbArticles")}: </strong>{" "}
//                 {details ? details.total_quantity : null}
//               </Typography>
//               <Typography color={"primary"} fontSize={"11px"}>
//                 <strong>{t("common:totalHT")}: </strong>
//                 {details ? details.net_total.toFixed(3) : "0.000"}{" "}
//                 {globalDefaults?.default_currency}
//               </Typography>
//               <Typography color={"primary"} fontSize={"11px"}>
//                 <strong>{t("common:total")} T.T.C.: </strong>{" "}
//                 {details ? details.grand_total.toFixed(3) : "0.000"}{" "}
//                 {globalDefaults?.default_currency}
//               </Typography>
//             </Box>
//           </Grid>
//         </Box>
//         <div className={classes.company}>
//           <Box fontWeight={"bold"} fontSize={"14px"}>
//             {t("common:SigCaissier")}
//           </Box>
//           <div>
//             {signature ? (
//               <img className={classes.float} alt="signature" src={signature} />
//             ) : null}
//           </div>
//         </div>
//       </Box>
//     );
//   }
// );
import React, { useEffect, useState } from "react";
import { useStyles } from "./style";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
  Grid
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { formatDate } from "../../../../helpers/utils";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import localforage from "localforage";
import { useTableStyles } from "../../../../styles/table.style";

const receiptStyle = {
  textAlign: "center",
  fontSize: "20px",
  fontFamily: "Verdana",
  maxWidth: "600px",
   margin: "auto",
   padding: "10px",
  border: "1px solid #ddd",
};

export const ZReport = React.forwardRef(
  ({ details, salesPersonClosed, signature, caisseBalance }, ref) => {
    
    const classes = useStyles();
    const { t } = useTranslation(["common"]);
    const posOpenedDate = useSelector((state) => state.Login.posOpeningDate);
    const selectedData = useSelector((state) => state.Login.selectedData);
    const cushFund = useSelector((state) => state.Login.cushFund);
    const activeImageTheme = useSelector((state) => state.Theme.image);
    const coffreOperations = useSelector((state) => state.Pos.coffreOperations);
    const globalDefaults = useSelector((state) => state.Login.globalDefaults);
    const tableClasses = useTableStyles();
    const [taxList, setTaxList] = useState([]);
    const invoices = useSelector((state) => state.Pos.invoices);

console.log('invoices',invoices)

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
      Authorization: JSON.parse(localStorage.getItem("user")).token,
    };
    const [salesPersonOpenPos, setSalesPersonOpenPos] = useState(null);

    useEffect(() => {
      const loadCachedSalesPerson = async () => {
        try {
          const cachedSalesPersonOpenPos = await localforage.getItem(
            "reduxPersist:SalesPerson"
          );
          setSalesPersonOpenPos(cachedSalesPersonOpenPos);
        } catch (error) {
          console.error("Error retrieving cached sales person data:", error);
        }
      };

      loadCachedSalesPerson();
    }, []);

    const getTaxList = () => {
      fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
        method: "POST",
        headers: header,
        body: JSON.stringify({
          doctype: "Account",
          fields: ["*"],
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (Array.isArray(data)) {
            setTaxList(data);
          }
        });
    };
    var timbreFiscal = parseFloat((details?.pos_transactions.length * 0.1).toFixed(1));


    const totalOpeningAmount = details?.payment_reconciliation?.reduce(
      (sum, row) => sum + (row.opening_amount || 0),
      0
    );

    useEffect(() => {
      getTaxList();
    }, []);

    return (
      <Box ref={ref} style={receiptStyle}>
          <Box className={classes.ticketHeader}>
          <div className={classes.logo}>
             <img
              alt=""
              src={activeImageTheme}
              style={{
                display: "block",
                marginLeft: "40%",
                marginBottom: "15px",
                width: "20%",
              }}
            />
          </div>
          <Box fontWeight={"bold"} fontSize={"20px"} marginBottom={"20px"}>
                 Ticket Z
                </Box>
          {selectedData &&
            Array.isArray(selectedData.contact) &&
            selectedData.contact.map((row) => (
              <>
              <Box fontWeight="normal" fontSize="12px">
  {row.address_line_1 === null ? "-" : row.address_line_1.toUpperCase()}
</Box>


                <Box fontWeight={"normal"} fontSize={"12px"}>
                  {t("common:city")}: {row.city === null ? "-" : row.city}
                </Box>
                <Box fontWeight={"normal"} fontSize={"12px"}>
                  {t("common:tels")}:{" "}
                  {row.phone_no === null ? "-" : row.phone_no} /{" "}
                  {row.mobile_no === null ? "-" : row.mobile_no}
                </Box>
              </>
            ))}
          <Box fontWeight={"normal"} fontSize={"12px"}>
            {formatDate(posOpenedDate, "LL")} {formatDate(Date(), "HH:mm:ss")}
          </Box>
        </Box>
        <Box display={"flex"} paddingTop={"10px"} marginBottom={"20px"}>
          <Grid item xs={12} sm={6}>
            <Box paddingLeft={10}>
              <Typography color={"primary"} fontSize={"11px"}>
                <strong>{t("common:dateDébutPeriode")}:</strong>
              </Typography>
              <Typography color={"primary"} fontSize={"11px"}>
                {details
                  ? formatDate(details.period_start_date, "DD/MM/YYYY HH:mm")
                  : ""}
              </Typography>
              <Typography color={"primary"} fontSize={"11px"}>
                <strong>{t("common:dateFinPeriode")}: </strong>
              </Typography>
              <Typography color={"primary"} fontSize={"11px"}>
                {details
                  ? formatDate(details.period_end_date, "DD/MM/YYYY HH:mm")
                  : ""}
              </Typography>
              <Typography color={"primary"} fontSize={"11px"}>
                <strong>{t("common:cashier")}: </strong>
              </Typography>
              <Typography color={"primary"} fontSize={"11px"}>
                {details ? details.user : ""}
              </Typography>

              <Typography color={"primary"} fontSize={"11px"}>
                <strong>{t("common:vendeurOpenPos")}: </strong>
              </Typography>
              <Typography color={"primary"} fontSize={"11px"}>
                {salesPersonOpenPos}
              </Typography>


            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box paddingRight={10}>
              <Typography color={"primary"} fontSize={"11px"}>
                <strong>{t("common:dateComptablisation")}: </strong>
              </Typography>
              <Typography color={"primary"} fontSize={"11px"}>
                {details ? details.posting_date : ""}
              </Typography>
              <Typography color={"primary"} fontSize={"11px"}>
                <strong>{t("common:ouvCaisse")}: </strong>
              </Typography>
              <Typography color={"primary"} fontSize={"11px"}>
                {details ? details.pos_opening_entry : ""} ({cushFund}{" "}
                {globalDefaults?.default_currency})
              </Typography>
              <Typography color={"primary"} fontSize={"11px"}>
                <strong>{t("common:nomCaissier")}: </strong>
              </Typography>
              <Typography color={"primary"} fontSize={"11px"}>
                {details ? details.pos_profile : ""}
              </Typography>

              <Typography color={"primary"} fontSize={"11px"}>
                <strong>{t("common:vendeurClosePos")}: </strong>
              </Typography>
              <Typography color={"primary"} fontSize={"11px"}>
                {salesPersonClosed}
              </Typography> 

            </Box>
          </Grid>
        </Box>
        

        {/* Divider */}
        <hr />

        {/* Transaction Details */}
        <Box>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            {t("common:detailsTransaction")}
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("common:ticketDeCaisse")}</TableCell>
                <TableCell>{t("common:client")}</TableCell>
                <TableCell>{t("common:vendeurs")}</TableCell>
                <TableCell>{t("common:amnt")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {details?.pos_transactions && details.pos_transactions.length > 0 &&
  details.pos_transactions.map((row, index) => (
    <TableRow key={index}>
      <TableCell>{row?.pos_invoice || "N/A"}</TableCell>
      <TableCell>{row?.customer || "N/A"}</TableCell>
      <TableCell>{row?.sales_team || "N/A"}</TableCell>
      <TableCell>
        {row?.grand_total != null
          ? `${row.grand_total.toFixed(3)} ${globalDefaults?.default_currency || ""}`
          : "N/A"}
      </TableCell>
    </TableRow>
  ))
}

            </TableBody>
          </Table>
        </Box>

        {/* Divider */}
        <hr />

        {/* Payment Reconciliation */}
        <Box>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            {t("common:reglements")}
          </Typography> 
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("common:modePayment")}</TableCell>
                <TableCell>{t("common:balanceOuverture")}</TableCell>
                <TableCell>{t("common:mntAttendu")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
  {details?.payment_reconciliation.map((row, index) => (
    <TableRow key={index}>
      <TableCell>{row.mode_of_payment || "N/A"}</TableCell>
      <TableCell>
        {row.mode_of_payment === "Cash"
          ? `${(row.opening_amount || 0).toFixed(3)} ${globalDefaults?.default_currency || ""}`
          : "-"}
      </TableCell>
      <TableCell>
        {(row.expected_amount || 0).toFixed(3)} {globalDefaults?.default_currency || ""}
      </TableCell>
    </TableRow>
  ))}
</TableBody>
          </Table>
        </Box>
        <hr />
        <Box >
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            {t("common:tax")}
          </Typography>
          <Table className={tableClasses.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                 
                <TableCell
                  className={tableClasses.tablecellHeader}
                  align="left"
                >
                  {t("common:tauxDeTax")}
                </TableCell>
                <TableCell
                  
                  align="left"
                >
                  {t("common:amnt")}{""}{"PU"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {details &&
                details.taxes &&
                Array.isArray(details.taxes) &&
                details.taxes.map((row, index) => {
                  const matchingTax = taxList.find(
                    (tax) => tax.name === row.account_head
                  );
                  return (
                    <TableRow  key={index}>
                       
                      <TableCell
                        
                        align="left"
                      >
                        {matchingTax ? `${matchingTax.tax_rate} %` : "N/A"}
                      </TableCell>
                      <TableCell
                        
                        align="left"
                      >
                        {row.amount.toFixed(3)}{" "}
                        {globalDefaults?.default_currency}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
        {/* Divider */}
        <hr />

        {/* Totals Section */}
        <Box>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            {t("common:total")}
          </Typography>
          <Table>
            <TableBody>
            <TableRow>
 
 <TableCell>Timbre Fiscal</TableCell>
 <TableCell>
   {timbreFiscal}{" "}
  {globalDefaults?.default_currency}
 </TableCell>
</TableRow>
            <TableRow>
                <TableCell>{t("common:totalCaisse")}</TableCell>
                <TableCell>
                {
  details &&
  (
    (parseFloat(details.grand_total || 0) +
    parseFloat(timbreFiscal || 0) +
    parseFloat(cushFund || 0))
  ).toFixed(3)
}

                 {globalDefaults?.default_currency}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("common:totalHT")}</TableCell>
                <TableCell>
                  {details?.net_total.toFixed(3) || "0.000"}{" "}
                  {globalDefaults?.default_currency || ""}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("common:TotalTTC")}</TableCell>
                <TableCell>
                {details && details.grand_total != null
    ? details.grand_total.toFixed(3)
    : "0.000"}{" "}
  {globalDefaults?.default_currency || ""}
                </TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>{t("common:NbTickets")}</TableCell>
                <TableCell>{details?.pos_transactions.length || 0}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t("common:NbProduits")}</TableCell>
                <TableCell>
                {invoices && invoices.length > 0
  ? invoices.reduce((total, invoice) => {
      const itemsQtySum = invoice?.items?.reduce((sum, item) => sum + (item.qty || 0), 0);
      return total + itemsQtySum;
    }, 0)
  : 0}

</TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Box>
      
        
        {/* Divider */}
        <hr />

        {/* Signature Section */}
        <Box>
          <Typography>{t("common:SigCaissier")}</Typography>
          {signature && (
            <img
              alt="Signature"
              src={signature}
              style={{ width: "60px", margin: "10px auto" }}
            />
          )}
        </Box>

        {/* Footer */}
        <Typography style={{ marginTop: "10px", fontSize: "10px" }}>
          {t("common:Merci")} {selectedData?.company_name || " CSS Store"}!
        </Typography>
      </Box>
    );
  }
);
