import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import { useTableStyles } from "../../../../theme/table.style";
import { Chip } from "@material-ui/core";
import {
  LoadDetailClient,
  SetDetailClient,
  SetHoldDataInCheckout,
  ignorePricingRules,
  loadItemDetail,
  sendReservedCommandEmail,
} from "../../../../store/checkout/checkout.action";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  ClickAwayListener,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Typography,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useHistory } from "react-router";
import clsx from "clsx";
import { formatDate } from "../../../../helpers/utils";
import {
  deleteHoldById,
  reloadHold,
  LoadHoldById,
  setHoldSelected,
} from "../../../../store/hold/hold.action";
import { useTranslation } from "react-i18next";
import { symbol } from "../../../../helpers/utils";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CallMadeIcon from "@material-ui/icons/CallMade";
import AlertModal from "../../../../components/common/alertModal";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import PrintIcon from "@material-ui/icons/Print";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { PrintReservedCommand } from "../printDoc/PrintReservedCommand";
import EmailIcon from "@material-ui/icons/Email";
import { CircularProgressLoader } from "../../../../components/loader/circularLoader";
import { Ticket } from "../../../payement/components/ticket_hold_res";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 24,
    height: 24,
  },
  avatarCell: {
    width: 24,
  },
  selectInput: {
    "& .MuiInputBase-root": {
      background: "#F4F7F9",
      borderRadius: 5,
      border: 0,
    },
    "& .MuiOutlinedInput-input": {
      padding: "2.9px 14px",
      fontSize: 14,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
  selectItems: {
    width: "100%",
    fontSize: 14,
    color: theme.palette.primary.main,
    display: "flex",
    justifyContent: "left",
  },
  icons: {
    color: theme.palette.primary.main,
    marginRight: 10,
    marginTop: 3,
    height: 15,
    width: 15,
  },
  print: {
    display: "none",
  },
  paper: {
    width: "500px",
    textAlign: "center",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  label: {
    textAlign: "left",
    fontSize: 12,
    marginLeft: 5,
    marginBottom: 5,
    marginTop: 5,
  },
  formControl: {
    marginBottom: 5,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  statusChip: {
    color: "white",
    fontSize: "12px",
    height: "24px",
  },
  statusCancelled: {
    backgroundColor: "#dc3545",
  },
  statusWaiting: {
    backgroundColor: "#ff9800",
  },
  statusPaid: {
    backgroundColor: "#4caf50",
  },
}));

export const HoldTable = (props) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const dispatch = useDispatch();
  const componentRefFacture = useRef();
  var themes = useSelector((state) => state.Theme.themes);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const currencies = useSelector((state) => state.Client.currencies);
  const temporaryholdOrders = useSelector(
    (state) => state.Checkout.temporaryHolds
  );
  const contactCompany = useSelector((state) => state.Login.selectedData);
  const holdselected = useSelector((state) => state.Checkout.holdselected);

  const contactClientList = useSelector(
    (state) => state.Checkout.detailClient?.__onload?.contact_list
  );
  const store = useSelector((state) => state.Login.selectedData?.store);

  const { t } = useTranslation(["common", "hold", "fulfillment", "checkout"]);
  const [action, setAction] = useState(0);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [state, setState] = useState({
    open: false,
    row: null,
  });
  const [email, setEmail] = React.useState("");

  const uniqueEmailIdsSet = contactClientList?.flatMap((item) => [
    item.email_id,
    ...item.email_ids.map((entry) => entry.email_id),
  ]);

  const uniqueEmailIds = [...new Set(uniqueEmailIdsSet)];

  const [loading, setLoading] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState(false);

  const handleOpenModal = async (row) => {
    try {
      setLoading(true);
      const clientReference = row?.ref_client || row?.client;
      await dispatch(LoadDetailClient(clientReference));
      await dispatch(LoadHoldById(row?.id));
      setOpenEmail(true);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const handleOpenPrintModal = async (row) => {
    try {
      setLoadingPrint(true);
      await dispatch(LoadHoldById(row?.id));
      setOpen(true);
    } catch (error) {
    } finally {
      setLoadingPrint(false);
    }
  };

  const handleOpen = (row) => () => {
    setState({ open: true, row: row });
  };

  const loadItemDetails = (items, hold) => {
    items?.forEach((item) => {
      let doc = {
        doctype: "POS Invoice",
        company: globalDefaults.default_company,
        posting_date: formatDate(new Date(), "YYYY-MM-DD"),
        currency: globalDefaults.default_currency,
        price_list_currency: globalDefaults.default_currency,
        ignore_pricing_rule: 0,
        party_account_currency: globalDefaults.default_currency,
        items: [
          {
            doctype: "POS Invoice Item",
            item_group: item.item_group,
            parentfield: "items",
            parenttype: "POS Invoice",
            qty: item.qty,
            item_code: item.item_code,
          },
        ],
        pos_profile: hold.pos_profile,
        set_warehouse: store,
        customer_name: hold.client,
        customer: hold.client,
      };
      let args = {
        item_code: item.item_code,
        set_warehouse: store,
        customer: hold.client,
        currency: globalDefaults.default_currency,
        price_list_currency: globalDefaults.default_currency,
        company: globalDefaults.default_company,
        ignore_pricing_rule: 0,
        doctype: "POS Invoice",
        pos_profile: hold.pos_profile,
      };
      dispatch(loadItemDetail(args, doc));
    });
    dispatch(
      ignorePricingRules(hold?.ignore_pricing_rule === 1 ? true : false)
    );
  };

  const handleFinaliserReserved = async (data) => {
    await dispatch(LoadHoldById(data?.id));
    await loadItemDetails(data?.products, data);
    const newData = {
      ...data,
      amount: {
        ...data.amount,
        depositAmount: 0, // Set to null or any other desired value
      },
    };
    await dispatch(SetHoldDataInCheckout(newData));

    (data.amount && data.amount.depositAmount != 0) ||
    (data.amount.payments &&
      data.amount.payments.length > 0 &&
      data?.ignore_pricing_rule != 0)
      ? history.push("/checkout/payement/1")
      : history.push("/checkout");
  };

  const handleFinaliser = async (data) => {
    await dispatch(SetHoldDataInCheckout(data));
    await loadItemDetails(data?.products, data);
    data.amount && data.amount.payments && data.amount.payments.length > 0
      ? history.push("/checkout/payement/1")
      : history.push("/checkout");

    if (data?.id) {
      dispatch(deleteHoldById(data.id));
    } else {
      dispatch(
        reloadHold(
          temporaryholdOrders?.filter((el) => el.index !== data?.index)
        )
      );
    }
  };

  const handleDetails = async (data) => {
    if (data?.id) {
      history.push(`/holds/${data?.id}`);
    } else {
      history.push(`/holds/t-${data?.index}`);
    }
  };
  const currencySymbol =
    globalDefaults &&
    symbol(globalDefaults?.default_currency, currencies)?.symbol;

  const getPaidAmount = (row) => {
    const totalPayments = row?.amount?.payments;
    if (totalPayments) {
      return totalPayments.reduce((a, v) => (a = a + parseFloat(v.amount)), 0);
    }
    return 0;
  };

  const getTotalAmount = (row) => {
    const total = row?.amount?.totalAmount;
    if (total) {
      if (row.discount) {
        return total - (total * row.discount) / 100;
      } else {
        return total;
      }
    }
    return 0;
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "annulé":
      case "cancel":
        return classes.statusCancelled;
      case "en attente":
      case "partly paid":
        return classes.statusWaiting;
      case "payé":
      case "paid":
        return classes.statusPaid;
      default:
        return classes.statusCancelled;
    }
  };

  const getStatusLabel = (status) => {
    switch (status?.toLowerCase()) {
      case "cancel":
        return "ANNULÉ";
      case "partly paid":
        return "EN ATTENTE";
      case "paid":
        return "PAYÉ";
      default:
        return status?.toUpperCase();
    }
  };

  const SendEmail = () => {
    const activeTheme = themes?.findIndex((obj) => obj.active === 1);
    const theme_id = themes[activeTheme]?.theme_id;
    if (holdselected && email) {
      let content = {
        ...holdselected,
        currency: globalDefaults?.default_currency,
        company: globalDefaults?.default_company,
      };
      dispatch(sendReservedCommandEmail(email, content, theme_id));
    }
    setOpenEmail(false);
    dispatch(SetDetailClient(null));
  };

  const alert = (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false);
      }}
    >
      <div className={classes.paper} width="auto">
        <Typography align={"center"} color={"primary"}>
          {t("common:printRecu")}
        </Typography>
        <Box mt={3} justifyContent="center" display="flex">
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t("common:annuler")}
          </Button>
          <ReactToPrint
            trigger={() => (
              <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: 10 }}
              >
                {t("common:imprimer")}
              </Button>
            )}
            content={() => componentRefFacture.current}
            onAfterPrint={() => {
              dispatch(setHoldSelected(null));
            }}
          />
        </Box>
      </div>
    </ClickAwayListener>
  );
  const body = (
    <div className={classes.paper} width="auto">
      <Typography align={"center"} color={"primary"}>
        {t("common:msgModalMail")} :
      </Typography>
      <Box mt={3}>
        <InputLabel className={classes.label}>Emails(s)</InputLabel>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            input={
              <OutlinedInput
                align="left"
                name="Email"
                id="demo-simple-select-outlined-label"
              />
            }
          >
            {uniqueEmailIds?.map((emailId, index) => (
              <MenuItem key={index} value={emailId} align="left">
                {emailId}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box mt={3} justifyContent="center" display="flex">
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setOpenEmail(false);
          }}
        >
          {t("common:annuler")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          style={{ marginLeft: 10 }}
          onClick={() => SendEmail()}
          disabled={email === "" || holdselected === null}
        >
          {t("common:envoyer")}
        </Button>
      </Box>
    </div>
  );

  if (loading || loadingPrint) return <CircularProgressLoader />;
  return (
    <Box mt={2} className={tableClasses.paper}>
      <Table
        className={clsx(tableClasses.table, classes.selectInput)}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:fullname")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:date")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:MontTotal")}
            </TableCell>
            {!props.isAwaitingOrders && (
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("hold:depositAmnt")}
              </TableCell>
            )}
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("checkout:couponCode")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:statut")}
            </TableCell>
            <TableCell
              className={tableClasses.tablecellHeader}
              align="left"
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.orders.map((row, index) => (
            <TableRow className={tableClasses.tableRow} key={index}>
              <TableCell className={tableClasses.tableCell} align="left">
                {row.client}
              </TableCell>
              <TableCell className={tableClasses.tableCell} align="left">
                {formatDate(row.created_at, "YYYY-MM-DD")}
              </TableCell>
              <TableCell className={tableClasses.tableCell} align="left">
                {getTotalAmount(row)?.toFixed(3)} {currencySymbol}
              </TableCell>
              {!props.isAwaitingOrders && (
                <TableCell className={tableClasses.tableCell} align="left">
                  {row?.amount?.depositAmount
                    ? Number(row?.amount?.depositAmount).toFixed(3)
                    : getPaidAmount(row)?.toFixed(3)}{" "}
                  {currencySymbol}
                </TableCell>
              )}
              <TableCell className={tableClasses.tableCell} align="left">
                {row.coupon ? row.coupon : "-"}
              </TableCell>
              <TableCell className={tableClasses.tableCell} align="left">
                {row.payment_status && (
                  <Chip
                    className={clsx(
                      classes.statusChip,
                      getStatusColor(row.payment_status)
                    )}
                    label={getStatusLabel(row.payment_status)}
                  />
                )}
              </TableCell>
              {!props?.isReservation && (
                <TableCell
                  className={tableClasses.tableCell}
                  align="left"
                  style={{ color: row?.id ? "#00b32b" : "#ff3e00" }}
                >
                  {row?.id ? t("common:Registered") : t("common:temporary")}
                </TableCell>
              )}

              <TableCell className={tableClasses.tableCell} align="left">
                <FormControl>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    defaultValue={0}
                    IconComponent={ExpandMore}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    input={
                      <OutlinedInput id="demo-simple-select-outlined-label" />
                    }
                  >
                    <MenuItem
                      className={classes.selectItems}
                      disabled
                      value={0}
                    >
                      Actions
                    </MenuItem>
                    {!props.isAwaitingOrders &&
                      row?.payment_status === "partly paid" &&
                      row?.amount?.payments &&
                      row?.amount?.payments.length > 0 && (
                        <MenuItem
                          onClick={() => handleFinaliserReserved(row)}
                          component={"button"}
                          className={classes.selectItems}
                          value={1}
                        >
                          <CallMadeIcon className={classes.icons} />{" "}
                          {t("hold:finaliser")}
                        </MenuItem>
                      )}

                    {props.isAwaitingOrders && (
                      <MenuItem
                        onClick={() => handleFinaliser(row)}
                        component={"button"}
                        className={classes.selectItems}
                        value={1}
                      >
                        <CallMadeIcon className={classes.icons} />{" "}
                        {t("hold:finaliser")}
                      </MenuItem>
                    )}

                    <MenuItem
                      onClick={() => handleDetails(row)}
                      component={"button"}
                      className={classes.selectItems}
                      value={2}
                    >
                      <VisibilityIcon className={classes.icons} />{" "}
                      {t("hold:voirDtls")}
                    </MenuItem>
                    {!props.isAwaitingOrders && (
                      <MenuItem
                        component={"button"}
                        className={classes.selectItems}
                        value={3}
                        onClick={() => {
                          handleOpenPrintModal(row);
                        }}
                      >
                        <PrintIcon className={classes.icons} />{" "}
                        {t("common:imprimer")}
                      </MenuItem>
                    )}
                    {!props?.isReservation ||
                      (row.payment_status === "partly paid" && (
                        <MenuItem
                          onClick={handleOpen(row)}
                          component={"button"}
                          className={classes.selectItems}
                          value={4}
                        >
                          {row.amount.depositAmount != 0 ||
                          row.amount.payments.length > 0 ? (
                            <>
                              <AssignmentReturnIcon className={classes.icons} />{" "}
                              {t("common:annuler")}
                            </>
                          ) : (
                            row?.payment_status != "cancel" && (
                              <>
                                <DeleteIcon className={classes.icons} />{" "}
                                {t("common:delete")}
                              </>
                            )
                          )}
                        </MenuItem>
                      ))}
                    {!props.isAwaitingOrders && (
                      <MenuItem
                        component={"button"}
                        className={classes.selectItems}
                        value={5}
                        onClick={() => handleOpenModal(row)}
                      >
                        <EmailIcon className={classes.icons} />{" "}
                        {t("common:sendByMail")}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <AlertModal
        isDeleteRow
        setOpenModal={setState}
        handleCancel={() => {
          if (
            state?.row?.amount?.depositAmount != 0 ||
            state?.row?.amount?.payments.length > 0
          )
            props?.handleCancelReservation(
              state?.row,
              state?.row?.amount?.depositAmount
                ? Number(state?.row?.amount?.depositAmount).toFixed(3)
                : getPaidAmount(state?.row)?.toFixed(3)
            );
          else {
            props.handleDelete(state.row);
          }
          setState({
            open: false,
            row: null,
          });
        }}
        openModal={state.open}
        title={
          state?.row?.amount?.depositAmount != 0 ||
          state?.row?.amount?.payments?.length > 0
            ? t("common:msgCancelReservation")
            : t("common:msgDeleteAlert")
        }
      />

      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {alert}
      </Modal>
      <Modal
        open={openEmail}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <div className={classes.print}>
        {holdselected && (
          // <PrintReservedCommand
          //   isReserved
          //   order={holdselected ?? holdselected}
          //   document={holdselected ?? holdselected}
          //   isFactureClient={true}
          //   isDeliveryDocument={false}
          //   title={t("common:orderReserved")}
          //   ref={componentRefFacture}
          //   contactCompany={contactCompany && contactCompany.contact}
          // />
          <Ticket 
            ref={componentRefFacture}
            isDuplicata={true}
            row={holdselected}
          />
        )}
      </div>
    </Box>
  );
};
