import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from './store/login/login.action';
import { UpdateThemes } from './store/theme/theme.action';
import { timeout } from './helpers/utils';
import axios from 'axios';
import { Modal, Box, Typography, CircularProgress, IconButton } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

function LogoutPOSListener() {
  const [openModal, setOpenModal] = useState(false); // Modal visibility state
  const themes = useSelector((state) => state.Theme.themes || []);
  const openSession = useSelector((state) => state.Pos.open || {});
  const dispatch = useDispatch();

  const defaultTheme = () => {
    const lastThemeIndex = themes.findIndex((obj) => obj.active === 1);
    const newThemeIndex = 0;
    if (lastThemeIndex !== -1) {
      let theme = themes.find((s) => s.theme_id === newThemeIndex);
      if (theme) {
        themes[lastThemeIndex].active = 0;
        theme.active = 1;
        themes[newThemeIndex] = theme;
        setTimeout(() => {
          dispatch(UpdateThemes(themes));
        }, 300000); // 5 minutes
      }
    }
  };

  const logoutHandler = async () => {
    setOpenModal(true); 

    await timeout(300000); 

    await dispatch(logout()); 
    await timeout(300000);
    defaultTheme();
    setOpenModal(false); 
  };

  useEffect(() => {
    const pollStatus = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URI}/api/pos-opening-entry`,
          {
            doctype: "POS Opening Entry",
            fields: [
              "`tabPOS Opening Entry`.`name`",
              "`tabPOS Opening Entry`.`owner`",
              "`tabPOS Opening Entry`.`modified`",
              "`tabPOS Opening Entry`.`status`",
              "`tabPOS Opening Entry`.`pos_profile`",
            ],
            filters: [["POS Opening Entry", "pos_profile", "=", openSession?.pos_profile]],
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("user")).token,
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            },
          }
        );

        if (response.data[0].status === "Closed") {
          logoutHandler(); 
        }
      } catch (error) {
        console.error("Error fetching POS status:", error);
      }
    };

    
    const intervalId = setInterval(pollStatus, 5000);

   
    return () => clearInterval(intervalId);
  }, [openSession, dispatch, themes]);

  return (
    <>
      <Modal
        open={openModal}
        aria-labelledby="farewell-modal"
        aria-describedby="farewell-message"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <IconButton color="primary">
            <ExitToAppIcon sx={{ fontSize: 40, color: '#3f51b5' }} />
          </IconButton>
          <Typography variant="h6" id="farewell-modal" sx={{ mt: 2 }}>
            Goodbye!
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
          Vous êtes en train d'être déconnecté. Merci d'utiliser le système.
          </Typography>
          <CircularProgress sx={{ mt: 2, color: '#3f51b5' }} />
        </Box>
      </Modal>
    </>
  );
}

export default LogoutPOSListener;
