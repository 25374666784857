{
  /* eslint-disable */
}
import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import { useSelector } from "react-redux";
import { formatDate } from "../../../../helpers/utils";
import {
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useTableStyles } from "../../../../styles/table.style";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import localforage from "localforage";
 
export const XReport = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { t } = useTranslation(["common"]);
  const tableClasses = useTableStyles();
  const posOpenedDate = useSelector((state) => state.Login.posOpeningDate);
  const open = useSelector((state) => state.Pos.open);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const cushFund = useSelector((state) => state.Login.cushFund);
  const signature = useSelector((state) => state.Login.signature);
  const consumer = useSelector((state) => state.Login.consumer);
  const user = useSelector((state) => state.Login.user);
  const openSignature = useSelector((state) => state.Login.openSignature);
  const coffreOperations = useSelector((state) => state.Pos.coffreOperations);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const [salesPerson,setSalesPerson] = useState('')
  const getSalesOpenPos = async() =>{
    const cachedSalesPersonSelected = await localforage.getItem("reduxPersist:SalesPerson");
    setSalesPerson(cachedSalesPersonSelected)
  }
  useEffect(()=>{
    getSalesOpenPos()
  },)
  return (
    <Box ref={ref}>
      <Box className={classes.ticketBlock}>
        <Box className={classes.ticketHeader}>
          <div className={classes.logo}>
            <img
              alt=""
              src={"/img/log_100.png"}
              style={{
                display: "block",
                marginLeft: "40%",
                marginBottom: "30px",
                width: "20%",
              }}
            />
          </div>
          <Box>{globalDefaults?.default_company}</Box>
          {selectedData &&
            Array.isArray(selectedData.contact) &&
            selectedData.contact.map((row) => (
              <>
              
                <Box fontWeight={"normal"} fontSize={"12px"}>
                  {row.address_line_1 === null ? "-" : row.address_line_1}
                </Box>
                <Box fontWeight={"normal"} fontSize={"12px"}>
                  {t("common:city")}: {row.city === null ? "-" : row.city}
                </Box>
                <Box fontWeight={"normal"} fontSize={"12px"}>
                  {t("common:tels")}:{" "}
                  {row.phone_no === null ? "-" : row.phone_no} /{" "}
                  {row.mobile_no === null ? "-" : row.mobile_no}
                </Box>
              </>
            ))}
          <Box fontWeight={"normal"} fontSize={"12px"}>
            {t("common:ouvertureCaisse")}: {formatDate(posOpenedDate, "LL")}{" "}
            {formatDate(posOpenedDate, "HH:mm:ss")}
          </Box>
          <Box fontWeight={"bold"} fontSize={"14px"}>
            {t("common:par")}: {open ? open.pos_profile : "-"}
          </Box>
        </Box>
        <Box mt={3} className={clsx(classes.tabHead, classes.paper)}>
          <Table className={tableClasses.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  className={tableClasses.tablecellHeader}
                  align="left"
                >
                  {t("common:modePayment")}{" "}
                </TableCell>
                <TableCell
                  className={tableClasses.tablecellHeader}
                  align="left"
                >
                  {t("common:balanceOuverture")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {open &&
                Array.isArray(open.balance_details) &&
                open.balance_details.map((row, index) => (
                  <TableRow className={tableClasses.tableRow} key={index}>
                    <TableCell className={tableClasses.tableCell} align="left">
                      {row.mode_of_payment}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell} align="left">
                      {row.opening_amount.toFixed(3)}{" "}
                      {globalDefaults?.default_currency}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        <Box className={clsx(classes.tabHead, classes.paper)}>
          <Table className={tableClasses.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  className={tableClasses.tablecellHeader}
                  align="left"
                >
                  {t("common:facture")}
                </TableCell>
                <TableCell
                  className={tableClasses.tablecellHeader}
                  align="left"
                >
                  {t("common:date")}
                </TableCell>
                <TableCell
                  className={tableClasses.tablecellHeader}
                  align="left"
                >
                  {t("common:client")}
                </TableCell>
                <TableCell
                  className={tableClasses.tablecellHeader}
                  align="left"
                >
                  {t("common:vendeur")}
                </TableCell>
                <TableCell
                  className={tableClasses.tablecellHeader}
                  align="left"
                >
                  {t("common:amnt")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.details &&
                props.details &&
                Array.isArray(props.details) &&
                props.details.map((row, index) => (
                  
                  <TableRow className={tableClasses.tableRow} key={index}>
                    <TableCell className={tableClasses.tableCell} align="left">
                      {row.name}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell} align="left">
                      {row.posting_date}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell} align="left">
                      {row.customer}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell} align="left">
  {row.sales_team?.[0]?.sales_person ? row.sales_team[0].sales_person : salesPerson}
</TableCell>

                    <TableCell className={tableClasses.tableCell} align="left">
                      {row.grand_total.toFixed(3)} {row.currency}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        <Box className={clsx(classes.tabHead, classes.paper)}>
          {coffreOperations.length != 0 && (
            <>
              <Typography color={"primary"} fontSize={"14px"}>
                {t("common:OpCoffre")}
              </Typography>
              <Table className={tableClasses.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={tableClasses.tablecellHeader}
                      align="left"
                    >
                      {" "}
                      {t("common:date")}
                    </TableCell>
                    <TableCell
                      className={tableClasses.tablecellHeader}
                      align="left"
                    >
                      {" "}
                      {t("common:type")}
                    </TableCell>
                    <TableCell
                      className={tableClasses.tablecellHeader}
                      align="left"
                    >
                      {" "}
                      {t("common:amnt")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coffreOperations.map((o, i) => (
                    <TableRow className={tableClasses.tableRow} key={i}>
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {formatDate(o.updated_at, "DD/MM/YYYY HH:mm")}
                      </TableCell>
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {o.type}
                      </TableCell>
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {o.amount} {globalDefaults?.default_currency}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </Box>
      </Box>
      <Box display={"flex"} paddingTop={"30px"}>
        <Grid item xs={12} sm={6}>
          <Box paddingLeft={10}>
            <Typography color={"primary"} fontSize={"12px"}>
              <strong>{t("common:totalCaisse")}: </strong>{" "}
              {  (
                    props.details.reduce(
                      (a, v) =>
                        (a = a + parseFloat(v.grand_total * v.conversion_rate)),
                      0
                    ) + parseFloat(cushFund)
                  ).toFixed(3)
                 }{" "}
              {globalDefaults?.default_currency}
            </Typography>
            <Typography color={"primary"} fontSize={"12px"}>
              <strong>{t("common:NbTickets")}: </strong>{" "}
              {props.details && props.details.length}{" "}
            </Typography>
            <Typography color={"primary"} fontSize={"12px"}>
              <strong>{t("common:NbArticles")}: </strong>{" "}
              {props.details
                ? props.details.reduce(
                    (a, v) =>
                      (a =
                        a +
                        v.items.reduce(
                          (c, u) => (c = c + parseFloat(u.qty)),
                          0
                        )),
                    0
                  )
                : null}
            </Typography>
            <Typography color={"primary"} fontSize={"12px"}>
              <strong>{t("common:totalHT")}: </strong>
              {props.details
                ? props.details
                    .reduce(
                      (a, v) =>
                        (a = a + parseFloat(v.net_total * v.conversion_rate)),
                      0
                    )
                    .toFixed(3)
                : "0.000"}{" "}
              {globalDefaults?.default_currency}
            </Typography>
            <Typography color={"primary"} fontSize={"12px"}>
              <strong>{t("common:total")} T.T.C.: </strong>{" "}
              {props.details
                ? props.details
                    .reduce(
                      (a, v) =>
                        (a = a + parseFloat(v.grand_total * v.conversion_rate)),
                      0
                    )
                    .toFixed(3)
                : "0.000"}{" "}
              {globalDefaults?.default_currency}
            </Typography>
          </Box>
        </Grid>
      </Box>
      {props.isSign && (
        <div className={classes.company}>
          <Box textAlign={"left"} fontWeight={"bold"} fontSize={"12px"}>
            {t("common:SigCaissier")} {user && user.email} |{" "}
            {user && user.full_name}
          </Box>
          <div>
            {props.signature ? (
              <img
                className={classes.float}
                alt="signature"
                src={props.signature}
              />
            ) : null}
          </div>
        </div>
      )}
      {props.isOpen && (
        <Grid style={{ marginLeft: 40 }} container spacing={3}>
          <Grid item xs={6}>
            <div className={classes.company}>
              <Box textAlign={"left"} fontWeight={"bold"} fontSize={"12px"}>
                {t("common:caissierPrec")} : {user && user.email} |{" "}
                {user && user.full_name}
              </Box>
              <div>
                {signature ? (
                  <img
                    className={classes.float}
                    alt="signature"
                    src={signature}
                  />
                ) : null}
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.company}>
              <Box textAlign={"left"} fontWeight={"bold"} fontSize={"12px"}>
                {t("common:caissierActual")} : {consumer && consumer.email} |{" "}
                {consumer && consumer.full_name}
              </Box>
              <div>
                {openSignature ? (
                  <img
                    className={classes.float}
                    alt="signature"
                    src={openSignature}
                  />
                ) : null}
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </Box>
  );
});