import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import {
  Typography,
  OutlinedInput,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Button,
  Grid,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  makeReturn,
  UpdateReturnInvoice,
  SetAvoirToPrint,
} from "../../../../../store/checkout/checkout.action";
import { Loader } from "../../../../../components/loader";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { AvoirComponentToPrint } from "../../../../../components/componentToPrint/avoir";
import DeleteIcon from "@material-ui/icons/Delete";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";
import AlertModal from "../../../../../components/common/alertModal";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { Voucher } from "../../../../../components/componentToPrint/avoir/voucher";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    margin: "15% auto",
    padding: "20px",
    width: "20%",
  },
  icon: {
    margin: theme.spacing(1),
    color: theme.palette.freeButton.icon,
  },
  delete: {
    cursor: "pointer",
  },
  button: {
    width: "100%",
    height: 90,
    backgroundColor: theme.palette.secondary.main,
    fontSize: 16,
    fontWeight: "bold",
    border: "3px solid",
    borderColor: theme.palette.blue.main,
    "&:hover": {
      backgroundColor: theme.palette.productsTable.button,
      color: theme.palette.freeButton.background,
      border: "3px solid",
      borderColor: theme.palette.secondary.main,
    },
  },
  paper: {
    textAlign: "center",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
  },
  formControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  buttonicon: {
    margin: "5% auto",
    padding: "10px",
    width: "80%",
    position: "relative",
  },
  done: {
    position: "inherit",
    marginLeft: "-20px",
    marginTop: "-7px",
    color: theme.palette.freeButton.text,
  },
  bar: {
    width: 60,
    height: 60,
    cursor: "pointer",
  },
  span: {
    position: "absolute",
    top: "20px",
    left: "18px",
    fontSize: 15,
    color: "#2F4B7C",
  },
  table: {
    background: theme.palette.productsTable.background,
    borderSpacing: "0 5px",
    borderCollapse: "separate",
    "& .MuiTableRow-root": {
      display: "table",
      width: "100%",
      tableLayout: "fixed",
    },
    marginBottom: 0,
  },
  tableRow: {
    height: 25,
    "& .MuiTableCell-root:first-child": {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
    "& .MuiTableCell-root:last-child": {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },
  },
  tableCell: {
    background: theme.palette.productsTable.tableCell,
    padding: "7px 16px",
    color: theme.palette.primary.main,
  },
  tablecellHeader: {
    paddingBottom: 0,
    color: theme.palette.productsTable.tablecellHeader,
    fontSize: 12,
    borderBottom: "none",
  },
  tableBody: {
    display: "block",
    overflow: "auto",
  },
  headPaper: {
    borderRadius: 10,
    padding: theme.spacing(2),
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    marginBottom: 10,
    height: 120,
  },
  discInput: {
    width: 50,
    textAlign: "center",
    "& .MuiInputBase-formControl": {
      marginTop: "-4px",
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
  },
  tableContainer: {
    padding: "0px 20px",
    background: theme.palette.productsTable.background,
    overflow: "auto",
    marginBottom: 20,
    marginTop: 10,
    borderRadius: 19,
    height: "100%",
    "& .MuiInputBase-root": {
      background: theme.palette.productsTable.tableCell,
      marginTop: 20,
    },
    "& .MuiOutlinedInput-input": {
      padding: "7.5px 14px",
      fontSize: 12,
    },
  },
  print: {
    display: "none",
  },
  errorMsg: {
    borderRadius: 5,
    fontWeight: "bold",
    marginBottom: 20,
  },
}));

export const ReturnForm = ({
  setOpenReturnModal,
  motifs,
  setReturnOperation,
  setLoading /* , name */,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const doc2Print = useRef();
  const { t } = useTranslation(["common", "checkout"]);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const companyacounts = useSelector(
    (state) => state.Configuration.companyacounts
  );
  const [open, setOpen] = React.useState(false);

  const returnedInvoice = useSelector(
    (state) => state.Checkout.returnedInvoice
  );
  const [tablequantities, setTablequantities] = useState([]);
  const itemTaxList = useSelector((state) => state.Product.itemTax);
  const avoir2Print = useSelector((state) => state.Checkout.avoir2Print);
  const [avoirPrinted, setAvoirPrinted] = useState(false);
  const [isTreated, setIsTreated] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const isDiscounted = (total, discountPercentage) => {
    if (discountPercentage && discountPercentage != 0) {
      return total - (total * discountPercentage) / 100;
    } else {
      return total;
    }
  };

  const GetTotalPrice = (rows) => {
    var total = 0;
    rows.forEach((element) => {
      if (!element.item_tax_template) {
        total += element.net_amount;
      } else {
        let taxRate = itemTaxList.find(
          (s) => s.name == element.item_tax_template
        )?.tax_rate;
        if (!taxRate) {
          taxRate = 0;
        }
        total +=
          element.net_amount +
          (parseInt(element.qty) * (element.net_rate * taxRate)) / 100;
      }
    });
    return parseFloat(total);
  };

  const GetTotalTax = (rows) => {
    var total = 0;
    rows.forEach((element) => {
      if (element.item_tax_template) {
        let taxRate = itemTaxList.find(
          (s) => s.name == element.item_tax_template
        )?.tax_rate;
        if (!taxRate) {
          taxRate = 0;
        }
        total += parseInt(element.qty) * ((element.net_rate * taxRate) / 100);
      }
    });
    return parseFloat(total);
  };

  const qtyChanges = (itemLine, qty) => {
    setIsUpdated(true);
    itemLine.qty = qty;
    itemLine.stock_qty = qty;
    itemLine.amount = +(qty * itemLine.rate - itemLine.discount_amount);
    itemLine.base_amount = +(qty * itemLine.rate);
    itemLine.net_rate = +(
      itemLine.rate -
      (itemLine.rate * returnedInvoice?.additional_discount_percentage) / 100
    ).toFixed(3);
    itemLine.net_amount = +(qty * itemLine.net_rate);
    itemLine.base_net_amount = +(qty * itemLine.net_rate);
    itemLine.total_weight = qty * itemLine.weight_per_unit;
    return itemLine;
  };

  const netAmount = (items) => {
    return items.reduce((a, v) => (a = a + parseFloat(v.net_amount)), 0);
  };

  const total = (items) => {
    return items.reduce((a, v) => (a = a + parseFloat(v.rate) * v.qty), 0);
  };

  const HandleDelete = (id) => {
    setIsUpdated(true);
    const data = [...returnedInvoice.items];
    const filteredData = data.filter((s) => s.item_code !== id);

    const taxAmount = +GetTotalTax(filteredData);
    const totalAmount = taxAmount + netAmount(filteredData);

    const totalQty = filteredData.reduce(
      (a, v) => (a = a + parseFloat(v.qty)),
      0
    );
    const payments = [
      {
        account: `${companyacounts?.default_cash_account}`,
        amount: +totalAmount,
        base_amount: +totalAmount,
        default: 0,
        docstatus: 0,
        doctype: "Sales Invoice Payment",
        idx: 1,
        mode_of_payment: "Cash",
        parentfield: "payments",
        parenttype: "POS Invoice",
        type: "Cash",
        __islocal: 1,
      },
    ];

    dispatch(
      UpdateReturnInvoice({
        ...returnedInvoice,
        /* pos_profile: returnedInvoice?.pos_profile !== selectedData?.cashier && selectedData?.cashier, */
        items: filteredData,
        payments: payments,
        base_grand_total: +totalAmount,
        base_net_total: +totalAmount - +taxAmount,
        base_paid_amount: +totalAmount,
        base_total: total(filteredData),
        base_total_taxes_and_charges: +taxAmount,
        grand_total: +totalAmount,
        net_total: +totalAmount - +taxAmount,
        paid_amount: +totalAmount,
        total: total(filteredData),
        total_taxes_and_charges: +taxAmount,
        total_qty: totalQty,
      })
    );
  };

  const handleUpdateQuantity = (e, id) => {
    setIsUpdated(true);
    const data = [...returnedInvoice.items];
    const ItemIndex = data.findIndex((s) => s.item_code == id);
    const IndexQtyMax = tablequantities.findIndex((el) => el.item_code == id);

    if (ItemIndex != -1 && IndexQtyMax != -1) {
      if (
        Math.abs(tablequantities[IndexQtyMax].qty) >=
        Math.abs(parseInt(e.target.value))
      ) {
        data[ItemIndex] = qtyChanges(data[ItemIndex], parseInt(e.target.value));
        const items = data;
        const taxAmount = +GetTotalTax(items);
        const totalAmount = taxAmount + netAmount(items);
        const totalQty = items.reduce((a, v) => (a = a + parseFloat(v.qty)), 0);

        const payments = [
          {
            account: `${companyacounts?.default_cash_account}`,
            amount: +totalAmount,
            base_amount: +totalAmount,
            default: 0,
            docstatus: 0,
            doctype: "Sales Invoice Payment",
            idx: 1,
            mode_of_payment: "Cash",
            parentfield: "payments",
            parenttype: "POS Invoice",
            type: "Cash",
            __islocal: 1,
          },
        ];

        dispatch(
          UpdateReturnInvoice({
            ...returnedInvoice,
            /*  pos_profile: returnedInvoice?.pos_profile !== selectedData?.cashier && selectedData?.cashier, */
            items: items,
            payments: payments,
            base_grand_total: +totalAmount,
            base_net_total: +totalAmount - +taxAmount,
            base_paid_amount: +totalAmount,
            base_total: total(items),
            base_total_taxes_and_charges: +taxAmount,
            grand_total: +totalAmount,
            net_total: +totalAmount - +taxAmount,
            paid_amount: +totalAmount,
            total: total(items),
            total_taxes_and_charges: +taxAmount,
            total_qty: totalQty,
          })
        );
      }
    }
  };

  const handleReturn = async () => {
    if (!isUpdated) {
      const dataitems = [...returnedInvoice.items];
      const taxAmount = +GetTotalTax(dataitems);
      const totalAmount = taxAmount + netAmount(dataitems);
      const totalQty = dataitems.reduce(
        (a, v) => (a = a + parseFloat(v.qty)),
        0
      );

      const payments = [
        {
          account: `${companyacounts?.default_cash_account}`,
          amount: +totalAmount.toFixed(3),
          base_amount: +totalAmount.toFixed(3),
          default: 0,
          docstatus: 0,
          doctype: "Sales Invoice Payment",
          idx: 1,
          mode_of_payment: "Cash",
          parentfield: "payments",
          parenttype: "POS Invoice",
          type: "Cash",
          __islocal: 1,
        },
      ];

      const data = {
        ...returnedInvoice,
        payments: payments,
        base_grand_total: +totalAmount.toFixed(3),
        base_net_total: +(totalAmount - taxAmount).toFixed(3),
        base_paid_amount: +totalAmount.toFixed(3),
        base_total: +total(dataitems).toFixed(3),
        base_total_taxes_and_charges: +taxAmount.toFixed(3),
        grand_total: +totalAmount.toFixed(3),
        net_total: +(totalAmount - taxAmount).toFixed(3),
        paid_amount: +totalAmount.toFixed(3),
        total: +total(dataitems).toFixed(3),
        total_taxes_and_charges: +taxAmount.toFixed(3),
        total_qty: totalQty,
        motif: motifs,
        write_off_amount: 0,
      };
      await dispatch(makeReturn(data, globalDefaults?.default_company));
      setAvoirPrinted(true);
      setOpen(false);
    } else {
      const data = {
        ...returnedInvoice,
        write_off_amount: 0,
        paid_amount: +returnedInvoice.grand_total.toFixed(3),
        base_paid_amount: +returnedInvoice.base_grand_total.toFixed(3),
        motif: motifs,
        disabled: false,
      };
      await dispatch(makeReturn(data, globalDefaults?.default_company));
      setAvoirPrinted(true);
      setOpen(false);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => avoir2Print && doc2Print.current,
    onAfterPrint: () => {
      setOpenReturnModal(false);
    },
  });

  const timeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  const waitSyncronisation = async () => {
    setLoading(true);
    setReturnOperation(true);
    await timeout(10000);
    setReturnOperation(false);
  };

  useEffect(() => {
    if (avoirPrinted && avoir2Print) {
      setAvoirPrinted(false);
      setOpen(false);
      handlePrint();
      waitSyncronisation();
    }
  }, [avoir2Print, avoirPrinted]);

  let isupdatedposprofile = false;

  const updateposreturnedInvoice = async () => {
    if (
      returnedInvoice &&
      returnedInvoice?.pos_profile !== selectedData?.cashier &&
      !isupdatedposprofile
    ) {
      isupdatedposprofile = true;
      await dispatch(
        UpdateReturnInvoice({
          ...returnedInvoice,
          pos_profile: selectedData?.cashier,
        })
      );
    }
  };

  useEffect(() => {
    updateposreturnedInvoice();
    if (tablequantities?.length == 0 && Array.isArray(returnedInvoice?.items)) {
      if (!isTreated) {
        returnedInvoice?.items?.forEach((element) => {
          if (element?.qty == 0) {
            HandleDelete(element?.item_code);
          }
        });
        setIsTreated(true);
      } else {
        const tbqty = returnedInvoice.items.map((el) => {
          return { item_code: el.item_code, qty: el.qty };
        });
        setTablequantities(tbqty);
      }
    }
  }, [returnedInvoice, isTreated]);

  const handleCancel = () => {
    setOpenReturnModal(false);
    dispatch(SetAvoirToPrint(null));
    dispatch(UpdateReturnInvoice(null));
  };

  if (!returnedInvoice && !isTreated /*&&  name !== returnedInvoice?.name */) {
    return (
      <div className={classes.paper}>
        <Loader />
      </div>
    );
  }

  if (returnedInvoice.total_qty === 0) {
    return (
      <div className={classes.paper}>
        <Alert severity="warning" className={classes.errorMsg}>
          {t("checkout:MsgRetour")}
        </Alert>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancel}
            >
              {t("common:annuler")}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }

  const GetItemPrice = (row) => {
    let itemPrice = row.net_amount;
    if (row.item_tax_template) {
      let taxRate = itemTaxList.find(
        (s) => s.name === row.item_tax_template
      )?.tax_rate;
      if (taxRate) {
        itemPrice += (row.qty * (row.net_rate * taxRate)) / 100;
      }
    }
    return itemPrice;
  };

  const checkIfDiscounted = (returnedInvoice) => {
    let isDisc = false;
    if (returnedInvoice.additional_discount_percentage !== 0) isDisc = true;
    returnedInvoice.items.forEach((el) => {
      if (el.discount_percentage !== 0) isDisc = true;
    });
    return isDisc;
  };

  const handleQuantityChange = (row, newQty) => {
    const itemIndex = tablequantities.findIndex(
      (el) => el.item_code === row.item_code
    );

    if (itemIndex !== -1) {
      const maxQty = Math.abs(tablequantities[itemIndex].qty);
      const validatedQty = Math.min(Math.max(0, Math.abs(newQty)), maxQty);
      const finalQty = -validatedQty;

      handleUpdateQuantity({ target: { value: finalQty } }, row.item_code);
    }
  };

  const handleIncrement = (row) => {
    const currentQty = Math.abs(row.qty);
    handleQuantityChange(row, -(currentQty + 1));
  };

  const handleDecrement = (row) => {
    const currentQty = Math.abs(row.qty);
    if (currentQty > 0) {
      handleQuantityChange(row, -(currentQty - 1));
    }
  };

  /*   if (checkIfDiscounted(returnedInvoice)) {
      return (
        <div className={classes.paper}>
          <Alert severity='warning' className={classes.errorMsg}>
            {t('checkout:discountedReturn')}
          </Alert>
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item>
              <Button
                variant='contained'
                color='secondary'
                onClick={handleCancel}
              >
                {t('common:annuler')}
              </Button>
            </Grid>
          </Grid>
        </div>
      );
    } */
  return (
    <div className={classes.paper}>
      <Typography
        align={"center"}
        color={"primary"}
        style={{ fontWeight: "bold", fontSize: 20, marginBottom: 20 }}
      >
        {t("checkout:RVente")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box className={classes.headPaper}>
            <Typography align={"left"} color={"primary"}>
              {t("common:client")}: {returnedInvoice?.customer}
            </Typography>
            <Typography align={"left"} color={"primary"}>
              H.T: {returnedInvoice?.net_total?.toFixed(3)}{" "}
              {returnedInvoice.currency}
            </Typography>
            <Typography align={"left"} color={"primary"}>
              T.V.A: {returnedInvoice?.total_taxes_and_charges?.toFixed(3)}{" "}
              {returnedInvoice.currency}
            </Typography>
            <Typography align={"left"} color={"primary"}>
              {t("common:total")}: {returnedInvoice?.grand_total?.toFixed(3)}{" "}
              {returnedInvoice.currency}
            </Typography>
            <Typography align={"left"} color={"primary"}>
              {t("common:qty")}: {returnedInvoice?.total_qty}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.headPaper}>
            <Typography align={"left"} color={"primary"}>
              {t("checkout:RetourSur")}: {returnedInvoice?.return_against}
            </Typography>
            <Typography align={"left"} color={"primary"}>
              {t("common:dateAchat")}: {returnedInvoice?.posting_date}
            </Typography>
            <Typography align={"left"} color={"primary"}>
              {t("common:magasin")}: {returnedInvoice?.items?.[0].warehouse}
            </Typography>
            <Typography align={"left"} color={"primary"}>
              {t("common:cashier")}: {returnedInvoice?.owner}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.tableContainer}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tablecellHeader}>
                {t("common:ref")}
              </TableCell>
              <TableCell className={classes.tablecellHeader}>
                {t("common:produit")}
              </TableCell>
              <TableCell className={classes.tablecellHeader} align="center">
                {t("common:qty")}
              </TableCell>
              <TableCell className={classes.tablecellHeader}>
                {t("common:amnt")}
              </TableCell>
              {returnedInvoice?.items?.length > 1 && (
                <TableCell
                  className={classes.tablecellHeader}
                  align="right"
                  width={15}
                ></TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody} style={{ height: 150 }}>
            {returnedInvoice &&
              returnedInvoice?.items?.map((row, i) => (
                <TableRow className={classes.tableRow} key={i}>
                  <TableCell className={classes.tableCell}>
                    {row.item_code}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.item_name}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <IconButton
                        onClick={() => handleDecrement(row)}
                        size="small"
                        disabled={Math.abs(row.qty) <= 0}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <OutlinedInput
                        style={{ marginTop: 0, width: 80 }}
                        type="number"
                        id="qty-input"
                        className={classes.discInput}
                        value={Math.abs(row.qty)}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue === "" || isNaN(newValue)) {
                            handleQuantityChange(row, 0);
                          } else {
                            handleQuantityChange(row, -parseInt(newValue));
                          }
                        }}
                        inputProps={{
                          min: 0,
                          max: Math.abs(
                            tablequantities.find(
                              (el) => el.item_code === row.item_code
                            )?.qty || 0
                          ),
                        }}
                        variant="outlined"
                      />
                      <IconButton
                        onClick={() => handleIncrement(row)}
                        size="small"
                        disabled={
                          Math.abs(row.qty) >=
                          Math.abs(
                            tablequantities.find(
                              (el) => el.item_code === row.item_code
                            )?.qty || 0
                          )
                        }
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {GetItemPrice(row).toFixed(3)} {returnedInvoice.currency}
                  </TableCell>
                  {returnedInvoice?.items?.length > 1 && (
                    <TableCell
                      className={classes.tableCell}
                      align="right"
                      width={15}
                    >
                      <Box>
                        <DeleteIcon
                          onClick={() => HandleDelete(row.item_code)}
                          className={classes.delete}
                        />
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      <Grid container spacing={2} justifyContent={"center"}>
        <Grid item>
          <Button
            disabled={avoir2Print}
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
          >
            {t("checkout:ERetour")}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={handleCancel}>
            {t("common:fermer")}
          </Button>
        </Grid>
        <Grid item style={{ marginTop: 5 }}>
          {avoir2Print && (
            <ReactToPrint
              trigger={() => (
                <PrintIcon color="primary" className={classes.delete} />
              )}
              content={() => avoir2Print && doc2Print.current}
            />
          )}
        </Grid>
      </Grid>
      <AlertModal
        setOpenModal={setOpen}
        handleCancel={handleReturn}
        openModal={open}
        title={t("checkout:alertReturn")}
      />
      <div className={classes.print}>
        {avoir2Print && (
          // <AvoirComponentToPrint
          //   returnedInvoice={returnedInvoice}
          //   document={avoir2Print}
          //   title={"Avoir"}
          //   ref={doc2Print}
          // />
          <Voucher
            returnedInvoice={returnedInvoice}
            document={avoir2Print}
            title={"Avoir"}
            ref={doc2Print}
            />
        )}
      </div>
    </div>
  );
};
