import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {InvoicesTable} from "./components/invoicestable";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import {useSelector, useDispatch} from "react-redux";
import * as localforage from "localforage";
import Pagination from "@material-ui/lab/Pagination";
import {
    GlobalfilterData,
    GetFilterItems,
    FilterBy,
} from "../../helpers/helper";
import {useTranslation} from "react-i18next";
import {Loader} from "../../components/loader";
import {Empty} from "../../components/empty";
import {Filter} from "../../components/filter";
import {useHistory} from "react-router-dom";
import moment from "moment";

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    const globalDefaults = useSelector((state) => state.Login.globalDefaults);
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        margin: "0.75rem 0px 0.8125rem",
        flexWrap: "wrap",
        "& .MuiInputBase-root": {
            background: theme.palette.white.main,
        },
        "& .MuiOutlinedInput-input": {
            padding: "8.9px 14px",
            fontSize: 12,
        },
    },
    formControl: {
        maxWidth: 300,
        [theme.breakpoints.down("xs")]: {
            marginTop: 10,
        },
    },
    button: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.blue.main,
        border: "1px solid",
        borderColor: theme.palette.secondary.main,
        fontSize: "0.875rem",
        borderRadius: 10,
    },
    icon: {
        marginRight: "0.375rem",
    },
    tabs: {
        "& .MuiTabs-scroller": {
            padding: "0px 30px",
        },
        "& .MuiTabs-flexContainer": {
            borderBottom: "2px solid #ECF2F5",
        },
    },
    tab: {
        borderBottom: "none",
    },
}));
export const Tickets = () => {
        const classes = useStyles();
        const [value, setValue] = React.useState(0);

        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

        const dispatch = useDispatch();
        const history = useHistory();
        const orders = useSelector((state) => state.Fullfilment.fullfilments);
        const user = useSelector((state) => state.Login.user);
        const selectedData = useSelector((state) => state.Login.selectedData);
        const checkoutOrders = useSelector((state) => state.Checkout.orders);
        const invoices = useSelector((state) => state.Pos.invoices);
        const {t} = useTranslation(["common", "order"]);
        const globalDefaults = useSelector((state) => state.Login.globalDefaults);
        const [filtredOrders, setFiltredOrders] = React.useState([]);
        const header = {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user"))?.token,
        };
        const [filtredTickets, setFiltredTickets] = React.useState([]);
        const [filters, setFilters] = React.useState({
            status: [],
        });
        const [orderPage, setOrderPage] = React.useState(1);
        const [invoicepage, setInvoicepage] = React.useState(1);

        const [searchOrder, setSearchOrder] = React.useState("");
        const [invoice, setInvoice] = useState([]);
        const [loading, setLoading] = useState(false);

        const fetchInvoices = async () => {
            setLoading(true);
            try {
                const cachedData = await localforage.getItem("reduxPersist:Pos");
                const posData = cachedData ? JSON.parse(cachedData) : null;
                const posProfile = posData?.open?.pos_profile;
     
                if (!posProfile) {
                    throw new Error("POS profile not found in cache");
                }
     
                const url = `${process.env.REACT_APP_API_URI}/api/pos/pos-closing/get-pos-invoice`;
                const response = await fetch(url, {
                    method: "POST",
                    body: JSON.stringify({
                        start: "2000-01-01",
                        end: moment().format("YYYY-MM-DDTHH:mm:ss"),
                        pos_profile: posProfile,
                        user: posData.open.user,
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "X-API-Key": process.env.REACT_APP_API_KEY,
                        Authorization: JSON.parse(localStorage.getItem("user")).token,
                    },
                });
     
                if (!response.ok) {
                    throw new Error(`Error: ${response.statusText}`);
                }
     
                const invoiceData = await response.json();
             
                if (invoiceData && Array.isArray(invoiceData.message)) {
                    setInvoice(invoiceData.message);
                } else {
                   
                }
            } catch (error) {
                console.error("Failed to fetch invoices:", error);
            } finally {
                setLoading(false);
            }
        };

        const HandleSearchOrder = (event) => {
            setSearchOrder(event.target.value);
            if (value == 0) {
                setFiltredTickets(
                    GlobalfilterData(
                        invoice?.slice(0)?.reverse(), event.target.value, [
                        "posting_date",
                        "name",
                        "customer_name",
                        "custom_ref",
                        "grand_total",
                    ])
                );
                setInvoicepage(1);
            }
        };
        const HandleFilter = (key, item) => {
            setInvoicepage(1);
            const FilterDataBy = {...filters, [key]: item};
            setFilters(FilterDataBy);
            setFiltredTickets(
                invoice?.slice(0)?.reverse().filter(FilterBy(FilterDataBy))
            );
        };

        useEffect(() => {
            setFiltredTickets(invoice?.slice(0)?.reverse());
        }, [invoice]);


        useEffect(() => {
            fetchInvoices()
        }, []);

        return (
            <Box>
                <Box className={classes.header}>
                    <FormControl className={classes.formControl} variant="outlined">
                        <OutlinedInput
                            id="outlined-adornment-weight"
                            value={searchOrder}
                            placeholder={t("order:orderSearch")}
                            onChange={HandleSearchOrder}
                            endAdornment={
                                <InputAdornment position="end">
                                    <span className="icon-search"/>
                                </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                                "aria-label": "weight",
                            }}
                            labelWidth={0}
                        />
                    </FormControl>
                </Box>
                <Box>
                    <Tabs
                        value={value}
                        className={classes.tabs}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab
                            className={classes.tab}
                            label={t("order:tickets")}
                            {...a11yProps(0)}
                        />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <React.Fragment>
                            {loading ? (
                                <Loader />
                            ) : (
                                <>
                                    <Filter
                                        data={GetFilterItems(
                                            [...new Set(filtredTickets.map((item) => item.status))],
                                            { Paid: "Paid", Return: "Return", 0: "-*-***-**-*-**-*-" }
                                        )}
                                        title={"Statut"}
                                        id={"status"}
                                        HandleFilter={HandleFilter}
                                        value={filters.status}
                                        number={filters.status.length}
                                    />
                                    <InvoicesTable
                                        orders={filtredTickets.slice(
                                            (invoicepage - 1) * 10,
                                            invoicepage * 10
                                        )}
                                    />
                                    {Math.ceil(filtredTickets.length / 10) > 0 && (
                                        <Box display="flex" justifyContent="center" m={4}>
                                            <Pagination
                                                onChange={(event, newPage) => setInvoicepage(newPage)}
                                                page={invoicepage}
                                                count={Math.ceil(filtredTickets.length / 10)}
                                                color="primary"
                                            />
                                        </Box>
                                    )}
                                    {filtredTickets && filtredTickets.length === 0 && (
                                        <Empty text={t("order:emptyMsgTicket")} />
                                    )}
                                </>
                            )}
                        </React.Fragment>
                    </TabPanel>
                </Box>
            </Box>
        );
    }
;
