import { GlobalfilterData } from "../../helpers/helper";
import {
  PRODUCTS_LOADED,
  CATEGORIES_LOADED,
  LOAD_FILTRED_PRODUCTS,
  PRODUCT_STOCK_LOADED,
  PRODUCTS_POS_LOADED,
  PRODUCTS_BUNDLE_LOADED,
  SET_SELECTED_PRODUCT_BUNDLE_DETAILS,
  ITEM_COLLECTION_LOADED,
  ITEM_TAX_LOADED,
  ALL_CATEGORIES_LOADED,
  PRODUCT_STOCK,
  IMAGES_LOADED,
} from "./product.type";
import { CREATE_STOCK_ENTRY_SUCCESS } from "../stockEntries/stockEntries.type";
import { CLEAR_STATE } from "../login/login.type";

const initialState = {
  products: [],
  categories: [],
  filtredProducts: [],
  productStock: null,
  productsPos: [],
  productsBundle: [],
  selectedProductBundle: [],
  itemCollection: [],
  itemTax: [],
  allItemCategories: [],
  imagesData: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTS_LOADED:
      return {
        ...state,
        products: Array.isArray(action.products) ? action.products : [],
        filtredProducts: Array.isArray(action.products) ? action.products : [],
      };
    case PRODUCTS_POS_LOADED:
      return {
        ...state,
        productsPos: action.productsPos,
      };
    case PRODUCTS_BUNDLE_LOADED:
      return {
        ...state,
        productsBundle: Array.isArray(action.productsBundle)
          ? action.productsBundle
          : action.productsBundle.message,
      };
    case SET_SELECTED_PRODUCT_BUNDLE_DETAILS:
      return {
        ...state,
        selectedProductBundle: action.payload.docs[0],
      };
    case ITEM_COLLECTION_LOADED:
      return {
        ...state,
        itemCollection: Array.isArray(action.itemCollection)
          ? action.itemCollection
          : action.itemCollection.message,
      };
    case CATEGORIES_LOADED:
      if (action.itemCategories) {
        return {
          ...state,
          categories: action.itemCategories,
        };
      }
      return {
        ...state,
        categories: JSON.parse(action.payload).message,
      };
    case PRODUCT_STOCK_LOADED:
      if (!action.payload) return { ...state, productStock: null };
      return {
        ...state,
        productStock: JSON.parse(action.payload).message,
      };
    case PRODUCT_STOCK:
      return {
        ...state,
        productStock: action.payload,
      };
    case IMAGES_LOADED:
      return {
        ...state,
        imagesData: action.images,
      };
    case ITEM_TAX_LOADED:
      return {
        ...state,
        itemTax: Array.isArray(action.itemTax)
          ? action.itemTax
          : action.itemTax.message,
      };
      case LOAD_FILTRED_PRODUCTS:
        var products = state.products;
        if (!action.resetFilter) {
          if (action.category) {
            products = products.filter((p) => 
              action.filterType === "sousitem_group" 
                ? p.sousitem_group === action.category
                : p.item_group === action.category
            );
          }
          if (action.search !== "") {
            products = GlobalfilterData(state.products, action.search, [
              "barcode",
              "item_name",
              "item_code",
              "description",
              "item_group",
              "standard_rate",
            ]);
          }
        }
        return {
          ...state,
          filtredProducts: products,
        };
    case CREATE_STOCK_ENTRY_SUCCESS:
      const dataPayload = JSON.parse(action.payload).docs[0];
      var productsPos = state.productsPos;
      dataPayload.items.forEach((element) => {
        const dataIndex = productsPos.findIndex(
          (p) => p.item_code == element.item_code
        );
        productsPos[dataIndex].actual_qty =
          productsPos[dataIndex].actual_qty + element.qty;
      });
      return {
        ...state,
        productsPos: productsPos,
      };
    case ALL_CATEGORIES_LOADED:
      return {
        ...state,
        allItemCategories: action.allItemCategories,
      };
    case CLEAR_STATE:
      return (state = initialState);
    default:
      return state;
  }
};

export default reducer;
