import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/esm/locale";
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Grid,
  FormControl,
  Typography,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { GlobalfilterData } from "../../../../helpers/helper";
import { BasicTable } from "../../../checkout/components/basictable";
import {
  SetRequestData,
  CreatePurchaseRequest,
} from "../../../../store/buying/buying.action";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../helpers/utils";
import useScanDetection from "use-scan-detection";
import { initializeBarcodeScanner } from "../../../../barcodeScanner";

const useStyles = makeStyles((theme) => ({
  blockTitle: {
    fontSize: 14,
    marginBottom: 8,
    paddingLeft: 10,
  },
  formControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  paper: {
    paddingLeft: 10,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
    "& .MuiSelect-root": {
      padding: "0.4938rem 0.875rem",
    },
  },
  label: {
    fontSize: 12,
    color: theme.palette.gray.main,
    paddingLeft: 9,
    marginBottom: 6,
  },
  date: {
    marginBottom: 15,
  },
  formBlock: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
    paddingTop: "20px",
  },
  customButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.blue.main,
    fontSize: "0.875rem",
    borderRadius: 10,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.blue.main,
    },
  },
  cancelButton: {
    color: "#909BAB",
  },
  basictable: {
    height: "255px",
  },
  save: {
    marginTop: "10px",
  },
}));

export const Form = ({ setOpenCreationModal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "buying"]);
  const history = useHistory();

  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const defaultwarehouse = useSelector(
    (state) => state.Configuration.defaultwarehouse
  );
  const companyacounts = useSelector(
    (state) => state.Configuration.companyacounts
  );
  const stores = useSelector((state) => state.Login.stores);
  const products = useSelector((state) => state.Product.products);
  const requestData = useSelector((state) => state.Buying.purchaseRequestData);
  const [initialLines, setInitialLines] = React.useState([0, 1, 2, 3]);
  const [suggestion, setsuggestion] = React.useState([]);
  const [searchProduct, setSearchProduct] = React.useState("");
  const [error, setError] = React.useState({
    schedule_date: false,
  });

  const [barcode, setBarcode] = useState("");
  useEffect(() => {
    const onBarcodeDetected = (barcodeScanner) => {
      console.log("Detected Barcode in Another Component:", barcodeScanner);
      setBarcode(barcodeScanner);
    };

    const cleanupScanner = initializeBarcodeScanner(onBarcodeDetected);

    return () => cleanupScanner();
  }, []);

  const handleSearchProduct = (e) => {
    setSearchProduct(e.target.value);
    if (e.target.value == "") {
      setsuggestion([]);
      return;
    }
    const data = GlobalfilterData(
      products?.filter(
        (p) =>
         p?.is_stock_item == 1 &&
          p?.has_variants == 0 &&
          p?.is_purchase_item == 1
      ),
      e.target.value,
     ["barcode","item_code", "item_name", "item_group", "description"]
    );
    setsuggestion(data);
  };

  const handleAddByBarcode = (barcodeValue) => {
    // Find the product in the product list by matching barcode
    const product = products.find((p) => p.barcode === barcodeValue); 
    if (product) {
      handleAddproduct(product);
 
    } else {
      console.error("Product not found for the scanned barcode.");
    }
  };
 
  const handleAddproduct = (id) => {
    var data = suggestion.find((s) => s.item_code == id.item_code) ? id : id;
    data.doctype = "Material Request Item";
    data.warehouse = `${defaultwarehouse}`;
    data.warehouse = `${selectedData?.store}`;
    data.expense_account = `${companyacounts?.default_expense_account}`;
    data.income_account = `${companyacounts?.default_income_account}`;
    const ItemIndex = requestData.items.findIndex((s) => s.item_code === id.item_code);
    if (ItemIndex !== -1) {
      requestData.items[ItemIndex].quantity =
        requestData.items[ItemIndex].quantity + 1;
      requestData.items[ItemIndex].qty = requestData.items[ItemIndex].qty + 1;
      requestData.items[ItemIndex].stock_qty =
        requestData.items[ItemIndex].stock_qty + 1;
    } else {
      data.qty = 1;
      data.quantity = 1;
      data.stock_qty = 1;
      requestData.items.push(data);
      dispatch(SetRequestData({ ...requestData, items: requestData.items }));
      initialLines.splice(-1);
    }
    setsuggestion([]);
    setSearchProduct("");
  };

  const HandleQuantityChange = (onAdd, id) => {
    const ItemIndex = requestData.items.findIndex((s) => s.item_code == id);
    const data = [...requestData.items];
    if (ItemIndex != -1) {
      data[ItemIndex].quantity = onAdd
        ? parseInt(data[ItemIndex].quantity) + 1
        : Math.sign(data[ItemIndex].quantity - 1) == 1
        ? data[ItemIndex].quantity - 1
        : 0;
      data[ItemIndex].qty = data[ItemIndex].quantity;
      data[ItemIndex].stock_qty = data[ItemIndex].quantity;
      dispatch(SetRequestData({ ...requestData, items: data }));
    }
  };

  const HandleDelete = (id) => {
    const data = requestData.items.filter((s) => s.item_code !== id);
    dispatch(SetRequestData({ ...requestData, items: data }));
  };

  const HandleUpdateQuantity = (e, id) => {
    const ItemIndex = requestData.items.findIndex((s) => s.item_code == id);
    const data = [...requestData.items];
    if (ItemIndex != -1) {
      data[ItemIndex].quantity = e.target.value;
      data[ItemIndex].qty = e.target.value;
      data[ItemIndex].stock_qty = e.target.value;
      dispatch(SetRequestData({ ...requestData, items: data }));
    }
  };

  const handleDateChange = (date, event) => {
    isNaN(Date.parse(date))
      ? setError({ ...error, schedule_date: false })
      : dispatch(
          SetRequestData({
            ...requestData,
            schedule_date: date.toISOString().slice(0, 10),
          })
        );
  };

  const handleChange = (event) => {
    dispatch(
      SetRequestData({
        ...requestData,
        [event.target.name]: event.target.value,
      })
    );
    setError({ ...error, set_warehouse: false });
    updateWarehouse(event.target.value);
  };

  const updateWarehouse = (value) => {
    requestData.items.map((item) => {
      item.warehouse = value;
    });
  };

  const handleCancel = () => {
    dispatch(
      SetRequestData({
        //set_warehouse: `${defaultwarehouse}`,
        __isLocal:1,
              __unsaved:1,

        set_warehouse: `${selectedData?.store}`,
        items: [],
        schedule_date: formatDate(new Date(), "YYYY-MM-DD"),
      })
    );
    setOpenCreationModal(false);
  };

  const handleSubmit = () => {
    const newrequestData = { ...requestData,__isLocal:1,__unsaved:1};
    dispatch(
      CreatePurchaseRequest(newrequestData, globalDefaults?.default_company)
    );
    // dispatch(
    //   SetRequestData({
    //     //set_warehouse: `${defaultwarehouse}`,
    //     set_warehouse: `${selectedData?.store}`,
    //     items: [{__isLocal:1,__unsaved:1}],
    //     schedule_date: formatDate(new Date(), "YYYY-MM-DD"),
    //   })
    // );
    setOpenCreationModal(false);
  };

  const isValid = () => {
    if (requestData.items.length == 0) return true;
    return false;
  };

  

  return (
    <Box className={classes.paper}>
      <Box className={classes.formBlock}>
        <Grid container spacing={3}>
          <Grid item xs>
            <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
              <InputLabel required className={classes.label}>
                {t("buying:RequiredOn")}
              </InputLabel>
              <KeyboardDatePicker
                className={classes.date}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                name="schedule_date"
                inputVariant="outlined"
                id="date-picker-inline"
                value={requestData.schedule_date}
                minDate={formatDate(new Date(), "YYYY-MM-DD")}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs>
            <InputLabel
              error={error.set_warehouse}
              required
              className={classes.label}
            >
              {t("common:mgsCible")}
            </InputLabel>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                className={classes.select}
                value={requestData.set_warehouse}
                error={error.set_warehouse}
                IconComponent={ExpandMore}
                onChange={handleChange}
                name="set_warehouse"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {Array.isArray(stores) &&
                  stores.map((row, index) => (
                    <MenuItem value={row.name} key={row.name}>
                      {row.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Typography
        className={classes.blockTitle}
        color={"primary"}
        variant="caption"
        display="block"
      >
        {t("common:article")} *
      </Typography>
      <div className={classes.basictable}>
        <BasicTable
          suggestion={suggestion}
          initialLines={initialLines}
          rows={requestData.items}
          HandleQuantityChange={HandleQuantityChange}
          HandleDelete={HandleDelete}
          handleSearchProduct={handleSearchProduct}
          handleAddproduct={handleAddproduct}
          searchProduct={searchProduct}
          transfer={true}
          stockEntry={true}
          HandleUpdateQuantity={HandleUpdateQuantity}
        />
      </div>
      <Box display="flex" justifyContent="flex-end" className={classes.save}>
        <Button className={classes.cancelButton} onClick={handleCancel}>
          {t("common:annuler")}
        </Button>
        <Button
          onClick={handleSubmit}
          className={classes.customButton}
          size={"small"}
          variant="outlined"
          color="primary"
          disabled={isValid()}
        >
          {t("common:ajouter")}
        </Button>
      </Box>
    </Box>
  );
};
