import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress, Typography, Button, Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import * as localforage from "localforage";
import { useHistory } from "react-router-dom";
import {
  LoadClients,
  LoadLoyaltyPrograms,
  LoadCustomersGroup,
  LoadCurrencies,
  LoadCouponCode,
} from "../../store/client/client.action";
import {LoadItemFinancierList} from "../../store/productFinancier/productFinancier.action";
import {
  CancelCheckout,
  LoadDefaultTaxValue,
  LoadOrders,
  LoadPayments,
  LoadSalesPerson,
  SetInvoicesReturned,
} from "../../store/checkout/checkout.action";
import { REINITIALIZE_DATA, SET_INITIAL_QUANTITIES } from "../../store/checkout/checkout.type";
import {
  LoadInventory,
  SetStockReportData,
} from "../../store/inventory/inventory.action";
import {
  loadCurrenciesData,
  loadAddressData,
  loadCustomersGroupData,
  loadItemCollectionData,
  loadTaxData,
  loadCouponCodes,
  loadClientsData,
  loadInventoryData,
  loadOrdersData,
  loadPayementMethodsData,
  loadProductsData,
  fetchItemFinancierList,
   LoadTotalItems,
  loadCountryData,
  loadTerritoryData,
  loadNotificationsData,
  loadProductsByPos,
  loadProductsBundleData,
  loadDefaultTax,
  loadLoyaltyProgramsData,
  loadSalesPersonData,
  loadAllCategories,
  loadReturnReasons,
  LoadItemsImages,
  loadStockReportData,
  loadMotifInventory,
} from "./api";
import {
  LoadPosProducts,
  LoadProducts,
   LoadProductsBundle,
  LoadItemCollection,
  LoadItemTax,
  LoadItemCategories,
  SetItemImages,
} from "../../store/product/product.action";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import {
  loadCustomerGroupParentData,
  loadDiscountData,
  loadSellingSettingsData,
  loadStockSettingsData,
  loadTerritoryParentData,
  loadWarehouseParentData,
  loadcompanyaccountData,
  loadThemesData,
  loadInvoicesReturnedData,
  loadBuyingSettingsData,
} from "./apiconfig";
import { LoadFullfilments } from "../../store/fullfilment/fullfilment.action";
import {
  LoadAddress,
  LoadCountry,
  LoadTerritoy,
} from "../../store/setting/setting.action";
import { LoadNotifications } from "../../store/broadcastNotification/broadcastNotification.action";
import { loadHolds } from "../../store/hold/hold.action";
import {
  LoadDiscounts,
  LoadWarehouseParent,
  LoadDefaultWarehouse,
  LoadDefaultTerriotry,
  Loadcompanyitemgroup,
  LoadTerritoryParent,
  LoadCustomerGroupParent,
  LoadCompanyAccount,
  LoadCompanyInformation,
  LoadPriceList,
  LoadDefaultCustomerGroup,
  LoadBuyingPriceList,
  LoadLoadReturnReasons,
  LoadMotifInventory,
} from "../../store/configuration/configuration.action";
import { useTranslation } from "react-i18next";
import { GetThemesDb, UpdateThemes } from "../../store/theme/theme.action";
import {
  GetLoyaltyProgramList,
  SetLoyaltyProgramDetails,
  UpdateAppliedLoyCard,
} from "../../store/cart/cart.action";
import { GetPOSList, GetStores } from "../../store/login/login.action";

const LinearProgressWithLabel = (props) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
      {props.value == 100 && <DoneAllIcon style={{ color: "#4caf50" }} />}
    </Box>
  );
};

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: "5%",
    flexDirection: "column",
    alignItems: "center",
  },
  paper: {
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "30%",
    padding: theme.spacing(3),
    backgroundColor: "#EEF2F6",
  },
  logo: {
    width: "30%",
    aspectRatio: "3/2",
    objectFit: "contain",
    mixBlendMode: "darken",
  },
  button: {
    width: "100%",
    marginTop: 21,
    padding: "0.9375rem !important",
    fontWeight: "600 !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  titleblock: {
    position: "relative",
    borderBottom: "0.0625rem solid #f3f4f6",
    padding: "0.8125rem 0rem 1.75rem 0rem",
  },
  backicon: {
    position: "absolute",
    top: 18,
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  block: {
    padding: "0rem 1.75rem",
    marginBottom: "6.9375rem",
  },
  title: {
    fontWeight: 600,
  },
  loadingText: {
    textAlign: "center",
  },
}));

export const Initialization = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "initialization"]);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const [progress, setProgress] = React.useState(0);
  const [title, setTitleText] = React.useState(null);

  const appliedLoyaltyCard = useSelector(
    (state) => state.Cart.appliedLoyaltyCard
  );
  const loyaltyProgramDetails = useSelector(
    (state) => state.Cart.loyaltyProgramDetails
  );

  const customergroupparent = useSelector(
    (state) => state.Configuration.customergroupparent
  );
  const parentterritory = useSelector(
    (state) => state.Configuration.territoryparent
  );
  const user = useSelector((state) => state.Login.user);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const pricelist = useSelector((state) => state.Configuration.pricelist);
  const itemgroup = useSelector((state) => state.Configuration.itemgroup);
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const stores = useSelector((state) => state.Login.stores);
  const posList = useSelector((state) => state.Login.pos);
  var themes = useSelector((state) => state.Theme.themes);
  // const currentPage = useSelector(state => state.Initialization.currentPage);
  const [currentPage, setCurrentPage] = React.useState({
    discounts: 0,
    clients: 0,
    products: 0,
    orders: 0,
    payementMethods: 0,
    inventory: 0,
    posProducts: 0,
  });

  /* **********RETAIL-530 :Default Terriotry synchronization****************  */
  /* const handleLoadDefaultTerriotryData = async () => {
    setTitleText(<>{t('initialization:loadterrparent')}</>);
    await loadDefaultTerriotryData(globalDefaults?.country).then(response => {
      dispatch(LoadDefaultTerriotry(response?.data[0]?.name));
    });
    // setProgress(24) 
  } */
  /* **********RETAIL-673 : POS Invoice returned synchronization****************  */
  /* const handleLoadinvoicereturned = async (defaultCompany) => {
     setTitleText(<>{t('initialization:loadinvoicereturnedlist')}</>);
     await loadInvoicesReturnedData(defaultCompany).then(response => {
       dispatch(SetInvoicesReturned(response?.data));
       setProgress(85);
     }).catch(err => {
       console.log(err);
     });
   }; * /
 
   /* **********RETAIL-657 : Themes synchronization****************  */
  const handleLoadThemes = async () => {
    setTitleText(<>{t("initialization:loadthemelist")}</>);
    await loadThemesData()
      .then((response) => {
        dispatch(GetThemesDb(response?.data));
        setProgress(82);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /* **********RETAIL-530 : discount synchronization****************  */
  const handleLoadDiscounts = async () => {
    setTitleText(<>{t("initialization:loaddisc")}</>);
    await loadDiscountData(selectedData?.store)
      .then((response) => {
        dispatch(LoadDiscounts(response?.data));
        setProgress(13);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleLoadClients = async () => {
    setTitleText(<>{t("initialization:loadCus")}</>);
    await loadClientsData()
      .then((response) => {
        dispatch(LoadClients(response?.data));
        setProgress(10);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const handleLoadItemFinancier = async () => {
  //   setTitleText(<>{t("initialization:loadItemFinancier")}</>);
  //   try {
  //     const response = await dispatch(fetchItemFinancierList());
  //     setProgress(16);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const handleLoadItemFinancier = async (defaultCompany) => {
    setTitleText(<>{t("initialization:loadItemFinancier")}</>);
    await fetchItemFinancierList(defaultCompany)
      .then((response) => {
        dispatch(LoadItemFinancierList(response?.data));
        setProgress(16);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleLoadProducts = async (defaultCompany) => {
    setTitleText(<>{t("initialization:loadProd")}</>);
    await loadProductsData(defaultCompany)
      .then((response) => {
        dispatch(LoadProducts(response?.data));
        setProgress(19);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadProductsBundle = async () => {
    setTitleText(<>{t("initialization:loadProdBundle")}</>);
    await loadProductsBundleData()
      .then((response) => {
        dispatch(LoadProductsBundle(response.data));
        setProgress(46);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadItemCollection = async () => {
    setTitleText(<>{t("initialization:loadProdColl")}</>);
    await loadItemCollectionData()
      .then((response) => {
        dispatch(LoadItemCollection(response.data));
        setProgress(76);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadItemTax = async (defaultCompany) => {
    setTitleText(<>{t("initialization:loadProdColl")}</>);
    await loadTaxData(defaultCompany)
      .then((response) => {
        dispatch(LoadItemTax(response.data));
        setProgress(79);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadAllItemCategories = async () => {
    setTitleText(<>{t("initialization:loadProdColl")}</>);
    await loadAllCategories()
      .then((response) => {
        // dispatch(LoadAllItemCategories(response.data));
        dispatch(LoadItemCategories(response?.data));
        setProgress(37);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadPosProducts = async (page, pricelist, itemgroup) => {
    setTitleText(<>{t("initialization:loadPOSProd")}</>);
    
    try {
      let lastReturn = {};
      try {
        const savedReturn = localStorage.getItem('lastReturn');
        if (savedReturn) {
          lastReturn = JSON.parse(savedReturn);
          //console.log('lastreturn:', lastReturn);
        }
      } catch (err) {
        console.error('Error loading last return:', err);
      }
  
      page = page && page.currentPage ? page.currentPage.posProducts : 0;
      const response = await loadProductsByPos(page, pricelist, itemgroup);
      
      if (response?.data?.message?.items) {
        const updatedItems = response.data.message.items.map(item => {
          const returnInfo = lastReturn[item.item_code];
          if (returnInfo) {
            //console.log(`return adjustment is to ${item.item_code}:`, {
              //before: item.actual_qty,
              //returnQty: returnInfo.qty,
              //after: item.actual_qty + returnInfo.qty
            //});
            return {
              ...item,
              actual_qty: item.actual_qty + returnInfo.qty
            };
          }
          return item;
        });

        dispatch(LoadPosProducts(updatedItems));
        setProgress(8);
      }
    } catch (err) {
      console.error('Error in handleLoadPosProducts:', err);
    }
  };

  const handleLoadOrders = async (defaultCompany) => {
    setTitleText(<>{t("initialization:loadOrders")}</>);
    await loadOrdersData(defaultCompany)
      .then((response) => {
        dispatch(LoadOrders(response?.data));
        setProgress(16);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadPaymentMethods = async (defaultCompany) => {
    setTitleText(<>{t("initialization:loadPaymnts")}</>);
    await loadPayementMethodsData(defaultCompany)
      .then((response) => {
        dispatch(LoadPayments(response?.data));
        setProgress(40);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadInventory = async (defaultCompany) => {
    setTitleText(<>{t("initialization:loadInv")}</>);
    await loadInventoryData(defaultCompany)
      .then((response) => {
        const inventoryData = response?.data;
        dispatch(LoadInventory(inventoryData));
        setProgress(43);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadStockReportData = async (defaultCompany) => {
    await loadStockReportData(defaultCompany)
      .then((response) => {
        dispatch(SetStockReportData(response?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadDefaultTax = async (defaultCompany) => {
    setTitleText(<>{t("initialization:loadTax")}</>);
    await loadDefaultTax(defaultCompany)
      .then((response) => {
        if (response.data && response.data[0] && response.data[0]["name"]) {
          dispatch(LoadDefaultTaxValue(response.data[0]["name"]));
        }
        setProgress(58);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadCountry = async () => {
    setTitleText(<>{t("initialization:loadCountries")}</>);
    await loadCountryData()
      .then((response) => {
        dispatch(LoadCountry(response.data));
        setProgress(49);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadTerritory = async () => {
    setTitleText(<>{t("initialization:loadTerr")}</>);
    await loadTerritoryData(parentterritory)
      .then((response) => {
        dispatch(LoadTerritoy(response.data.message));
        setProgress(52);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadAddress = async () => {
    setTitleText(<>{t("initialization:loadAddr")}</>);
    await loadAddressData(globalDefaults?.country)
      .then((response) => {
        dispatch(LoadAddress(response.data.message));
        setProgress(70);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadNotification = async () => {
    setTitleText(<>{t("initialization:loadNotif")}</>);
    await loadNotificationsData()
      .then((response) => {
        dispatch(LoadNotifications(response.data));
        setProgress(55);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadLoyaltyPrograms = async () => {
    setTitleText(<>{t("initialization:loadLoyal")}</>);
    await loadLoyaltyProgramsData()
      .then((response) => {
        dispatch(LoadLoyaltyPrograms(response.data));
        dispatch(GetLoyaltyProgramList(response.data));
        setProgress(61);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadSalesPersonData = async () => {
    setTitleText(<>{t("initialization:loadSellers")}</>);
    await loadSalesPersonData()
      .then((response) => {
        dispatch(LoadSalesPerson(response.data));
        setProgress(67);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadCustomersGroupData = async () => {
    setTitleText(<>{t("initialization:loadCusGrp")}</>);
    await loadCustomersGroupData(customergroupparent)
      .then((response) => {
        dispatch(LoadCustomersGroup(response.data.message));
        setProgress(73);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadCurrenciesData = async () => {
    setTitleText(<>{t("initialization:loadCurr")}</>);
    await loadCurrenciesData()
      .then((response) => {
        dispatch(LoadCurrencies(response.data));
        setProgress(64);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadRestOfData = async (defaultCompany) => {
    setTitleText(<>{t("initialization:prep")}</>);
    selectedData?.store &&
      dispatch(LoadFullfilments(selectedData.store, defaultCompany));
    selectedData?.store &&
      dispatch(loadHolds(defaultCompany, selectedData?.store));
    setProgress(100);
    setTimeout(() => {
      setTitleText(<>{t("initialization:ready")}</>);
    }, 500);
  };

  const handleLoadCouponCodesData = async () => {
    setTitleText(<>{t("initialization:loadCoupCode")}</>);
    await loadCouponCodes()
      .then((response) => {
        dispatch(LoadCouponCode(response.data));
      })
      .catch((err) => {
        console.log(err);
      });
    setProgress(99);
  };
  const handleLoadBuyingSettingsData = async () => {
    await loadBuyingSettingsData()
      .then(async (response) => {
        dispatch(
          LoadBuyingPriceList(response?.data?.docs[0]?.buying_price_list)
        );
        setProgress(25);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadSellingSettingsData = async (page) => {
    await loadSellingSettingsData()
      .then(async (response) => {
        dispatch(LoadPriceList(response?.data?.docs[0]?.selling_price_list));
        dispatch(LoadDefaultTerriotry(response?.data?.docs[0]?.territory));
        dispatch(
          LoadDefaultCustomerGroup(response?.data?.docs[0]?.customer_group)
        );
        await loadStockSettingsData().then(async (res) => {
          dispatch(LoadDefaultWarehouse(res?.data.docs[0]?.default_warehouse));
          dispatch(Loadcompanyitemgroup(res?.data.docs[0]?.item_group));
          await handleLoadPosProducts(
            page,
            response?.data?.docs[0]?.selling_price_list,
            res?.data?.docs[0]?.item_group
          );
        });
        setProgress(20);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadWarehouseParentData = async (defaultCompany) => {
    await loadWarehouseParentData(defaultCompany)
      .then((response) => {
        dispatch(LoadWarehouseParent(response?.data[0]?.name));
        setProgress(22);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadTerritoryParentData = async () => {
    await loadTerritoryParentData()
      .then((response) => {
        dispatch(LoadTerritoryParent(response?.data[0].name));
        setProgress(27);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const LoadItemsImagesData = async () => {
    await LoadItemsImages()
      .then((response) => {
        dispatch(SetItemImages(response?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadCustomerGroupParentData = async () => {
    await loadCustomerGroupParentData()
      .then((response) => {
        dispatch(LoadCustomerGroupParent(response?.data[0].name));
        setProgress(31);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadReturnReasonsData = async () => {
    await loadReturnReasons()
      .then((response) => {
        dispatch(LoadLoadReturnReasons(response?.data));
        setProgress(33);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadInventoryReasonsData = async () => {
    await loadMotifInventory()
      .then((response) => {
        dispatch(LoadMotifInventory(response?.data));
        setProgress(37);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadCompanyAccount = async (defaultCompany) => {
    await loadcompanyaccountData(defaultCompany)
      .then((response) => {
        const acccountcomp = {
          cost_center: response?.data?.docs?.[0]?.cost_center,
          default_bank_account: response?.data?.docs?.[0]?.default_bank_account,
          default_cash_account: response?.data?.docs?.[0]?.default_cash_account,
          default_currency: response?.data?.docs?.[0]?.default_currency,
          default_employee_advance_account:
            response?.data?.docs?.[0]?.default_employee_advance_account,
          default_expense_account:
            response?.data?.docs?.[0]?.default_expense_account,
          default_income_account:
            response?.data?.docs?.[0]?.default_income_account,
          default_payroll_payable_account:
            response?.data?.docs?.[0]?.default_payroll_payable_account,
          default_receivable_account:
            response?.data?.docs?.[0]?.default_receivable_account,
          disposal_account: response?.data?.docs?.[0]?.disposal_account,
          exchange_gain_loss_account:
            response?.data?.docs?.[0]?.exchange_gain_loss_account,
          round_off_account: response?.data?.docs?.[0]?.round_off_account,
          round_off_cost_center:
            response?.data?.docs?.[0]?.round_off_cost_center,
          write_off_account: response?.data?.docs?.[0]?.write_off_account,
          default_payable_account:
            response?.data?.docs?.[0]?.default_payable_account,
          default_inventory_account:
            response?.data?.docs?.[0]?.default_inventory_account,
          stock_adjustment_account:
            response?.data?.docs?.[0]?.stock_adjustment_account,
          stock_received_but_not_billed:
            response?.data?.docs?.[0]?.stock_received_but_not_billed,
          expenses_included_in_valuation:
            response?.data?.docs?.[0]?.expenses_included_in_valuation,
          accumulated_depreciation_account:
            response?.data?.docs?.[0]?.accumulated_depreciation_account,
          depreciation_expense_account:
            response?.data?.docs?.[0]?.depreciation_expense_account,
          expenses_included_in_asset_valuation:
            response?.data?.docs?.[0]?.expenses_included_in_asset_valuation,
          depreciation_cost_center:
            response?.data?.docs?.[0]?.depreciation_cost_center,
          capital_work_in_progress_account:
            response?.data?.docs?.[0]?.capital_work_in_progress_account,
          asset_received_but_not_billed:
            response?.data?.docs?.[0]?.asset_received_but_not_billed,
        };
        const infcop = {
          company_name: response?.data?.docs?.[0]?.company_name,
          abbr: response?.data?.docs?.[0]?.abbr,
          default_currency: response?.data?.docs?.[0]?.default_currency,
          country: response?.data?.docs?.[0]?.country,
          phone_no: response?.data?.docs?.[0]?.phone_no,
          email: response?.data?.docs?.[0]?.email,
          website: response?.data?.docs?.[0]?.website,
          company_description: response?.data?.docs?.[0]?.company_description,
          company_logo: response?.data?.docs?.[0]?.company_logo,
          tax_id: response?.data?.docs?.[0]?.tax_id,
        };

        dispatch(LoadCompanyAccount(acccountcomp));
        dispatch(LoadCompanyInformation(infcop));
        setProgress(34);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ChangeThemeValid = () => {
    const lastThemeIndex = themes.findIndex((obj) => obj.active === 1);

    const store = Array.isArray(stores)
      ? stores.filter((f) => f.name == selectedData.store)
      : null;

    const id_theme_warehouse =
      store?.map((m) => m.theme_id) === ""
        ? 0
        : store.map((m) => m.theme_id).toString();

    const posProfile = posList.filter((f) => f.name == selectedData.cashier);

    const id_theme_pos =
      posProfile.map((m) => m.theme_id) === ""
        ? 0
        : posProfile.map((m) => m.theme_id).toString();

    let theme_pos = themes.find((s) => s.theme_id == id_theme_pos);
    let theme_warehouse = themes.find((s) => s.theme_id == id_theme_warehouse);

    if (id_theme_warehouse && id_theme_pos) {
      themes[lastThemeIndex].active = 0;
      theme_pos.active = 1;
      themes[id_theme_pos] = theme_pos;
      dispatch(UpdateThemes(themes));
    } else if (id_theme_warehouse) {
      themes[lastThemeIndex].active = 0;
      theme_warehouse.active = 1;
      themes[id_theme_warehouse] = theme_warehouse;
      dispatch(UpdateThemes(themes));
    }
  };

  const handleStartSession = () => {
    ChangeThemeValid();
    dispatch(CancelCheckout());
    appliedLoyaltyCard && dispatch(UpdateAppliedLoyCard(null));
    loyaltyProgramDetails && dispatch(SetLoyaltyProgramDetails(null));
    user && user.role == "Cashier"
      ? history.push("/checkout")
      : history.push("/stock");
  };

  useEffect(() => {
    return Promise.all([
      localforage.getItem("reduxPersist:Checkout"),
      localforage.getItem("reduxPersist:Client"),
      localforage.getItem("reduxPersist:Inventory"),
      localforage.getItem("reduxPersist:Product"),
      localforage.getItem("reduxPersist:Initialization"),
      localforage.getItem("reduxPersist:Login"),
    ])
      .then(async function (results) {
        const orders = results[0] ? JSON.parse(results[0]).orders : [];
        const payementsMethods = results[0]
          ? JSON.parse(results[0]).payementsMethods
          : [];
        const clients = results[1] ? JSON.parse(results[1]).clients : [];
        const inventory = results[2] ? JSON.parse(results[2]).inventory : [];
        const products = results[3] ? JSON.parse(results[3]).products : [];
        const Posproducts = results[3]
          ? JSON.parse(results[3]).productsPos
          : [];
        const page = JSON.parse(results[4]);
        const defaultCompany = results[5]
          ? JSON.parse(results[5])?.globalDefaults?.default_company
          : globalDefaults?.default_company;

        LoadTotalItems().then(async (res) => {
          await handleLoadItemFinancier();
          await handleLoadClients();
          await handleLoadDiscounts();
          await handleLoadOrders(defaultCompany);
          await handleLoadProducts(defaultCompany);
          await LoadItemsImagesData();
          await handleLoadWarehouseParentData(defaultCompany);
          await handleLoadSellingSettingsData(page);
          await handleLoadBuyingSettingsData();
          await handleLoadTerritoryParentData();
          await handleLoadCustomerGroupParentData();
          await handleLoadReturnReasonsData();
          await handleLoadInventoryReasonsData();
          await handleLoadCompanyAccount(defaultCompany);
          await handleLoadAllItemCategories();
          await handleLoadPaymentMethods(defaultCompany);
          await handleLoadInventory(defaultCompany);
          await handleLoadStockReportData(defaultCompany);
          await handleLoadProductsBundle();
          await handleLoadCountry();
          await handleLoadTerritory();
          await handleLoadNotification();
          await handleLoadDefaultTax(defaultCompany);
          await handleLoadLoyaltyPrograms();
          await handleLoadCurrenciesData();
          await handleLoadSalesPersonData();
          await handleLoadAddress();
          await handleLoadCustomersGroupData();
          await handleLoadItemCollection();
          await handleLoadItemTax(defaultCompany);
          await handleLoadThemes();
          await dispatch(GetStores(defaultCompany, user));
          await dispatch(GetPOSList());

          //await handleLoadinvoicereturned(defaultCompany);
          // await handleLoadCouponCodesData();
          await handleLoadRestOfData(defaultCompany);
        });
      })
      .catch((error) => console.log(`Error in Promises ${error}`));
  }, []);
  return (
    <Box className={classes.root}>
      <Box className={classes.paper}>
        <img src={activeImageTheme} alt={"logo"} className={classes.logo} />
        <Box mt={2} width={"100%"}>
          <Box className={classes.titleblock}>
            <Typography
              align={"center"}
              className={classes.title}
              color={"primary"}
              variant="h5"
            >
              {t("initialization:init")}
            </Typography>
          </Box>
          <LinearProgressWithLabel value={progress} />
          <Typography className={classes.loadingText}>
            {title ? title : t("initialization:loadEnv")}
          </Typography>
        </Box>
        <Box mt={2} width={"100%"}>
          <Button
            onClick={handleStartSession}
            disabled={progress !== 100}
            className={classes.button}
            size={"large"}
            variant="contained"
            color="primary"
          >
            {t("initialization:start")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
