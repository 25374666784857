import React from "react";
import {
  Button,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useTableStyles } from "../../styles/table.style";
import { Empty } from "../empty";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetErrorMessage } from "../../store/rollback/rollback.action";
import { ignorePricingRules, UpdatePayment } from "../../store/checkout/checkout.action";
import { useEffect, useState } from "react";
import { calculatePrices } from "../../helpers/utils";

const PricingRules = (props) => {
  const { openPricingRules, setOpenPricingRules } = props;
  const tableClasses = useTableStyles();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "checkout", "payement"]);

  const [showDetails, setShowDetails] = React.useState(false);
  const itemDetails = useSelector((state) => state.Checkout.itemDetails);
  const errorMessage = useSelector((state) => state.Rollback.errorMessage);
  const [pricingRulesList, setPricingRulesList] = useState([]);
  const products = useSelector((state) => state.Checkout.products);
  const amount = useSelector((state) => state.Checkout.amount);
  const discount = useSelector((state) => state.Checkout.discount);
  const taxsales = useSelector((state) => state.Checkout.taxsales);
  const itemPrestationList = useSelector((state) => state.Checkout.itemPrestationList);


  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user")).token,
  };

  const getPricingRulesList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        doctype: "Pricing Rule",
        fields: ['`tabPricing Rule`.`name`'],
        filters: [
          ['Pricing Rule', 'pricing_rule_check', '=', 1]
        ],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setPricingRulesList(data);
        }
      });
  };

  useEffect(() => {
    getPricingRulesList();
  }, []);

  const isPricingRuleValid = (pricingRule) => {
    return !pricingRulesList.some(rule => rule.name === pricingRule);
  };

  const itemsWithValidPricingRules = itemDetails?.filter((item) => {
    if (!Object.keys(item).includes("pricing_rules")) return false;
    const itemPricingRules = JSON.parse(item.pricing_rules);
    return itemPricingRules.some(rule => isPricingRuleValid(rule));
  });

  const ignorePricingRule = useSelector(
    (state) => state.Checkout.ignorePricingRules
  );

  const globalDefaults = useSelector((state) => state.Login.globalDefaults);

  const showPricingRules = () => {
    dispatch(ignorePricingRules(!ignorePricingRule));
  };

  const subTotalPerRow = (item, discount) => {
    const subtotal = parseFloat(
      item?.price_list_rate ? item?.price_list_rate : item?.standard_rate
    );

    const discountType =
      item?.discount_percentage !== 0 ? "percentage" : "amount";

    const discountedAmount =
      discountType === "percentage" ? subtotal * (discount / 100) : discount;

    const total = ((subtotal - discountedAmount) * parseInt(item?.qty)).toFixed(
      3
    );

    return `${total} ${globalDefaults?.default_currency}`;
  };

  const handleResetError = () => {
    dispatch(resetErrorMessage());
  };

  const handleReturn = () => {
    handleResetError();
    setOpenPricingRules(false);
  };

  const taxsalesvalue = taxsales && !isNaN(taxsales?.value) 
  ? parseFloat(taxsales?.value) 
  : 0;

  const handlePricingRuleChange = () => {
    const newIgnorePricingRule = !ignorePricingRule;
    dispatch(ignorePricingRules(newIgnorePricingRule));
  
    const newPrices = calculatePrices({
      products,
      itemDetails,
      ignorePricingRule: newIgnorePricingRule,
      discount,
      taxsalesvalue,
      itemPrestationList
    });
      
    dispatch(UpdatePayment({
      ...amount,
      totalAmount: newPrices.total,
      taxAmount: newPrices.taxTotal,
      baseAmount: newPrices.subtotal,
      discountAmount: newPrices.discountAmount
    }));
  };

  return (
    <>
      <Dialog
        open={openPricingRules}
        onClose={() => setOpenPricingRules(false)}
        maxWidth="md"
        fullWidth
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle align={"center"}>
          <Typography
            className={classes.title}
            align={"center"}
            color={"primary"}
            fontWeight={"bold"}
          >
            {t("checkout:choiceCondition")}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <Typography align="left" variant="caption" color="primary">
              {itemsWithValidPricingRules?.length} {t("checkout:salesConditionApp")}
            </Typography>
          </DialogContentText>
          {errorMessage && (
            <DialogContentText>
              <Alert severity="error">{errorMessage}</Alert>
            </DialogContentText>
          )}
          {itemsWithValidPricingRules && itemsWithValidPricingRules.length === 0 && (
            <Empty text={t("checkout:noCondition")} />
          )}
          {itemsWithValidPricingRules?.length !== 0 && (
            <DialogContentText id="scroll-dialog-description">
              <Box className={tableClasses.paper} mt={2}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow className={classes.tableRow}>
                      <TableCell className={tableClasses.tablecellHeader}>
                        {t("checkout:appliedCondition")}
                      </TableCell>
                      <TableCell className={tableClasses.tablecellHeader}>
                        {t("checkout:PriceOrProductDiscount")}
                      </TableCell>
                      <TableCell className={tableClasses.tablecellHeader}>
                        {t("checkout:ApplConditionFor")}
                      </TableCell>
                      <TableCell className={tableClasses.tablecellHeader}>
                        {t("checkout:benefits")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {itemsWithValidPricingRules.map((row) => (
                      <TableRow className={classes.tableRow} key={row.item_code}>
                        <TableCell className={tableClasses.tableCell}>
                          {JSON.parse(row.pricing_rules).filter(rule => isPricingRuleValid(rule)).join(", ")}
                        </TableCell>
                        <TableCell className={tableClasses.tableCell}>
                          {row.price_or_product_discount}
                        </TableCell>
                        <TableCell className={tableClasses.tableCell}>
                        {JSON.parse(row.pricing_rules).filter(rule => isPricingRuleValid(rule)).join(", ")}
                        </TableCell>
                        <TableCell className={tableClasses.tableCell}>
                          {row.discount_percentage != 0
                            ? `${row.discount_percentage}% `
                            : row.discount_amount != 0
                            ? row.discount_amount.toFixed(3) +
                              " " +
                              globalDefaults?.default_currency
                            : "Free Item"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </DialogContentText>
          )}

          {itemsWithValidPricingRules?.length !== 0 && (
            <>
              <Box
                mt={3}
                justifyContent="center"
                display="flex"
                flexDirection="column"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ignorePricingRule}
                        onChange={handlePricingRuleChange}
                        color="primary"
                      />
                    }
                    label={t("checkout:ignorePricingRule")}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showDetails}
                        onChange={() => setShowDetails(!showDetails)}
                        color="primary"
                      />
                    }
                    label={t("checkout:detailCondition")}
                  />
                </FormGroup>
              </Box>
              {showDetails && (
                <Box className={tableClasses.paper}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow className={classes.tableRow}>
                        <TableCell className={tableClasses.tablecellHeader}>
                          {t("common:ref")}
                        </TableCell>
                        <TableCell className={tableClasses.tablecellHeader}>
                          {t("produit")}
                        </TableCell>
                        <TableCell className={tableClasses.tablecellHeader}>
                          {t("qty")}
                        </TableCell>
                        <TableCell className={tableClasses.tablecellHeader}>
                          {t("prixHT")}
                        </TableCell>
                        <TableCell className={tableClasses.tablecellHeader}>
                          {t("checkout:discountValue")}
                        </TableCell>
                        <TableCell className={tableClasses.tablecellHeader}>
                          {t("checkout:PricingRule")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                      {itemsWithValidPricingRules?.flatMap((row) => [
                        <TableRow
                          key={row.item_code}
                          className={classes.tableRow}
                        >
                          <TableCell className={tableClasses.tableCell}>
                            {row.item_code}
                          </TableCell>
                          <TableCell className={tableClasses.tableCell}>
                            {row.item_name}
                          </TableCell>
                          <TableCell className={tableClasses.tableCell}>
                            {row.qty}
                          </TableCell>
                          <TableCell className={tableClasses.tableCell}>
                            {(
                              parseInt(row?.qty) *
                              parseFloat(row?.price_list_rate)
                            ).toFixed(3)}{" "}
                            {globalDefaults?.default_currency}
                          </TableCell>
                          <TableCell className={tableClasses.tableCell}>
                            {subTotalPerRow(
                              row,
                              row?.discount_amount != 0
                                ? row?.discount_amount
                                : row?.discount_percentage
                            )}
                          </TableCell>
                          <TableCell className={tableClasses.tableCell}>
                          {JSON.parse(row.pricing_rules).filter(rule => isPricingRuleValid(rule)).join(", ")}
                          </TableCell>
                        </TableRow>,
                        ...(row.free_item_data &&
                        row.free_item_data.length > 0
                          ? row.free_item_data.map((freeItem) => (
                              <TableRow
                                className={classes.highlightedRow}
                                key={`${row.item_code}-${freeItem.name}`}
                              >
                                <TableCell className={classes.tableCell}>
                                  {freeItem.item_code}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {freeItem.item_name}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {freeItem.qty}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {freeItem.price_list_rate.toFixed(3)}
                                  {globalDefaults.default_currency}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  -
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                {JSON.parse(row.pricing_rules).filter(rule => isPricingRuleValid(rule)).join(", ")}
                                </TableCell>
                              </TableRow>
                            ))
                          : []),
                      ])}
                    </TableBody>
                  </Table>
                </Box>
              )}
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={handleReturn}>
            {!props.isPurchaseOrder ? t("common:annuler") : t("common:fermer")}
          </Button>
          {!props.isPurchaseOrder && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push("/checkout/payement/1")}
            >
              {t("common:confirmer")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PricingRules;
