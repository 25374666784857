import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { ProductsToCheckout } from "../productsToCheckout";
import {
  LoadCouponCodeById,
  SetCouponCode,
  SetHoldDataInCheckout,
  ignorePricingRules,
  loadItemDetail,
} from "../../../../store/checkout/checkout.action";
import {
  LoadHoldById,
  deleteHoldById,
  reloadHold,
} from "../../../../store/hold/hold.action";
import { Chip, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { formatDate, symbol } from "../../../../helpers/utils";
import { Loader } from "../../../../components/loader";

export const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.secondary.main,
    borderRadius: 10,
    border: "none",
    minHeight: 300,
  },
  cardBoldText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 14,
  },
  cardTitle: {
    fontSize: 16,
    textAlign: "center",
  },
  statusChip: {
    color: "white",
    marginLeft: 5,
  },
  statusCancelled: {
    backgroundColor: "#dc3545",
  },
  statusPartiallyPaid: {
    backgroundColor: "#ff9800",
  },
  statusPaid: {
    backgroundColor: "#4caf50",
  },
  cardTitleBlock: {
    borderBottom: "1px solid #e7eaef",
    padding: "10px 20px 17px",
  },
  cardContentBlock: {
    padding: "1.4375rem",
    marginBottom: "-30px",
    display: "flex",
    flexWrap: "wrap",
  },
  titleblock: {
    position: "relative",
    borderBottom: "0.0625rem solid #f3f4f6",
    padding: "0.8125rem 0rem 1.75rem 0rem",
  },
  closeicon: {
    position: "absolute",
    top: 18,
    color: theme.palette.primary.main,
    width: 23,
    height: 23,
    cursor: "pointer",
  },
  block: {
    padding: "0rem 1.75rem",
    marginBottom: "6.9375rem",
  },
  title: {
    fontWeight: 600,
  },
  cardLightText: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  root: {
    marginTop: 27,
  },
  paper: {
    overflow: "auto",
    padding: "0px 10px",
  },
  restToPayContainer: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  amountContainer: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  // Media Query for All Screens
  "@media screen and (max-width: 600px)": {
    cardTitle: {
      fontSize: 16,
    },
    cardLightText: {
      fontSize: 14,
    },
    cardBoldText: {
      fontSize: 14,
    },
    cardContentBlock: {
      marginBottom: theme.spacing(-1),
    },
  },
  "@media screen and (min-width: 601px) and (max-width: 1024px)": {
    cardTitle: {
      fontSize: 16,
    },
    cardLightText: {
      fontSize: 14,
    },
    cardBoldText: {
      fontSize: 14,
    },
  },
}));
export const HoldDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation(["common", "hold"]);
  const dispatch = useDispatch();
  const holds = useSelector((state) => state.Checkout.holds);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const currencies = useSelector((state) => state.Client.currencies);
  const user = useSelector((state) => state.Login.user);
  const temporaryholdOrders = useSelector(
    (state) => state.Checkout.temporaryHolds
  );
  const store = useSelector((state) => state.Login.selectedData?.store);

  const currencySymbol =
    globalDefaults &&
    symbol(globalDefaults?.default_currency, currencies)?.symbol;

  const [holdOrder, setHoldOrder] = useState(null);
  const [fromApi, setFromApi] = useState(false);
  const getHoldFromApi = (index) => {
    fetch(`${process.env.REACT_APP_API_URI}/api/holds/details`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setHoldOrder(data);
        setFromApi(true);
      });
  };

  useEffect(() => {
    if (!holdOrder && holds && user) {
      const index = id?.toLowerCase()?.indexOf("-".toLowerCase());
      if (index < 0) {
        const selectedOrder = holds.find((h) => h.id + "" === id);
        if (selectedOrder) {
          setHoldOrder(selectedOrder);
        } else {
          getHoldFromApi(index);
        }
      } else {
        const selectedOrder = temporaryholdOrders.find(
          (h) => h.index + "" === id.substr(index + 1)
        );
        setHoldOrder(selectedOrder);
      }
    }
  }, [holds, temporaryholdOrders]);

  const loadItemDetails = (items) => {
    items?.forEach((item) => {
      let doc = {
        doctype: "POS Invoice",
        company: globalDefaults.default_company,
        posting_date: formatDate(new Date(), "YYYY-MM-DD"),
        currency: globalDefaults.default_currency,
        price_list_currency: globalDefaults.default_currency,
        ignore_pricing_rule: 0,
        party_account_currency: globalDefaults.default_currency,
        items: [
          {
            doctype: "POS Invoice Item",
            item_group: item.item_group,
            parentfield: "items",
            parenttype: "POS Invoice",
            qty: item.qty,
            item_code: item.item_code,
          },
        ],
        pos_profile: holdOrder.pos_profile,
        set_warehouse: store,
        customer_name: holdOrder.client,
        customer: holdOrder.client,
      };
      let args = {
        item_code: item.item_code,
        set_warehouse: store,
        customer: holdOrder.client,
        currency: globalDefaults.default_currency,
        price_list_currency: globalDefaults.default_currency,
        company: globalDefaults.default_company,
        ignore_pricing_rule: 0,
        doctype: "POS Invoice",
        pos_profile: holdOrder.pos_profile,
      };
      dispatch(loadItemDetail(args, doc));
    });
    dispatch(
      ignorePricingRules(holdOrder?.ignore_pricing_rule === 1 ? true : false)
    );
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "annulé":
      case "cancel":
        return classes.statusCancelled;
      case "en attente":
      case "partly paid":
        return classes.statusPartiallyPaid;
      case "payé":
      case "paid":
        return classes.statusPaid;
      default:
        return classes.statusCancelled;
    }
  };

  const handleFinaliserReserved = async (data) => {
    await dispatch(LoadHoldById(data?.id));
    await loadItemDetails(data?.products);
    const newData = {
      ...data,
      amount: {
        ...data.amount,
        depositAmount: 0, // Set to null or any other desired value
      },
    };
    await dispatch(SetHoldDataInCheckout(newData));

    (data.amount && data.amount.depositAmount != 0) ||
    (data.amount.payments &&
      data.amount.payments.length > 0 &&
      data?.ignore_pricing_rule != 0)
      ? history.push("/checkout/payement/1")
      : history.push("/checkout");
  };

  const HandleSetToCheckout = async (data) => {
    await loadItemDetails(data?.products);
    data?.coupon
      ? dispatch(LoadCouponCodeById(data.coupon))
      : dispatch(SetCouponCode({}));
    await dispatch(SetHoldDataInCheckout(data));
    data.amount && data.amount.payments && data.amount.payments.length > 0
      ? history.push("/checkout/payement/1")
      : history.push("/checkout");
    if (data?.id) {
      dispatch(deleteHoldById(data?.id));
    } else {
      dispatch(
        reloadHold(
          temporaryholdOrders?.filter((el) => el.index !== data?.index)
        )
      );
    }
  };
  if (!holdOrder) return <Loader />;
  return (
    <Box className={classes.block}>
      <Box className={classes.titleblock}>
        <span
          onClick={() => history.goBack()}
          className={clsx(classes.closeicon, "icon-close")}
        />
        <Typography
          align={"center"}
          className={classes.title}
          color={"primary"}
          variant="h5"
        >
          {holdOrder?.amount?.payments?.length > 0
            ? t("common:orderReserved")
            : t("hold:pendingOrdr")}
          {holdOrder?.amount?.payments.length > 0 && (
            <Chip
              className={clsx(
                classes.statusChip,
                getStatusColor(holdOrder?.payment_status)
              )}
              label={
                <>
                  <span>
                    {holdOrder?.payment_status?.toLowerCase() === "cancel" &&
                      "ANNULÉ"}
                    {holdOrder?.payment_status?.toLowerCase() ===
                      "partly paid" && "EN ATTENTE"}
                    {holdOrder?.payment_status?.toLowerCase() === "paid" &&
                      "PAYÉ"}
                  </span>
                </>
              }
            />
          )}
        </Typography>
      </Box>

      <Box display="flex" className={classes.root}>
        <Grid item xs={12} md={3}>
          {holdOrder && holdOrder?.amount && (
            <Card
              className={clsx(classes.card, classes.cardBoldText)}
              variant="outlined"
            >
              <CardContent>
                <Box className={classes.cardTitleBlock}>
                  <Typography
                    className={clsx(classes.cardTitle, classes.cardBoldText)}
                  >
                    {t("common:pymnt")}
                  </Typography>
                  {holdOrder?.ignore_pricing_rule != null && (
                    <Typography
                      className={classes.cardBoldText}
                      style={{ fontSize: 10, textAlign: "center" }}
                    >
                      {holdOrder?.ignore_pricing_rule == 0
                        ? t("common:appliedCondition")
                        : t("common:noConditionApplied")}
                    </Typography>
                  )}
                </Box>
                <Box
                  className={classes.cardContentBlock}
                  display="flex"
                  flexWrap="wrap"
                >
                  <Box mr={2}>
                    <Typography
                      className={classes.cardLightText}
                      variant="caption"
                      display="block"
                    >
                      Date :
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      className={classes.cardBoldText}
                      variant="caption"
                      display="block"
                    >
                      {formatDate(holdOrder?.created_at, "YYYY-MM-DD")}
                    </Typography>
                  </Box>
                </Box>
                {holdOrder?.amount?.payments?.map((row, index) => (
                  <Box
                    className={classes.cardContentBlock}
                    display="flex"
                    flexWrap="wrap"
                    key={index}
                  >
                    <Box mr={2}>
                      <Typography
                        className={classes.cardLightText}
                        variant="caption"
                        display="block"
                      >
                        {t("common:modePayment")}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {row.name}
                      </Typography>
                    </Box>
                    <Box className={classes.amountContainer}>
                    <Box mr={2}>
                      <Typography
                        className={classes.cardLightText}
                        variant="caption"
                        display="block"
                      >
                        {t("common:amnt")}:
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {row.amount.toFixed(3)} {currencySymbol}
                      </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
                <Box
                  className={classes.cardContentBlock}
                  display="flex"
                  flexWrap="wrap"
                >
                  <Box mr={2}>
                    <Typography
                      className={classes.cardLightText}
                      variant="caption"
                      display="block"
                    >
                      {t("common:total")}:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      className={classes.cardBoldText}
                      variant="caption"
                      display="block"
                    >
                      {holdOrder?.discount
                        ? (
                            holdOrder.amount.totalAmount -
                            (holdOrder.amount.totalAmount *
                              holdOrder?.discount) /
                              100
                          ).toFixed(3)
                        : holdOrder.amount.totalAmount.toFixed(3)}{" "}
                      {currencySymbol}
                    </Typography>
                  </Box>
                </Box>
                {holdOrder?.amount?.payments?.length > 0 && (
                  <Box
                    className={classes.cardContentBlock}
                    display="flex"
                    flexWrap="wrap"
                  >
                    <Box mr={2}>
                      <Typography
                        className={classes.cardLightText}
                        variant="caption"
                        display="block"
                      >
                        {t("common:pay_by_customer")}:
                      </Typography>
                    </Box>

                    <Box mr={2}>
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {holdOrder?.client}
                      </Typography>
                    </Box>

                    <Box mr={2}>
                      <Typography
                        className={classes.cardLightText}
                        variant="caption"
                        display="block"
                      >
                        {t("hold:depositAmnt")}:
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {holdOrder?.amount?.payments
                          .map((item) => item.amount)
                          .reduce((prev, curr) => prev + curr, 0)
                          .toFixed(3)}{" "}
                        {currencySymbol}
                      </Typography>
                    </Box>
                    <Box className={classes.restToPayContainer}>
                    <Box mr={2}>
                      <Typography
                        className={classes.cardLightText}
                        variant="caption"
                        display="block"
                      >
                        {t("common:restToPay")}:
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {holdOrder?.payment_status === "partly paid"
                          ? (
                              holdOrder?.amount?.totalAmount -
                              holdOrder?.amount?.payments
                                .map((item) => item.amount)
                                .reduce((prev, curr) => prev + curr, 0)
                            ).toFixed(3)
                          : 0.0}{" "}
                        {currencySymbol}
                      </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
                {holdOrder?.coupon && (
                  <Box
                    className={classes.cardContentBlock}
                    display="flex"
                    flexWrap="wrap"
                  >
                    <Box mr={2}>
                      <Typography
                        className={classes.cardLightText}
                        variant="caption"
                        display="block"
                      >
                        {t("checkout:couponCode")}:
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {holdOrder.coupon ? holdOrder.coupon : "-"}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {holdOrder?.discount != 0 && (
                  <Box
                    className={classes.cardContentBlock}
                    display="flex"
                    flexWrap="wrap"
                  >
                    <Box mr={2}>
                      <Typography
                        className={classes.cardLightText}
                        variant="caption"
                        display="block"
                      >
                        {t("hold:remiseTtl")}:
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {holdOrder.discount ? holdOrder.discount + " %" : "-"}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} md={9} sm={6}>
          <Box className={classes.paper}>
            <ProductsToCheckout
              products={holdOrder ? holdOrder.products : []}
              currencySymbol={currencySymbol}
              ignorePricingRule={holdOrder?.ignore_pricing_rule}
            />
            {user && user.role == "Cashier" && (
              <Box display="flex" justifyContent="flex-end" mt={4}>
                <Button
                  disabled={
                    holdOrder?.payment_status != "partly paid" || fromApi
                  }
                  onClick={() =>
                    (holdOrder?.amount?.payments &&
                      holdOrder?.amount?.payments?.length > 0) ||
                    holdOrder?.amount?.depositAmount != 0
                      ? handleFinaliserReserved(holdOrder)
                      : HandleSetToCheckout(holdOrder)
                  }
                  variant="contained"
                  color="primary"
                >
                  {t("hold:finaliser")}
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};
