// barcodeScanner.js

let scannerInput = ""; // Temporary storage for scanner input
let scanTimeout = null; // Timer to reset the input on idle

/**
 * Initializes the barcode scanner listener.
 * @param {Function} onBarcodeDetected - Callback triggered when a complete barcode is scanned.
 * @param {Object} options - Optional configurations (e.g., timeout duration).
 */
export const initializeBarcodeScanner = (onBarcodeDetected, options = {}) => {
  const timeoutDuration = options.timeout || 300; // Default timeout of 300ms

  const handleKeyDown = (event) => {
    // Ignore special keys like Shift, CapsLock, etc.
    if (
      event.key === "Shift" || 
      event.key === "CapsLock" || 
      event.key === "Control" || 
      event.key === "Alt"
    ) {
      return;
    }

    // Check if Enter key signals the end of the barcode
    if (event.key === "Enter") {
      const barcodeScanner = scannerInput.replace(/[^a-zA-Z0-9]/g, ""); // Sanitize input
      if (barcodeScanner) {
        onBarcodeDetected(barcodeScanner); // Trigger callback with sanitized barcode
      }
      scannerInput = ""; // Reset input for the next scan
      return;
    }

    // Append valid characters to the scanner input
    scannerInput += event.key;

    // Reset the timer for incomplete input
    clearTimeout(scanTimeout);
    scanTimeout = setTimeout(() => {
      scannerInput = ""; // Reset scanner input on timeout
    }, timeoutDuration);
  };

  // Attach the global event listener
  window.addEventListener("keydown", handleKeyDown);

  // Return a cleanup function to remove the listener when it's no longer needed
  return () => {
    window.removeEventListener("keydown", handleKeyDown);
  };
};
