import React, { useEffect, useState, useCallback } from "react";
import { Filter } from "../../components/filter";
import ClearIcon from "@material-ui/icons/Clear";
import {
  TextField,
  Typography,
  Button,
  Chip,
  makeStyles,
  Box,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useTableStyles } from "../../styles/table.style";
import * as localforage from "localforage";
import { FilterBy, GlobalfilterData } from "../../helpers/helper";
import { useSelector, useDispatch } from "react-redux";
import { updateTableConfig } from "../../store/setting/setting.action";
import { SimpleTable } from "../../components/simpleTable";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import CachedIcon from "@material-ui/icons/Cached";
import { debounce } from "lodash";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
    whiteSpace: "nowrap",
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
    fontSize: "0.875rem",
    borderRadius: 10,
  },
  filterBlock: {
    "& .MuiOutlinedInput-input": {
      padding: "5.9px 14px !important",
      fontSize: 12,
    },
  },
}));

function removeDuplicates(originalArray, prop) {
  const newArray = [];
  const lookupObject = {};

  originalArray.forEach((item) => {
    lookupObject[item[prop]] = item;
  });

  Object.values(lookupObject).forEach((item) => newArray.push(item));
  return newArray;
}


export const Stock = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "product"]);
  const tableclasses = useTableStyles();

  const stockQtyReport = useSelector((state) => state.Inventory.stockQtyReport);
  const store = useSelector((state) => state.Login.selectedData?.store);
  const hold = useSelector((state) => state.Checkout.holds);

  const [filtredStock, setFiltredStock] = useState([]);
  const [categories, setCategories] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [page, setPage] = useState(1);
  const [searchProduct, setSearchProduct] = useState("");
  const [posProfileList, setPosProfileList] = useState([]);
  const [selectedPOS, setSelectedPOS] = useState(""); // Only one POS selection allowed
  const [filteredPosProducts, setFilteredPosProducts] = useState([]);
  const [itemGroupList, setItemGroupList] = useState([]);
  const [itemData, setItemData] = useState([]);
  const tableConfigs = useSelector((state) => state.Setting.tableConfigs);
  const [filteredSousItemGroups, setFilteredSousItemGroups] = useState([]);
  const [rawItemGroups, setRawItemGroups] = useState([]);
  const [isLoadingGroups, setIsLoadingGroups] = useState(true);

  const [filters, setFilters] = useState(() => {
    const storedFilters = localStorage.getItem("stockFilters");
    if (storedFilters) {
      const parsed = JSON.parse(storedFilters);
      return {
        warehouse: parsed.warehouse ? [parsed.warehouse] : [],
        item_group: parsed.item_group || [],
        sousitem_group: parsed.sousitem_group || [],
      };
    }
    return {
      warehouse: store ? [store] : [],
      item_group: [],
      sousitem_group: [],
    };
  });

  const mergeAndFilterStockData = (stockData, posData, selectedWarehouse) => {
    const posDataMap = new Map(posData.map((item) => [item.item_code, item]));
    const itemDataMap = new Map(itemData.map((item) => [item.item_code, item]));

    //console.log("ItemData", itemDataMap);

    return stockData
      .filter(
        (stockItem) =>
          posDataMap.has(stockItem.item_code) &&
          stockItem.warehouse === selectedWarehouse
      )
      .map((stockItem) => {
        const posItem = posDataMap.get(stockItem.item_code);
        const itemInfo = itemDataMap.get(stockItem.item_code);
        const reservedQty = isReservedInPOS(stockItem.item_code);
        const calculatedQty = posItem.actual_qty - reservedQty;

        return {
          ...stockItem,
          actual_qty: Math.max(calculatedQty, 0),
          sousitem_group: itemInfo?.sousitem_group || "-",
          item_name:
            stockItem.item_name || itemInfo?.name || stockItem.item_code,
        };
      });
  };

  const isReservedInPOS = (item_code) => {
    let totalQtyReserved = 0;
    hold &&
      hold
        .filter((element) => element?.payment_status === "partly paid")
        .forEach((element) => {
          const reservedProducts = element?.products?.filter(
            (el) => el.item_code === item_code
          );
          reservedProducts?.forEach(
            (reservedProduct) => (totalQtyReserved += reservedProduct.qty)
          );
        });
    return totalQtyReserved;
  };

  const getPosProductsList = (warehouse) => {
    debouncedGetPosProductsList(warehouse);
  };

  useEffect(() => {
    getPOSProfileList();
    if (store) {
      getPosProductsList(store);
    }
  }, [store]);

  useEffect(() => {
    localStorage.setItem("stockFilters", JSON.stringify(filters));
  }, [filters]);

  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user")).token,
  };

  const getPOSProfileList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        doctype: "POS Profile",
        fields: ["`tabPOS Profile`.`name`", "`tabPOS Profile`.`warehouse`"],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setPosProfileList(data);
          if (!selectedPOS && data.length > 0) {
            setSelectedPOS(data[0].name); // Set default selection to the first POS profile
          }
        }
      });
  };

  const getItemGroupsParents = useCallback(() => {
    setIsLoadingGroups(true);
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        doctype: "Item Group",
        fields: [
          "`tabItem Group`.`name`",
          "`tabItem Group`.`parent_item_group`",
          "`tabItem Group`.`is_group`"
        ],
        order_by: "`tabItem Group`.`name` ASC"
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {

          setRawItemGroups(data);

          const mainCategories = data.filter(
            (group) => group.parent_item_group === "All Item Groups"
          );
          setItemGroupList(mainCategories);
        }
      })
      .catch((error) => {
        console.error("Error fetching item groups:", error);
      })
      .finally(() => {
        setIsLoadingGroups(false);
      });
  }, [header]);

  useEffect(() => {
    getItemGroupsParents();
  }, []);

  const getItemsList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        doctype: "Item",
        fields: [
          "`tabItem`.`item_code`",
          "`tabItem`.`name`",
          "`tabItem`.`sousitem_group`",
        ],
        start: 0,
        page_length: 9999,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setItemData(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching items:", error);
      });
  };

  useEffect(() => {
    getItemsList();
  }, []);

  const debouncedGetPosProductsList = useCallback(
    debounce(async (warehouse) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URI}/api/pos/products`,
          {
            method: "POST",
            headers: header,
            body: JSON.stringify({
              pos_profile: selectedPOS,
              price_list: "Standard Selling",
              item_group: "Nos",
              start: 0,
              page_length: 1000,
              warehouse,
            }),
          }
        );

        if (!response.ok) throw new Error("Failed to fetch POS Products");

        const data = await response.json();
        const posProducts = data.message.items;
        const mergedData = mergeAndFilterStockData(
          stockQtyReport,
          posProducts,
          warehouse
        );
        setFiltredStock(mergedData);
        setFilteredPosProducts(mergedData);
      } catch (error) {
        console.error("Error fetching POS Products:", error);
        setFiltredStock([]);
        setFilteredPosProducts([]);
      }
    }, 500),
    [selectedPOS, stockQtyReport, itemData]
  );

  useEffect(() => {
    if (selectedPOS) {
      const selectedProfile = posProfileList.find(
        (profile) => profile.name === selectedPOS
      );
      if (selectedProfile) {
        debouncedGetPosProductsList(selectedProfile.warehouse);
      }
    }
  }, [selectedPOS, posProfileList, debouncedGetPosProductsList]);

  const columns = [
    { key: "item_code", title: "common:ref", isInView: 1 },
    { key: "item_name", title: "Article", isInView: 1 },
    { key: "item_group", title: "common:categorie", isInView: 1 },
    { key: "sousitem_group", title: "Sous-catégorie", isInView: 1 },
    { key: "brand", title: "common:brand", isInView: 1 },
    {
      key: "actual_qty",
      title: "common:actualqty",
      isInView: 1,
      render: (row) =>
        Math.max(row.actual_qty - isReservedInPOS(row.item_code), 0),
    },
    { key: "indented_qty", title: "common:indentedqty", isInView: 1 },
    { key: "ordered_qty", title: "common:orderedqty", isInView: 1 },
    { key: "planned_qty", title: "common:plannedqty", isInView: 1 },
    { key: "projected_qty", title: "common:projectedqty", isInView: 1 },
    { key: "re_order_level", title: "common:reorderlvl", isInView: 1 },
    { key: "re_order_qty", title: "common:reorderqty", isInView: 1 },
    { key: "reserved_qty", title: "common:reservedqty", isInView: 1 },
    { key: "stock_uom", title: "common:stockuom", isInView: 1 },
    { key: "warehouse", title: "common:warehouse", isInView: 1 },
  ];

  useEffect(() => {
    const tableConfig = {
      tableName: "stock",
      columns: columns,
    };

    const existingConfig = tableConfigs.find(
      (config) => config.tableName === "stock"
    );
    if (!existingConfig) {
      dispatch(updateTableConfig([...tableConfigs, tableConfig]));
    } else {
      const updatedConfigs = tableConfigs.map((config) => {
        if (config.tableName === "stock") {
          return {
            ...config,
            columns: columns.map((col) => ({
              ...col,
              isInView:
                existingConfig.columns.find((c) => c.key === col.key)
                  ?.isInView ?? 1,
            })),
          };
        }
        return config;
      });
      dispatch(updateTableConfig(updatedConfigs));
    }
  }, []);

  //console.log("config", tableConfigs);
  //console.log("merged data", filtredStock);

  const handleRedirection = (item_code) =>
    history.push(`/stock/${encodeURIComponent(item_code)}`);

  const handleSearchProduct = (event) => {
    setPage(1);
    setSearchProduct(event.target.value);
    setFiltredStock(
      GlobalfilterData(filteredPosProducts, event.target.value, [
        "item_name",
        "item_code",
        "item_group",
        "sousitem_group",
      ])
    );
  };

  useEffect(() => {
    const selectedItemGroups = filters.item_group || [];

    if (selectedItemGroups.length > 0 && rawItemGroups?.length > 0) {
      const subcategories = rawItemGroups.filter((group) =>
        selectedItemGroups.includes(group.parent_item_group)
      );
      setFilteredSousItemGroups(subcategories);

      const uniqueParents = [
        ...new Set(rawItemGroups.map((g) => g.parent_item_group)),
      ];
    } else {
      setFilteredSousItemGroups([]);
    }
  }, [filters.item_group, rawItemGroups]);

  const HandleFilter = (key, item) => {
    setPage(1);
    const normalizedItem = Array.isArray(item) ? item : [item];

    let updatedFilters;

    switch (key) {
      case "warehouse":
        updatedFilters = {
          ...filters,
          warehouse:
            normalizedItem.length > 0
              ? [normalizedItem[normalizedItem.length - 1]]
              : [],
        };
        break;

      case "item_group":
        updatedFilters = {
          ...filters,
          [key]: normalizedItem,
          sousitem_group: [],
        };

        if (normalizedItem.length > 0 && rawItemGroups?.length > 0) {
          const subcategories = rawItemGroups.filter((group) =>
            normalizedItem.includes(group.parent_item_group)
          );
          setFilteredSousItemGroups(subcategories);
        } else {
          setFilteredSousItemGroups([]);
        }
        break;

      case "sousitem_group":
        updatedFilters = {
          ...filters,
          [key]: normalizedItem,
        };
        break;

      default:
        updatedFilters = {
          ...filters,
          [key]: normalizedItem,
        };
    }

    setFilters(updatedFilters);

    if (key === "warehouse") {
      if (normalizedItem.length > 0) {
        debouncedGetPosProductsList(normalizedItem[normalizedItem.length - 1]);
      } else {
        setFiltredStock([]);
        setFilteredPosProducts([]);
      }
    } else if (key !== "pos_profile") {

      let filteredData = filteredPosProducts;

      if (updatedFilters.item_group.length > 0) {
        filteredData = filteredData.filter((item) =>
          updatedFilters.item_group.includes(item.item_group)
        );
      }

      if (updatedFilters.sousitem_group.length > 0) {
        filteredData = filteredData.filter((item) =>
          updatedFilters.sousitem_group.includes(item.sousitem_group)
        );
      }

      setFiltredStock(filteredData);
    }
  };

  const HandleCloseChip = (item, key) => {
    setPage(1);

    if (key === "pos_profile") {
      setSelectedPOS("");
      if (posProfileList.length > 0) {
        setSelectedPOS(posProfileList[0].name);
      }
      return;
    }

    const updatedFilters = {
      ...filters,
      [key]: key === "warehouse" ? [] : filters[key].filter((f) => f !== item),
    };

    if (key === "item_group" && updatedFilters.item_group.length === 0) {
      updatedFilters.sousitem_group = [];
    }

    setFilters(updatedFilters);

    if (key === "warehouse") {
      setFiltredStock([]);
      setFilteredPosProducts([]);
    } else {
      setFiltredStock(filteredPosProducts.filter(FilterBy(updatedFilters)));
    }
  };

  const HandleDeleteAll = () => {
    setPage(1);
    setFilters({
      warehouse: [],
      item_group: [],
      sousitem_group: [],
    });

    if (posProfileList.length > 0) {
      const lastProfile = posProfileList[0].name;
      setSelectedPOS(lastProfile);
    }

    setFiltredStock([]);
    setFilteredPosProducts([]);
    localStorage.removeItem("stockFilters");
  };

  useEffect(() => {
    localforage.getItem("reduxPersist:Inventory", (err, value) => {
      const inventoryData = JSON.parse(value)?.stockQtyReport;
      if (inventoryData) {
        const categoriesData = inventoryData.map((el) => ({
          label: el.item_group,
          value: el.item_group,
        }));
        const warehousesData = inventoryData.map((el) => ({
          label: el.warehouse,
          value: el.warehouse,
        }));
        setWarehouses(removeDuplicates(warehousesData, "value"));
        setCategories(removeDuplicates(categoriesData, "value"));
      }
    });
  }, []);

  return (
    <div className={classes.root}>
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <Box className={classes.header}>
          <TextField
            id="outlined-adornment-weight"
            value={searchProduct}
            placeholder={t("common:rechercher")}
            onChange={handleSearchProduct}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{ "aria-label": "weight" }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/products")}
            startIcon={<CachedIcon />}
          >
            {t("common:produits")}
          </Button>
        </Box>
      </Box>
      <Box className={tableclasses.filterBlock}>
        <Box className={tableclasses.filter}>
          <Filter
            data={warehouses}
            title={t("common:magasin")}
            id={"warehouse"}
            HandleFilter={HandleFilter}
            value={filters.warehouse}
            number={filters.warehouse.length}
            multiple={false}
          />
          <Filter
            data={posProfileList.map((profile) => ({
              label: profile.name,
              value: profile.name,
            }))}
            title={t("common:caisse")}
            id={"pos_profile"}
            HandleFilter={HandleFilter}
            value={selectedPOS ? [selectedPOS] : []}
            number={selectedPOS ? 1 : 0}
          />
          <Filter
            data={itemGroupList.map((group) => ({
              label: group.name,
              value: group.name,
            }))}
            title={t("common:categorie")}
            id={"item_group"}
            HandleFilter={HandleFilter}
            value={filters.item_group || []}
            number={(filters.item_group || []).length}
            multiple={true}
          />
          <Filter
            data={filteredSousItemGroups.map((group) => ({
              label: group.name,
              value: group.name,
            }))}
            title="Sous-catégorie"
            id={"sousitem_group"}
            HandleFilter={HandleFilter}
            value={filters.sousitem_group || []}
            number={(filters.sousitem_group || []).length}
            disabled={!filters.item_group?.length}
          />
        </Box>
      </Box>

      <Box display="flex" flexWrap="wrap" alignItems="center">
        {filters.item_group.map((filter, i) => (
          <Chip
            key={i}
            className={tableclasses.chip}
            label={filter}
            onDelete={() => HandleCloseChip(filter, "item_group")}
            color="secondary"
            size={"small"}
            deleteIcon={<ClearIcon />}
          />
        ))}
        {filters.sousitem_group.map((filter, i) => (
          <Chip
            key={i}
            className={tableclasses.chip}
            label={filter}
            onDelete={() => HandleCloseChip(filter, "sousitem_group")}
            color="secondary"
            size={"small"}
            deleteIcon={<ClearIcon />}
          />
        ))}
        {filters.warehouse.map((filter, i) => (
          <Chip
            key={i}
            className={tableclasses.chip}
            label={filter}
            onDelete={() => HandleCloseChip(filter, "warehouse")}
            color="secondary"
            size={"small"}
            deleteIcon={<ClearIcon />}
          />
        ))}
        {selectedPOS && (
          <Chip
            className={tableclasses.chip}
            label={selectedPOS}
            onDelete={() => HandleCloseChip(selectedPOS, "pos_profile")}
            color="secondary"
            size={"small"}
            deleteIcon={<ClearIcon />}
          />
        )}
        {(filters.warehouse.length > 0 ||
          filters.item_group.length > 0 ||
          filters.sousitem_group.length > 0 ||
          selectedPOS) && (
          <Typography onClick={HandleDeleteAll} className={tableclasses.remove}>
            {t("common:delete")}
          </Typography>
        )}
      </Box>

      <Box>
        {filters.warehouse.length > 0 ? (
          <SimpleTable
            title={"stock"}
            data={filtredStock.slice((page - 1) * 20, page * 20)}
            columns={columns}
            hasPaper={true}
            isRowClickable={true}
            handleRowClick={handleRedirection}
            rowPrimaryKey={"item_code"}
          />
        ) : (
          <Typography variant="body1" align="center">
            Veuillez sélectionner un magasin pour afficher la liste des stocks
            de produits.
          </Typography>
        )}
        {filters.warehouse.length > 0 && filtredStock.length > 0 && (
          <Box display="flex" justifyContent="center" m={4}>
            <Pagination
              onChange={(event, newPage) => setPage(newPage)}
              page={page}
              count={Math.ceil(filtredStock.length / 20)}
              color="primary"
            />
          </Box>
        )}
      </Box>
    </div>
  );
};
