import React from "react";
import Box from "@material-ui/core/Box";
import { CircularProgress, Typography } from "@material-ui/core";

import { useSelector } from "react-redux";
import { formatDate, calculatePrices } from "../../../../helpers/utils";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { symbol } from "../../../../helpers/utils";
import { FreeItemTableBody } from "./components";
import { useState } from "react";
import { useEffect } from "react";
import { useStylesTicket } from "./components/ticketStyle";
import Barcode from "react-barcode";

export const Ticket = React.forwardRef(({ isDuplicata, row }, ref) => {
  const classes = useStylesTicket();
  const { t } = useTranslation(["payement", "common", "checkout", "clients"]);
  const coupon = useSelector((state) => state.Checkout.selectedCoupon);
  const products = useSelector((state) => state.Checkout.products);
  const amount = useSelector((state) => state.Checkout.amount);
  const discount = useSelector((state) => state.Checkout.discount);
  const posOpenedDate = useSelector((state) => state.Login.posOpeningDate);
  const order = useSelector((state) => state.Checkout.order);
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [progress, setProgress] = useState(0); // Progress state
  const TPEticketnumber = useSelector(
    (state) => state.Checkout.TPEticketnumber
  );
  const selectedCustomer = useSelector((state) => state.Checkout.client);
  const customRef = useSelector((state) => state.Checkout.customRef);
  const currencies = useSelector((state) => state.Client.currencies);
  const selectedSalesPerson = useSelector(
    (state) => state.Checkout.selectedSalesPerson
  );
  const cashier = useSelector((state) => state.Login.selectedData);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const currencyExchangeRate = useSelector(
    (state) => state.Checkout.currencyExchangeRate
  );
  const exchangeRate = useSelector((state) => state.Checkout.exchangeRate);
  const selectCurrency = useSelector((state) => state.Checkout.selectCurrency);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const itemTaxList = useSelector((state) => state.Product.itemTax);
  const taxsales = useSelector((state) => state.Checkout.taxsales);
  const [paymentSansEspaces, setPaymentSansEspaces] = useState("");
  const ignorePricingRule = useSelector(
    (state) => state.Checkout.ignorePricingRules
  );
  const appliedLoyaltyCard = useSelector(
    (state) => state.Cart.appliedLoyaltyCard
  );
  const detailClient = useSelector((state) => state.Client.client);
  const holdselected = useSelector((state) => state.Checkout.holdselected);
  const [itemPrestationList, setItemPrestationListList] = useState([]);

  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user")).token,
  };
  useEffect(() => {
    if (order && order.docs) {
      setOrderData(order.docs[0]);
      setLoading(false);
    }
  }, [order]);
  function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 10
      );
    }, 500);

    // Simulate loading order data
    if (progress >= 100 && order && order.docs) {
      setOrderData(order.docs[0]); // Assuming you need the first document
      clearInterval(timer); // Stop the timer once loading is complete
    }

    return () => {
      clearInterval(timer); // Clear the interval if component unmounts
    };
  }, [progress, order]);
  const getItemPrestationsList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        doctype: "Item",
        fields: [
          "`tabItem`.`name`",
          "`tabItem`.`item_code`",
          "`tabItem`.`prix_de_vente_ht`",
          "`tabItem`.`standard_rate`",
        ],
        filters: [
          ["Item", "is_stock_item", "=", 0],
          ["Item", "item_group", "=", "Services"],
        ],
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setItemPrestationListList(data);
        }
      });
  };

  useEffect(() => {
    getItemPrestationsList();
  }, []);

  const calculateTaxRate = (item) => {
    const matchingItem = itemPrestationList.find(
      (prestationItem) => prestationItem.item_code === item.item_code
    );
    if (matchingItem) {
      const standardRate = parseFloat(matchingItem.standard_rate);
      const prixDeVenteHT = parseFloat(matchingItem.prix_de_vente_ht);
      if (standardRate && prixDeVenteHT) {
        return ((prixDeVenteHT - standardRate) / standardRate) * 100;
      }
    }
    return (
      parseFloat(
        item.item_tax_template?.match(/\d+/g)?.toString().replace(",", ".")
      ) || 0
    );
  };

  const calculateTotalTaxAmount = () => {
    return products.reduce((totalTax, product) => {
      const taxRate = calculateTaxRate(product) / 100;

      const unitTax = parseFloat(
        (product.price_list_rate * taxRate).toFixed(3)
      );

      const productTotalTax = parseFloat(
        (unitTax * product.quantity).toFixed(3)
      );

      return parseFloat((totalTax + productTotalTax).toFixed(3));
    }, 0);
  };

  const calculateTotalWithTax = () => {
    return products.reduce((total, product) => {
      const taxRate = calculateTaxRate(product) / 100;

      const unitTax = parseFloat(
        (product.price_list_rate * taxRate).toFixed(3)
      );

      const baseAmount = parseFloat(
        (product.price_list_rate * product.quantity).toFixed(3)
      );

      const productTotalTax = parseFloat(
        (unitTax * product.quantity).toFixed(3)
      );

      return parseFloat((total + baseAmount + productTotalTax).toFixed(3));
    }, 0);
  };

  const currencySymbol = globalDefaults?.default_currency
    ? symbol(globalDefaults?.default_currency, currencies)?.symbol
    : symbol(globalDefaults?.default_currency, currencies) &&
      symbol(globalDefaults?.default_currency, currencies)?.symbol;
  const tvaTaxes = (product) => {
    const data =
      selectCurrency != globalDefaults?.default_currency
        ? product?.item_tax_template &&
          product?.item_tax_template.slice(
            8,
            product?.item_tax_template.indexOf("%") + 1
          ) +
            ": " +
            (
              ((product?.net_amount / Object.values(exchangeRate)) *
                parseFloat(
                  product?.item_tax_template
                    .match(/\d+/g)
                    .toString()
                    .replace(",", ".")
                ).toFixed(3)) /
              100
            ).toFixed(3)
        : product?.item_tax_template &&
          product?.item_tax_template.slice(
            8,
            product?.item_tax_template.indexOf("%") + 1
          ) +
            ": " +
            (
              (product?.net_amount *
                parseFloat(
                  product?.item_tax_template
                    .match(/\d+/g)
                    .toString()
                    .replace(",", ".")
                ).toFixed(3)) /
              100
            ).toFixed(3);
    return data;
  };

  const selectedSalesPersonNames = [];

  selectedSalesPerson.length &&
    selectedSalesPerson.map((s) => {
      s.sales_person_name
        ? selectedSalesPersonNames.push(s.sales_person_name)
        : selectedSalesPersonNames.push(s);
    });
  const allSalesPerson = [...new Set(selectedSalesPersonNames)];

  const prixTTC = (element) => {
    const taxRate = calculateTaxRate(element);
    const basePrice =
      element.price_list_rate ||
      element.standard_rate ||
      element.basic_rate ||
      0;
    const quantity = parseInt(element.qty) || 1;
    const unitPriceTTC =
      Math.round(basePrice * (1 + taxRate / 100) * 1000) / 1000;
    return unitPriceTTC * quantity;
  };

  const calculateTotal = () => {
    return (
      Math.floor(
        products.reduce((total, product) => {
          return total + prixTTC(product);
        }, 0) * 1000
      ) / 1000
    );
  };

  const itemDetails = useSelector((state) => state.Checkout.itemDetails);

  const hasFreeItemData = () => {
    return itemDetails.some(
      (obj) => obj.free_item_data && obj.free_item_data.length > 0
    );
  };

  const calculateTotalHT = () => {
    return products.reduce((total, product) => {
      const baseAmount = parseFloat(
        (product.price_list_rate * product.quantity).toFixed(3)
      );
      return parseFloat((total + baseAmount).toFixed(3));
    }, 0);
  };

  const htAmount = (calculateTotalHT() / Object.values(exchangeRate)).toFixed(
    3
  );

  const taxsalesvalue =
    taxsales && !isNaN(taxsales.value) ? parseFloat(taxsales.value) : 0;

  const calculateTicketTotals = () => {
    const prices = calculatePrices({
      products,
      itemDetails,
      ignorePricingRule,
      discount,
      taxsalesvalue,
      itemPrestationList
    });
  
    return {
      totalHT: prices.subtotal,
      totalTVA: prices.taxTotal,
      totalWithTax: prices.subtotal + prices.taxTotal,
      finalTotal: prices.total,
    };
  };

  const { totalHT, totalTVA, totalWithTax, finalTotal } = calculateTicketTotals();

  return (
    <Box className={classes.ticket} ref={ref}>
      <Box className={classes.ticketBlock}>
        <Box className={classes.ticketHeader}>
          <div className={classes.logo}>
            <img
              src={activeImageTheme}
              style={{
                display: "block",
                marginLeft: "40%",
                marginBottom: "30px",
                width: "15%",
              }}
            />
          </div>
          {selectedData &&
            selectedData.contact &&
            Array.isArray(selectedData.contact) &&
            selectedData.contact.map((row) => (
              <>
                <Box>{globalDefaults?.default_company}</Box>
                <Box fontWeight={"bold"} fontSize={"13px"}>
                  {!isDuplicata ? (
                    orderData?.name ? (
                      <Box fontWeight={"bold"} fontSize={"13px"}>
                        <Barcode
                          value={orderData.name}
                          displayValue={true}
                          fontSize={10}
                          fontWeight={"bold"}
                          width={0.8}
                          height={"50px"}
                        />
                      </Box>
                    ) : (
                      <CircularProgressWithLabel
                        value={progress}
                        fontSize="12px"
                      />
                    )
                  ) : null}
                </Box>
                <Box fontWeight={"normal"} fontSize={"13px"}>
                  {row.address_line_1 === null ? "-" : row.address_line_1}
                </Box>
                <Box fontWeight={"normal"} fontSize={"13px"}>
                  {t("common:city")}: {row.city === null ? "-" : row.city}
                </Box>
                <Box fontWeight={"bold"} fontFamily={"Serif"} fontSize={"10px"}>
                  {t("common:tels")}:{" "}
                  {row.phone_no === null ? "-" : row.phone_no} /{" "}
                  {row.mobile_no === null ? "-" : row.mobile_no}
                </Box>
              </>
            ))}
          {isDuplicata ? (
            <>
              <Box fontWeight={"normal"} fontSize={"12px"}>
                {formatDate(row.posting_date, "LL")} {row.posting_time}
              </Box>
            </>
          ) : (
            <>
              <Box fontWeight={"bold"} fontFamily={"Serif"} fontSize={"10px"}>
                {formatDate(posOpenedDate, "LL")}{" "}
                {formatDate(Date(), "HH:mm:ss")}
              </Box>
              {/* <Box fontWeight={'bold'} fontSize={'13px'}>
                                {t('common:ref')} :
                                { order && Array.isArray(order.docs) && order.docs[0] ? order.docs[0].name :  customRef}
                            </Box>  */}
              {TPEticketnumber && (
                <Box fontFamily={"Verdana"} fontSize={"10px"} color={"#000000"}>
                  {`${t("payement:TPEticketnumber")}: ${TPEticketnumber}`}
                </Box>
              )}
            </>
          )}
        </Box>
        {isDuplicata ? (
          <>
            <Box className={classes.ticketProducts}>
              <Table className={classes.table}>
                <Box className={classes.ticketHeader}>
                  <Box
                    align={"center"}
                    fontWeight={"bold"}
                    fontSize={"11px"}
                    fontFamily={"Verdana"}
                    color={"#000000"}
                  >
                    {t("common:caisse")}: {row.pos_profile}
                  </Box>
                  {row.sales_team && row.sales_team.length != 0 && (
                    <Box
                      align={"center"}
                      fontWeight={"bold"}
                      fontSize={"11px"}
                      fontFamily={"Verdana"}
                      color={"#000000"}
                      paddingTop={"5px"}
                      paddingBottom={"5px"}
                    >
                      {"Vendeur: " + row.sales_team.map((s) => s.sales_person)}
                    </Box>
                  )}
                  <Box
                    align={"center"}
                    fontWeight={"bold"}
                    fontSize={"13px"}
                    fontFamily={"Verdana"}
                    color={"#000000"}
                  >
                    {t("common:client")}: {row.client}
                  </Box>
                </Box>
              </Table>
            </Box>
            <Box className={classes.ticketProducts}>
              <Table className={classes.table}>
                <TableHead className={classes.tablecellHeader}>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      <Box fontFamily={"Verdana"} color={"#000000"}>
                        {t("common:qte")}
                      </Box>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Box fontFamily={"Verdana"} color={"#000000"}>
                        {t("common:title")}
                      </Box>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Box fontFamily={"Verdana"} color={"#000000"}>
                        {t("common:Description")}
                      </Box>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Box fontFamily={"Verdana"} color={"#000000"}>
                        {t("common:amnt")} HT
                      </Box>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Box fontFamily={"Verdana"} color={"#000000"}>
                        {t("common:amnt")} TTC
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.products.map((product) => (
                    <TableRow
                      key={product?.item_name}
                      className={classes.tableRow}
                    >
                      <TableCell className={classes.tableCell}>
                        <Box fontWeight={"bold"} color={"#000000"}>
                          {product.qty}X
                        </Box>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Box fontWeight={"bold"} color={"#000000"}>
                          {product.item_name}
                        </Box>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Box fontWeight={"bold"} color={"#000000"}>
                          {product.description}
                        </Box>
                      </TableCell>
                      {/*<TableCell className={classes.tableCell}>
                                                <Box   color={'#ffffff'}  >
                                                    P.U: {prixTTC(product).toFixed(3)} {currencySymbol}
                                                </Box>
                                            </TableCell>*/}
                      <TableCell className={classes.tableCell}>
                        <Box align={"left"} bottom={"0"} color={"#000000"}>
                          {product.price_list_rate.toFixed(3)}
                          {/* {((parseFloat(product.qty) * prixTTC(product)) / Object.values(exchangeRate)).toFixed(
                                                        3
                                                    )}{' '} */}
                          {currencySymbol}
                        </Box>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Box align={"left"} bottom={"0"} color={"#000000"}>
                          {prixTTC(product).toFixed(3)}
                          {/* {(
                                                        (parseFloat(product.qty) *
                                                            prixTTC(product) *
                                                            (1 + calculateTaxRate(product) / 100)) /
                                                        Object.values(exchangeRate)
                                                    ).toFixed(3)}{' '} */}
                          {currencySymbol}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      {row.amount.payments.map((payment) => (
                        <Box key={payment?.type} color={"#000000"}>
                          {payment?.mode_of_payment
                            ? payment?.mode_of_payment
                            : payment?.type}
                        </Box>
                      ))}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.amount.payments.map((payment) => (
                        <Box
                          key={payment?.amount}
                          align={"right"}
                          color={"#000000"}
                        >
                          {(
                            payment.amount / Object.values(exchangeRate)
                          ).toFixed(3)}{" "}
                          {currencySymbol}
                        </Box>
                      ))}
                    </TableCell>
                  </TableRow>
                  {row?.discount != 0 && (
                    <TableRow className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>
                        <Box fontFamily={"Verdana"} color={"#000000"}>
                          {t("common:remise")}:{" "}
                        </Box>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Box align={"right"} color={"#000000"}>
                          {row.additional_discount_percentage} % (
                          {row.discount_amount / Object.values(exchangeRate)}{" "}
                          {currencySymbol}){" "}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </>
        ) : (
          <>
            <Box className={classes.ticketProducts}>
              <Table className={classes.table}>
                <Box className={classes.ticketHeader}>
                  <Box
                    align={"center"}
                    fontWeight={"bold"}
                    fontSize={"11px"}
                    fontFamily={"Verdana"}
                    color={"#000000"}
                    paddingTop={"5px"}
                  >
                    {t("common:caisse")}: {cashier && cashier.cashier}
                  </Box>
                  {selectedSalesPerson && selectedSalesPerson.length != 0 && (
                    <Box
                      align={"center"}
                      fontWeight={"bold"}
                      fontSize={"11px"}
                      fontFamily={"Verdana"}
                      color={"#000000"}
                      paddingTop={"5px"}
                      paddingBottom={"5px"}
                    >
                      {"Vendeur: " + allSalesPerson.map((s) => s)}
                    </Box>
                  )}
                  <Box
                    align={"center"}
                    fontWeight={"bold"}
                    fontSize={"11px"}
                    fontFamily={"Verdana"}
                    color={"#000000"}
                  >
                    {t("common:client")}:{" "}
                    {selectedCustomer && selectedCustomer.customer_name}
                  </Box>
                </Box>
              </Table>
            </Box>
            <Box className={classes.ticketProducts}>
              <Table className={classes.table}>
                <TableHead className={classes.tablecellHeader}>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      <Box
                        style={{ width: 10 }}
                        fontWeight={"bold"}
                        fontFamily={"Verdana"}
                        color={"#000000"}
                      >
                        {t("Qté")}
                      </Box>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Box
                        fontWeight={"bold"}
                        fontFamily={"Verdana"}
                        color={"#000000"}
                      >
                        {t("common:code")}
                      </Box>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Box
                        fontFamily={"Verdana"}
                        fontSize={"10px"}
                        fontWeight={"bold"}
                        color={"#000000"}
                      >
                        {t("common:Description")}
                      </Box>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Box
                        align={"right"}
                        fontWeight={"bold"}
                        fontFamily={"Verdana"}
                        color={"#000000"}
                      >
                        {t("common:amnt")} TTC
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FreeItemTableBody
                    products={
                      hasFreeItemData() && !ignorePricingRule && order
                        ? order?.docs[0]?.items
                        : products
                    }
                    classes={classes}
                    currencySymbol={currencySymbol}
                    ignorePricingRule={ignorePricingRule}
                    isPricingRule={
                      hasFreeItemData() && !ignorePricingRule ? true : false
                    }
                    itemPrestationList={itemPrestationList}
                  />
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      {amount.payments.map((payment) => (
                        <>
                          <Box
                            key={payment?.name}
                            fontWeight={"bold"}
                            fontFamily={"Verdana"}
                            color={"#000000"}
                          >
                            {t(`common:${payment?.name.replace(/\s/g, "")}`)}
                          </Box>
                        </>
                      ))}
                      {appliedLoyaltyCard?.redeem_points_amount ? (
                        <Box
                          key={"loyalty"}
                          fontFamily={"Verdana"}
                          color={"#000000"}
                        >
                          {t("clients:loyalty")}
                        </Box>
                      ) : null}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {amount.payments.map((payment) => (
                        <Box
                          key={payment?.amount}
                          align={"right"}
                          fontFamily={"Verdana"}
                          color={"#000000"}
                          fontWeight={"bold"}
                        >
                          {(
                            payment.amount / Object.values(exchangeRate)
                          ).toFixed(3)}{" "}
                          {currencySymbol}
                        </Box>
                      ))}
                      {appliedLoyaltyCard?.redeem_points_amount ? (
                        <Box
                          key={appliedLoyaltyCard?.redeem_points_amount}
                          align={"right"}
                          fontFamily={"Verdana"}
                          color={"#000000"}
                        >
                          {appliedLoyaltyCard?.redeem_points_amount?.toFixed(3)}{" "}
                          {currencySymbol}
                        </Box>
                      ) : null}
                    </TableCell>
                  </TableRow>
                  {discount !== 0 && (
                    <TableRow className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>
                        <Box fontFamily={"Verdana"} color={"#000000"}>
                          {t("common:remise")}:
                        </Box>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Box
                          align={"right"}
                          fontFamily={"Verdana"}
                          color={"#000000"}
                        >
                          {discount}%
                        </Box>
                        <Box
                          align={"right"}
                          fontFamily={"Verdana"}
                          color={"#000000"}
                        >
                          {t("common:amnt")}:
                          {((discount / 100) * amount.totalAmount).toFixed(3)}{" "}
                          {currencySymbol}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </>
        )}
        {isDuplicata ? (
          <>
            <Box
              align={"center"}
              fontWeight={"bold"}
              paddingTop={"5px"}
              fontSize={"13px"}
              fontFamily={"Verdana"}
              color={"#000000"}
            >
              {t("common:amnt")} H.T.: {row?.amount?.baseAmount} {currencySymbol}
            </Box>
            <Box
              align={"center"}
              fontWeight={"bold"}
              fontSize={"10px"}
              fontFamily={"Verdana"}
              color={"#000000"}
            >
              T.V.A.: {row?.amount?.taxAmount} {currencySymbol}
            </Box>
            <Box
              align={"center"}
              fontWeight={"bold"}
              fontSize={"20px"}
              fontFamily={"Verdana"}
              color={"#000000"}
            >
              {t("common:grandTotal")}: {row?.amount?.totalAmount} {currencySymbol}
            </Box>
          </>
        ) : (
          <>
            {holdselected && holdselected?.amount?.payments?.length > 0 ? (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#000000",
                  color: "white",
                  margin: 10,
                  padding: 10,
                }}
                align={"center"}
                fontFamily={"Verdana"}
              >
                <span style={{ fontWeight: "bold", fontSize: 13 }}>
                  {t("common:advance")} :{" "}
                  {holdselected?.amount?.payments
                    .map((item) => item.amount)
                    .reduce((prev, curr) => prev + curr, 0)
                    .toFixed(3)}{" "}
                  {currencySymbol}
                </span>
              </Box>
            ) : null}
            {order?.docs[0]?.redeem_loyalty_points === 1 ? (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#000000",

                  margin: 10,
                  padding: 10,
                }}
                align={"center"}
                fontFamily={"Verdana"}
              >
                <span style={{ fontWeight: "bold", fontSize: 13 }}>
                  {t("checkout:lprg")}: {order?.docs[0]?.loyalty_program}
                </span>
                <span style={{ fontWeight: "lighter", fontSize: 11 }}>
                  {t("checkout:PointsUsed")} : {order?.docs[0]?.loyalty_points}
                </span>
                <span style={{ fontWeight: "bold", fontSize: 13 }}>
                  {t("checkout:PointsNow")} :{" "}
                  {detailClient?.__onload?.dashboard_info[0]?.loyalty_points ??
                    0}
                </span>
              </Box>
            ) : detailClient?.loyalty_program &&
              detailClient?.__onload?.dashboard_info[0]?.loyalty_points ? (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#000000",
                  color: "white",
                  margin: 10,
                  padding: 10,
                }}
                align={"center"}
                fontFamily={"Verdana"}
              >
                <span style={{ fontWeight: "bold", fontSize: 13 }}>
                  {t("checkout:lprg")}: {detailClient?.loyalty_program}
                </span>
                <span style={{ fontWeight: "bold", fontSize: 13 }}>
                  {t("checkout:ttlpoints")}:{" "}
                  {detailClient?.__onload?.dashboard_info[0]?.loyalty_points ??
                    0}
                </span>
              </Box>
            ) : null}
            {coupon?.name && (
              <Box
                align={"center"}
                fontWeight={"bold"}
                fontSize={"13px"}
                fontFamily={"Verdana"}
                color={"#000000"}
              >
                {`${t("checkout:couponCode")}: ${coupon?.name}`}
              </Box>
            )}
            <Box
              align={"center"}
              fontWeight={"bold"}
              paddingTop={"5px"}
              fontSize={"13px"}
              fontFamily={"Verdana"}
              color={"#000000"}
            >
              {t("common:amnt")} H.T.: {totalHT.toFixed(3)}
            </Box>
            <Box
              align={"center"}
              fontWeight={"bold"}
              fontSize={"11px"}
              fontFamily={"Verdana"}
              color={"#000000"}
            >
              T.V.A.: {totalTVA.toFixed(3)} {currencySymbol}
            </Box>
            <Box
              align={"center"}
              fontWeight={"bold"}
              fontSize={"11px"}
              fontFamily={"Verdana"}
              color={"#000000"}
            >
              Timbre fiscal: {(0.1).toFixed(3)} {currencySymbol}
            </Box>
            <Box
              align={"center"}
              fontWeight={"bold"}
              fontSize={"13px"}
              fontFamily={"Verdana"}
              color={"#000000"}
            >
              {t("common:grandTotal")}: {finalTotal.toFixed(1)} {currencySymbol}
            </Box>
            <Box
              align={"center"}
              fontWeight={"bold"}
              fontSize={"10px"}
              paddingTop={"5px"}
              fontFamily={"Verdana"}
              color={"#000000"}
            >
              {order ? order.docs[0].total_qty : products.length}{" "}
              {t("common:article")}
            </Box>
          </>
        )}

        <Box className={classes.ticketFooter} textAlign="center">
          {t("L'échange est valable uniquement pendant 15 jours")}
          <br />
          {t("et en dehors des périodes de solde")}
          <br />
          {t("payement:msgComing")}
        </Box>
      </Box>
    </Box>
  );
});
