import React, { useEffect, useState,useRef } from "react";
import Box from "@material-ui/core/Box";
import Pagination from "@material-ui/lab/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { LoadSupplierQuotations } from "../../../../store/buying/buying.action";
import { Loader } from "../../../../components/loader";
import { CiImport } from "react-icons/ci";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import * as XLSX from "xlsx";
import { SuppQuoTable } from "../supplierQuoTable";
import {
  Button,
  FormControl,
  InputAdornment,
  Link,
  Modal,
  OutlinedInput,
  ClickAwayListener
} from "@material-ui/core";
import {
  FilterBy,
  GetFilterItems,
  GlobalfilterData,
} from "../../../../helpers/helper";
import { Filter } from "../../../../components/filter";
import { useTableStyles } from "../../../../styles/table.style";
import { useStyles } from "../../style";
import { useTranslation } from "react-i18next";
import FormAddQuotation from "./formAddQuotation";
import ImportQuotationFile from "./importQuotationFile";
import { Empty } from "../../../../components/empty";
export const SupplierQuotation = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableclasses = useTableStyles();
  const { t } = useTranslation(["buying", "common"]);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const supplierQuoList = useSelector((state) => state.Buying.supplierQuoList);
  const [page, setPage] = useState(1);
  const [listSupplierQuoList, setListSupplierQuoList] = useState([]);
  const [valueSearch, setValueSearch] = useState("");
  const [closeModal, setCloseModal] = useState(false);
  const [filters, setFilters] = useState({
    status: [],
  });
  const maxitems = 20;

  const handleOnExport = () => {
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(supplierQuoList)
    XLSX.utils.book_append_sheet(wb, ws, "sheet1")
    XLSX.writeFile(wb, "Quotations.xlsx")
}
  const handleSearch = (event) => {
    setPage(1);
    setValueSearch(event.target.value);
    setListSupplierQuoList(
      GlobalfilterData(
        filters?.status?.length > 0
          ? supplierQuoList?.slice(0)?.filter(FilterBy(filters))
          : supplierQuoList,
        event.target.value,
        ["name", "transaction_date", "supplier"]
      )
    );
  };

  const HandleFilter = (key, item) => {
    setPage(1);
    const FilterDataBy = { ...filters, [key]: item };
    setFilters(FilterDataBy);
    setListSupplierQuoList(
      valueSearch
        ? GlobalfilterData(supplierQuoList, valueSearch, [
            "name",
            "transaction_date",
            "supplier",
          ])
            ?.slice(0)
            ?.filter(FilterBy(FilterDataBy))
        : supplierQuoList?.slice(0)?.filter(FilterBy(FilterDataBy))
    );
  };

  useEffect(() => {
    if (globalDefaults?.default_company)
      dispatch(LoadSupplierQuotations(globalDefaults?.default_company));
  }, [globalDefaults?.default_company]);
  const [open, setOpen] = useState(false);
  const [openModalImport, setOpenModalImport] = useState(false);
  const addModal = () => {
  setOpen(true);
  }
  const handleCloseModal = () => {
    setOpen(false);
  };
  const addModalImport = () => {
  setOpenModalImport(true);
  }
  const handleCloseModalImport = () => {
    setOpenModalImport(false);
  };
 
  if (!supplierQuoList) return <Loader />;

  return (
    <Box>
      <Box className={classes.header}>
        <FormControl className={classes.formControlSearch} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            value={valueSearch}
            placeholder={t("common:rechercher")}
            onChange={handleSearch}
            endAdornment={
              <InputAdornment position="end">
                <span className="icon-search" />
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            labelWidth={0}
          />
        </FormControl>
      </Box>
      <Box className={tableclasses.filterBlock}>
        <Box className={tableclasses.filter}>
          <Filter
            data={GetFilterItems([
              ...new Set((Array.isArray(supplierQuoList) ? supplierQuoList : []).map((item) => item?.status)),
            ])}
            title={t("common:statut")}
            id={"status"}
            HandleFilter={HandleFilter}
            value={filters.status}
            number={filters.status.length}
          />
         
         </Box>
       <Box >
       <Button 
       style={{ marginLeft: 10,marginBottom: 10,marginTop: 10 }}
            size="small"
            color="primary"
            variant="contained"
            onClick={addModal}
          >
            {t("common:AddSupplierQuotation")}
          </Button>
          <Button
            style={{ marginLeft: 10,marginBottom: 10, marginTop: 10 }}
            size="small"
            color="primary"
            variant="outlined"
            onClick={addModalImport}
            startIcon={<CloudDownloadIcon />} // Use the CloudUploadIcon as the start icon
      >
         {t("common:importFile")}
      </Button>

      <Button
        style={{ marginLeft: 10,marginBottom: 10, marginTop: 10 }}
        size="small"
        color="primary"
        variant="outlined"
        onClick={handleOnExport}
        startIcon={<CloudUploadIcon />} // Use the CloudUploadIcon as the start icon
      >
         {t("common:exportFile")}
      </Button>
          {/* <Button
        size="large"
        color="primary"
        variant="outlined"
        onClick={handleIconClick}
        startIcon={} // Use the CloudUploadIcon as the start icon
      >
        Import File
      </Button>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept=".xlsx, .xls" 
        onChange={handleFileChange}
      /> */}

    
         <Modal
      open={ open }
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"

    >
      {/*   <ClickAwayListener
        onClickAway={() => (setOpenModal ? setOpenModal(false) : null)}
      > */}
      <div className={classes.paper} style={{ overflowY: 'auto', maxHeight: '90vh',width: '70%' }}    >
        <FormAddQuotation setCloseModal={handleCloseModal}/>
      </div>
      {/*   </ClickAwayListener> */}
    </Modal>
    
    <Modal
      open={ openModalImport }
      aria-labelledby="simple-modal-import"
      aria-describedby="simple-modal-import"

    >
        <ClickAwayListener
        onClickAway={() => (setOpenModalImport ? setOpenModalImport(false) : null)}
      >
      <div className={classes.paper} style={{ overflowY: 'auto', maxHeight: '90vh',width: '50%' }}    >
        <ImportQuotationFile setCloseModalImport={handleCloseModalImport}/>
      </div>
         </ClickAwayListener> 
    </Modal>
        </Box>
      </Box>
      <Box>
        <SuppQuoTable
          supplierQuoList={
            valueSearch || filters.status.length > 0
              ? listSupplierQuoList.slice(
                  (page - 1) * maxitems,
                  page * maxitems
                )
              : supplierQuoList.slice((page - 1) * maxitems, page * maxitems)
          }
        />{(Array.isArray(supplierQuoList) <= 0) && (
          <Box display="flex" justifyContent="center" m={4}>
            <Empty text={t("Aucun Devis Disponible")}/>
          </Box>
      )}
        {Math.ceil(
          (valueSearch || filters.status.length > 0
            ? listSupplierQuoList?.length
            : supplierQuoList.length) / maxitems
        ) > 0 && (
          <Box display="flex" justifyContent="center" m={4}>
            <Pagination
              onChange={(event, newPage) => setPage(newPage)}
              defaultPage={page}
              count={Math.ceil(
                (valueSearch || filters.status.length > 0
                  ? listSupplierQuoList?.length
                  : supplierQuoList.length) / maxitems
              )}
              color="primary"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};